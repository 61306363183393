import { RootState } from '@app/RootReducer'
import { Button, Flex, Text } from '@chakra-ui/core'
import NewModal from '@components/NewModal'
import { eventActions } from '@utils/Amplitude'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { DASHBOARD_URL } from '../../src/constent'

const TermsAndConditionModal = () => {
  const [isOpen, setOpen] = useState(false)
  const { me } = useSelector((state: RootState) => state.login)
  const useruid = me?.user_uid

  const bgColor = 'linear-gradient(97.18deg, #511FFF 0%, #5338FF 100%)'
  useEffect(() => {
    if (!useruid) return
    const status = window.localStorage.getItem('terms_condition_modal_2023')
    if (status === 'ACCEPTED' || status === 'REJECTED') {
      return
    }
    eventActions.sendAmplitudeData('visit_dashboard_terms', {
      user_id: useruid,
      user_type: me.user_type,
      username: me.username,
    })
    setOpen(true)
  }, [useruid])

  if (!useruid) {
    return null
  }

  const eventProperty = {
    user_id: useruid,
    user_type: me.user_type,
    username: me.username,
  }
  return (
    <NewModal
      isOpen={isOpen}
      onClose={() => {
        eventActions.sendAmplitudeData('dashboard_terms_accepted', {
          ...eventProperty,
          ctr_clicked: 'dismissed',
        })
        window.localStorage.setItem('terms_condition_modal_2023', 'REJECTED')
        setOpen(false)
      }}
      isCentered={true}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      modalContentStyle={{
        bg: ['brand.primary-light-black-v4'],
        width: ['80%', '700px'],
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        position: 'relative',
        top: null,
        bottom: null,
        mb: null,
      }}
      modalHeaderStyle={{
        px: 0,
        pb: 0,
      }}
      modalBodyStyle={{
        py: [8, 8],
        px: [0, 0],
      }}
      modalBodyComponent={
        <Flex w={'full'} direction="column" flex={1} px={[4, 8]} py={[2, 4]}>
          <Text fontSize={'md'} fontWeight={900} letterSpacing={'1px'}>
            Annual reminder of Terms of Use
          </Text>
          <br />
          <Text fontSize={'xs'} textAlign="justify" letterSpacing={'1px'}>
            This is the annual reminder of our terms of use. We have updated our{' '}
            <a
              href={`${DASHBOARD_URL}/terms`}
              target="_blank"
              style={{ textDecoration: 'underline' }}
              rel="noreferrer"
            >
              Terms of Use.
            </a>{' '}
            Please confirm your acceptance by clicking &quot;I Accept&quot;
            below. In case the above changes are not acceptable to you, you may
            stop using our services. Thank you for being a part of the Loco
            community!
          </Text>
          <Button
            mt={'20px'}
            rounded={4}
            textTransform="uppercase"
            letterSpacing="1px"
            px={'4.5rem'}
            py={1}
            background={bgColor}
            _hover={{ background: bgColor }}
            _active={{ background: bgColor, transform: 'scale(1.02)' }}
            _focus={{ background: bgColor, outline: 'none' }}
            onClick={() => {
              eventActions.sendAmplitudeData('dashboard_terms_accepted', {
                ...eventProperty,
                ctr_clicked: 'accepted',
              })
              window.localStorage.setItem(
                'terms_condition_modal_2023',
                'ACCEPTED'
              )

              setOpen(false)
            }}
          >
            I Accept
          </Button>
        </Flex>
      }
    />
  )
}

export default TermsAndConditionModal
