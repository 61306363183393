import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { setPreviousVisit } from '../../app/appSlice'
import { RootState } from '../../app/RootReducer'
import { paths } from '../../routers/constants'
import { eventActions, eventConstants } from '../../utils/Amplitude'
import AcademyCategoryAccordion from './Components/AcademyCategoryAccordion'
import AcademyCategoryContainer from './Components/AcademyCategoryContainer'
import { howToStreamData } from './data'
import { contentData } from './data/contentData'
import { monetisationData } from './data/monetisationData'

const CategoryContent = () => {
  const location = useLocation()
  const { pathname } = location
  const academyCategory = pathname.split('/').pop()

  const { previousVisit: previousVisitStore } = useSelector(
    (state: RootState) => state.app
  )
  const dispatch = useDispatch()
  const { me } = useSelector((state: RootState) => state.login)
  const [cardsMap, setCardsMap] = useState(new Map())

  useEffect(() => {
    return () => {
      let sum = 0
      cardsMap.forEach(function(value) {
        sum = sum + value
      })
      handleEventVisitStreamerAcademyDashboard(sum)
    }
  }, [cardsMap])
  const handleEventVisitStreamerAcademyDashboard = (sum: number) => {
    const eventProperties = {
      source_name: previousVisitStore,
      platform: eventConstants.platform,
      streamer_id: me?.user_uid,
      cards_opened: sum,
    }
    const previousVisit = academyCategory
    dispatch(setPreviousVisit(previousVisit))
    eventActions.sendAmplitudeData(
      `visit_SA_${academyCategory}`,
      eventProperties
    )
  }

  let academyData,
    next,
    prev,
    prevPath,
    nextPath,
    dashboardNextPath,
    dashboardPrevPath

  switch (academyCategory) {
    case 'howToStream':
      academyData = howToStreamData
      next = contentData.title
      nextPath = paths.academyContent
      dashboardNextPath = paths.dashboard.academyContent
      break
    case 'contentAndCommunity':
      academyData = contentData
      prev = howToStreamData.title
      next = monetisationData.title
      prevPath = paths.academyHowToStream
      nextPath = paths.academyMonetisation
      dashboardPrevPath = paths.dashboard.academyHowToStream
      dashboardNextPath = paths.dashboard.academyMonetisation
      break
    case 'MonetisationAndRewards':
      academyData = monetisationData
      prev = contentData.title
      prevPath = paths.academyContent
      dashboardPrevPath = paths.dashboard.academyContent
      break
    default:
      academyData = howToStreamData
      break
  }
  const { title, text, data } = academyData

  return (
    <AcademyCategoryContainer
      text={text}
      title={title}
      nextCategory={next}
      prevCategory={prev}
      nextPath={nextPath}
      prevPath={prevPath}
      dashboardPrevPath={dashboardPrevPath}
      dashboardNextPath={dashboardNextPath}
    >
      <AcademyCategoryAccordion
        academyData={data}
        setCardsMap={setCardsMap}
        cardsMap={cardsMap}
      />
    </AcademyCategoryContainer>
  )
}

export default CategoryContent
