export const OTPLESS_URL = 'https://otpless.com/auth.js'
export const OTPLESS_SCRIPT = 'otp-less-script'

export const OTPLESS_WRAPPER = 'otpless-floating-button'
export const OTPLESS_BUTTON_IFRAME = 'otpless-floating-button'
export const OTPLESS_IFRAME = 'otpless-floating-frame'

// checking timezone & browser language both.
export const checkIndianBrowser = (): boolean => {
  // let isIndia = false
  // const browserLanguage =
  //   // @ts-ignore
  //   window?.navigator?.language || window?.navigator?.userLanguage
  // if (browserLanguage?.startsWith('en')) {
  //   // English language -> non-MENA region
  //   isIndia = true
  // } else {
  //   // Check timezone
  const currentTimezone = new Date().getTimezoneOffset() // -5:30 === -330
  const isIndia = currentTimezone === -330
  // }
  return isIndia
}
