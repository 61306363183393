import './login.css'

import { Box, Flex, Stack, Text } from '@chakra-ui/core'
import { checkPremiumUser, UserTypeEnum } from '@modules/Login/loginSlice'
import * as Sentry from '@sentry/browser'
import { eventActions, eventConstants } from '@utils/Amplitude'
import React, { useEffect, useMemo, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  findMissingLinking,
  googleStreamerLogin,
  LoginScreenENUM,
  setError,
} from '../../app/appSlice'
import { RootState } from '../../app/RootReducer'
import { FullSpinnerPage } from '../../components/FullSpinnerPage'
import { isWebViewBuild } from '../../constent'
import withToasts from '../../hoc/WithToasts'
import loginBG from '../../images/login/bg.png'
import loginRightChildBg from '../../images/login/right_side_bg.png'
import { paths } from '../../routers/constants'
import { streamDetailsStateInterface } from '../DefaultStream/streamDetailsSlice'
import { GoogleAndMobileOptionLoginScreen } from './GoogleAndMobileOptionLoginScreen'
import { LoginOTPScreen } from './LoginOTPScreen'
import { LoginPhoneScreen } from './LoginPhoneScreen'
import { WebViewLoginError } from './WebViewLoginError'

function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const LoginComponent = (props: any): JSX.Element => {
  const { state: redirectState } = props.location
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const { me, loading: meLoading, userType } = useSelector(
    (state: RootState) => state.login
  )

  const {
    loginScreen,
    accessToken,
    refreshToken,
    linkedGmail,
    linkedPhoneNumber,
  } = useSelector((state: RootState) => state.app)

  const {
    defaultStreamDetails,
    loading: defaultStreamDetailsLoading,
  }: streamDetailsStateInterface = useSelector(
    (state: RootState) => state.streamDetails
  )

  const loading = meLoading || defaultStreamDetailsLoading

  useEffect(() => {
    if (accessToken && refreshToken) {
      if (me?.username) {
        if (isWebViewBuild) {
          history.replace(paths.dashboard.default)
        }
      }
    }
  }, [me, accessToken, refreshToken])

  const prevDefaultStreamDetailsLoading = usePrevious(
    defaultStreamDetailsLoading
  )

  useEffect(() => {
    if (
      accessToken &&
      refreshToken
      // ||
      // (localstorageAccessToken && localstrorageRefreshToken)
    ) {
      if (
        prevDefaultStreamDetailsLoading !== defaultStreamDetailsLoading &&
        !defaultStreamDetailsLoading &&
        prevDefaultStreamDetailsLoading !== undefined
      ) {
        // handleGetUserSettings();
        // handleLinkingAccounts();
      }
    }
  }, [
    defaultStreamDetails?.title,
    defaultStreamDetailsLoading,
    accessToken,
    refreshToken,
    prevDefaultStreamDetailsLoading,
  ])

  useEffect(() => {
    if (accessToken && refreshToken) {
      dispatch(checkPremiumUser())
    }
  }, [accessToken, refreshToken])

  useEffect(() => {
    if (accessToken && refreshToken) {
      if (
        userType &&
        [
          UserTypeEnum.PARTNER,
          UserTypeEnum.ORGANIC,
          UserTypeEnum.PREMIUM,
        ].includes(userType)
      ) {
        const redirectPath = redirectState
          ? redirectState.from.pathname
          : paths.dashboard.default
        history.replace(redirectPath)
        localStorage.setItem('prev_logged_in', 'yes')
      } else if (userType === UserTypeEnum.VIEWER) {
        dispatch(findMissingLinking())
      }
    }
  }, [accessToken, refreshToken, userType])

  useEffect(() => {
    if (accessToken && refreshToken) {
      if (linkedGmail && linkedPhoneNumber) {
        const redirectPath = redirectState
          ? redirectState.from.pathname
          : paths.dashboard.default
        history.replace(redirectPath)
        localStorage.setItem('prev_logged_in', 'yes')
      } else if (linkedGmail || linkedPhoneNumber) {
        history.push(paths.signup)
      }
    }
  }, [linkedGmail, linkedPhoneNumber])

  const responseGoogle = (response: any): void => {
    const userEmail = response?.profileObj?.email
    let ErrorEvent: null | string = response?.error || null
    Sentry.configureScope(function(scope) {
      scope.setUser({
        //@ts-ignore
        token: response?.tokenId,
        //@ts-ignore
        email: response?.profileObj?.email,
      })
    })
    if (
      response &&
      //@ts-ignore
      response.error &&
      //@ts-ignore
      response.error === 'popup_closed_by_user'
    ) {
      // do nothing
    }
    //@ts-ignore
    else if (response?.error === 'idpiframe_initialization_failed') {
      const errorObj = {
        statusCode: 101,
        title: 'Localstorage Error',
        //@ts-ignore
        message: response.details,
      }
      dispatch(setError(errorObj))
    } else {
      //@ts-ignore
      if (response?.tokenId) {
        //@ts-ignore
        ErrorEvent = null
        dispatch(googleStreamerLogin(response.tokenId, userEmail))
      }
    }
    if (ErrorEvent) {
      eventActions.sendAmplitudeData(eventConstants.dashboard_login_google, {
        trigger: 'login_prompt_open',
        response: 'fail',
        failure_msg: ErrorEvent || '',
        email: userEmail,
      })
    }
  }

  const isEnterNumberScreen = loginScreen === LoginScreenENUM.PhoneNumberScreen
  useEffect(() => {
    if (isEnterNumberScreen) {
      eventActions.sendAmplitudeData(
        eventConstants.dashboard_login_enter_phone,
        {
          trigger: 'login_prompt_open',
        }
      )
    }
  }, [isEnterNumberScreen])

  const LoginLeftChild = useMemo(() => {
    switch (loginScreen) {
      case LoginScreenENUM.LoginOptionsScreen:
        return (
          <GoogleAndMobileOptionLoginScreen responseGoogle={responseGoogle} />
        )

      case LoginScreenENUM.PhoneNumberScreen:
        return <LoginPhoneScreen />

      case LoginScreenENUM.OTPNumberScreen:
        return <LoginOTPScreen />

      default:
        return <></>
    }
  }, [loginScreen])

  const LoginRightChild = () => {
    return (
      <Box
        backgroundImage={`url(${loginRightChildBg})`}
        w="211px"
        display={['none', 'flex', 'flex']}
        height="400px"
      >
        <Stack
          w="full"
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Flex
            bg="#FFDA00"
            w="103px"
            alignItems="center"
            height="31px"
            justifyContent="center"
            textAlign="left"
            transform="skew(-20deg)"
          >
            <Text
              color="#0c0c20"
              fontSize="18px"
              fontWeight="black"
              lineHeight="1.22"
              transform="skew(20deg)"
            >
              {t('login.joinText')}
            </Text>
          </Flex>
          <Box textAlign="left" w="103px" height="31px">
            <Text
              color="white"
              fontSize="25px"
              fontWeight="black"
              lineHeight="1.56"
            >
              {t('login.gamingText')}
            </Text>
          </Box>
          <Box textAlign="left" w="103px" height="31px">
            <Text
              color="white"
              fontSize="25px"
              fontWeight="black"
              lineHeight="1.22"
            >
              {t('login.revolutonText')}
            </Text>
          </Box>
          <Box textAlign="left" w="103px" height="31px">
            <Text color="white" fontSize="18px" lineHeight="1.06">
              {t('login.locoText')}
            </Text>
          </Box>
        </Stack>
      </Box>
    )
  }

  return (
    <>
      <Helmet>
        <title>Loco Streaming Centre</title>
      </Helmet>
      <Flex
        direction="column"
        alignItems="center"
        w="full"
        height="full"
        bgImage={`radial-gradient(circle at 0 0, rgba(5, 5, 6, 0.7), rgba(5, 5, 6, 0.9), #050506), url(${loginBG})`}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        bg="#2c18a6"
        justifyContent={['start', 'center']}
      >
        {!loading && !isWebViewBuild ? (
          <Flex
            h="full"
            w="full"
            maxW="600px"
            maxH={['100%', '400px']}
            rounded="12px"
            overflow="visible"
            color="black"
          >
            {LoginLeftChild}
            <LoginRightChild />
          </Flex>
        ) : !loading && isWebViewBuild ? (
          <WebViewLoginError />
        ) : (
          <FullSpinnerPage />
        )}
      </Flex>
    </>
  )
}
const Login = withToasts(LoginComponent)
export { Login }
