import {
  Icon,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/core'
import DefaultInput, { InputProps } from '@chakra-ui/core/dist/Input'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface DefaultInputProps {
  value?: string
  onBlur?: any
  onChange?: any
  error?: boolean
  matches?: boolean
  rightComponent?: React.ReactNode
  inputGroup?: InputProps
}
export const Input = (
  props: DefaultInputProps & InputProps<HTMLTextAreaElement>
) => {
  const { onBlur, onChange, value, error, matches, rightComponent } = props

  const { i18n } = useTranslation()

  const isRTL = i18n.resolvedLanguage !== 'en'

  return (
    <InputGroup {...props.inputGroup}>
      <DefaultInput
        {...props}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        bg={props.bg ? props.bg : ['brand.primary-text-field']}
        border={'none'}
        borderRadius={'4px'}
        focusBorderColor={'none'}
        _placeholder={
          props._placeholder
            ? props._placeholder
            : {
                color: 'brand.primary-white-v2',
              }
        }
        fontSize="sm"
        _readOnly={{
          cursor: 'no-drop',
        }}
        color={props.color ? props.color : 'white'}
      />
      {error ? (
        isRTL ? (
          <InputLeftElement>
            <Icon name="warningV2" color="brand.primary-red" />
          </InputLeftElement>
        ) : (
          <InputRightElement>
            <Icon name="warningV2" color="brand.primary-red" />
          </InputRightElement>
        )
      ) : matches ? (
        isRTL ? (
          <InputLeftElement>
            <Icon name="check" color="brand.primary-green" />
          </InputLeftElement>
        ) : (
          <InputRightElement>
            <Icon name="check" color="brand.primary-green" />
          </InputRightElement>
        )
      ) : rightComponent ? (
        isRTL ? (
          <InputLeftElement>{rightComponent}</InputLeftElement>
        ) : (
          <InputRightElement>{rightComponent}</InputRightElement>
        )
      ) : null}
    </InputGroup>
  )
}
