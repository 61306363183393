/* eslint-disable no-undef */
export const GOOGLE_CLIENT_ID =
  '1079034827923-1it0pd4ae4njhv6vojgr6gte4tencvma.apps.googleusercontent.com'

export const DASHBOARD_WEBVIEW_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://webview-dashboard.loco.gg'
    : 'https://webview-dashboard-dev.loco.gg'

export const DASHBOARD_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://dashboard.loco.gg'
    : 'https://dashboard-dev.loco.gg'
export const serverUrl =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://ivory.getloconow.com/v1'
    : 'https://dev-ivory.loco.gg/v1'
export const serverUrl_V2 =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://ivory.getloconow.com/v2'
    : 'https://dev-ivory.loco.gg/v2'
export const serverUrl2 =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.getloconow.com/v3'
    : 'https://dev.getloconow.com/v3'
export const serverUrl3 =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://ivory.getloconow.com'
    : 'https://dev-ivory.loco.gg'

export const yenServerUrl =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://yen.getloconow.com/v1'
    : 'https://dev-yen.getloconow.com/v1'
export const paymentsUrl3 =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://payments.getloconow.com/api/v3'
    : 'https://dev-payments.getloconow.com/api/v3'
export const yenClientID =
  process.env.REACT_APP_ENV === 'production'
    ? 'PHRFPyfdoyzn5gflkukGsOTPyWBWpBFi'
    : 'SQUKQEakx1e4cmMLDqqlMk2Zm8BB0cxb'
export const yenCleintSecret =
  process.env.REACT_APP_ENV === 'production'
    ? 'mfrAv2fc7uEoiM2Xlr06vhaHLWB0pnsL'
    : 'PSd9zd1eC7tYdJzQ8IvX6s54UteClQ3N'
export const clientID =
  process.env.REACT_APP_ENV === 'production'
    ? 'Lo3r2P6oFQPXAeuddoRSB4HbXDZI1WRS8pnMzRtX'
    : 'ZVpPXpShMbFK3v4ASzLRgbQwh3eqDACmu1PIv0Y7'
export const clientSecret =
  process.env.REACT_APP_ENV === 'production'
    ? 'ue8mcpwC9ofEYDPjovO5lzr1N2E16ejH0zwzYsqABxAEIZgGtqx9NLNfQEjkZcz7Dor0NfXhIDyiXJ6kdQtNhHr9V0P9zX0xeKfzZasRSdRkRZxqUdnU5wnmqc9qIELA'
    : 'yAL7wxNpUyTS5mqFFdlJPqlmrtYpqwkmCZhUcLpW3ARt6OQXVnENmAU31h1GENC2fZASb7y280dAh7iZ3qqbAZSBJu9qXBAdg3QDqTBnR5aCMh0lfMJmxbv6Xzauvlgo'
export const sentryDsn =
  process.env.REACT_APP_BUILD === 'webview'
    ? 'https://a05516603d094d65a62acb976bdb8b8c@o1100646.ingest.sentry.io/6508896'
    : 'https://7f374ba249554cc38c49d13ba95fc05b@o1100646.ingest.sentry.io/6508890'
export const environment =
  process.env.REACT_APP_ENV === 'production'
    ? 'production'
    : process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT
export const mqttUsername =
  process.env.REACT_APP_ENV === 'production'
    ? 'N7AcYWuBXdSsADEm8aF6JrFpnOm5Pnv6'
    : 'cRqlmWsryumZvmO3bs0Nc2DMiyv0t9L1'
export const mqttPassword =
  process.env.REACT_APP_ENV === 'production'
    ? 'xWinLUb8TWry2kvxc79RkD1fiiJsolrM'
    : 'tbSSYbQP8LkaCcANOf628f3Vlkdvdym5'
export const mqttPort = process.env.REACT_APP_ENV === 'production' ? 443 : 443
export const mqttUrl =
  process.env.REACT_APP_ENV === 'production'
    ? 'wss://cf-mqtt-ws.getloconow.com'
    : 'wss://dev-cf-mqtt-ws.getloconow.com'
export const isWebViewBuild = process.env.REACT_APP_BUILD === 'webview'
export const amplitude_API_KEY =
  process.env.REACT_APP_ENV === 'production'
    ? 'a4d5600e65147238fa65443a42bf485a'
    : '7319b6f48b337c5ee24b6e19b829adac'
export const CLEVERTAP_ACCOUNT_ID =
  process.env.REACT_APP_ENV === 'production'
    ? 'R95-899-9R5Z'
    : 'TEST-86Z-KW7-5K5Z'
export const moonUrl =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://moon.getloconow.com/v2'
    : 'https://devmoon.getloconow.com/v2'

export const strapiUrl =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://strapicms.getloconow.com/api'
    : 'https://dev-strapicms.getloconow.com/api'

export const StreamerDetailsApiForGiveWay =
  'https://api.steinhq.com/v1/storages/5f11eaa95d3cdc44fcd7d1a7/sheet1'

export const UserDetailsForGiveWay =
  'https://api.steinhq.com/v1/storages/5f11eaa95d3cdc44fcd7d1a7/sheet2'

export const isPRODUCTION =
  process.env.REACT_APP_ENV === 'production' ? true : false

export const CHAT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://chat.getloconow.com/v2'
    : 'https://dev-chat-api.getloconow.com/v2'

export const DRM_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://drm.loco.gg/v1'
    : 'https://dev-ivory.loco.gg/v1'

export const LOCO_API_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://loco.gg/api'
    : 'https://dev.loco.gg/api'

export const BASE_TASK_CENTER_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://task.getloconow.com'
    : 'https://dev-task.getloconow.com'
