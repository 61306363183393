import {
  Divider,
  Flex,
  FormErrorMessage,
  Icon,
  Image,
  Text,
} from '@chakra-ui/core'
import { FormikProps, withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import {
  isUsernameAvailable,
  updateProfileParams,
} from '../../../api/apiRequest'
import CropComponent from '../../../components/CropComponent'
// import { ProfileUpload } from './ProfileUpload';
import { streamerDetailsInterface } from '../../../context/ApiConnector/types'
import Form from '../../../hoc/Form'
import profilePlaceholder from '../../../images/profilePlaceholder.svg'
import { dateToObj } from '../../../utils/formatDateToObj'

export interface OnboardingFormProps {
  values?: any
  streamerDetails: streamerDetailsInterface | null
  handleProfileUpdate: (params: updateProfileParams) => void
  handlePhotoUpload: (file: any) => void
  isUploading: boolean
  submittingEnabled: boolean
}
export interface OnboardingFormValues {
  isUpdate: boolean
  name: string
  username: string
  bio: string
  dob: string | any
  gender: string | number
  game: string
  emailAddress: string
  phoneNumber: string | number
  country: any
}
const OnboardingForm = (
  props: OnboardingFormProps & FormikProps<OnboardingFormValues>
) => {
  const { t } = useTranslation()
  const [
    isUsernameAvailableErrorFlag,
    setIsUsernameAvailableErrorFlag,
  ] = useState<string | null>(null)
  const {
    setFieldValue,
    handleChange,
    // handleBlur,
    errors,
    streamerDetails,
    setFieldError,
    setFieldTouched,
    isUploading,
    handlePhotoUpload,
    values,
    submittingEnabled,
  } = props
  const handleUsernameChange = (e: any) => {
    handleChange(e)
    //@ts-ignore
    debouncedCheckUsername(e.target.value)
  }
  function checkUsername(username: string) {
    if (username !== streamerDetails?.username && username.length > 3) {
      if (!errors['username']) {
        isUsernameAvailable(username)
          .then((result) => {
            if (result.is_available) {
              setIsUsernameAvailableErrorFlag(null)
            } else if (result.is_available === false) {
              setIsUsernameAvailableErrorFlag(
                t('onBoarding.profileSetup.usernameTaken')!
              )
              setFieldTouched('username', true, false)
              setFieldError('username', 'not available')
            } else if (result?.statusCode === 400) {
              setIsUsernameAvailableErrorFlag(result?.message)
              setFieldTouched('username', true, false)
              setFieldError('username', 'Not available')
            } else {
              setIsUsernameAvailableErrorFlag('Something went wrong')
              setFieldTouched('username', true, false)
              setFieldError('username', 'Not available')
            }
          })
          .catch(() => {
            setIsUsernameAvailableErrorFlag('Something went wrong')
            setFieldError('username', 'Something went wrong on server side')
            setFieldTouched('username', true, false)
          })
      }
    } else {
      setIsUsernameAvailableErrorFlag(null)
    }
  }
  const debouncedCheckUsername = useCallback(_.debounce(checkUsername, 300), [])
  // 300ms debounce
  const handleBlurForUsername = (e: any) => {
    // handleBlur(e); // this will let Formik's default validation take place
    setFieldTouched('username', true, false)
    checkUsername(e.target.value)
    // setFieldError('username', 'not available');
  }
  const maxDob = moment()
    .subtract(13, 'y')
    .format('DD/MM/YYYY')
  const maxDobObj = dateToObj(maxDob)
  const inputFields = [
    {
      name: 'profileUploadText',
      component: (
        <>
          <Text fontWeight="black" fontSize="sm">
            {t('onBoarding.profileSetup.uploadProfile')}
          </Text>
          <Text fontWeight="300" mt={4} fontSize="xs">
            {t('onBoarding.profileSetup.uploadProfileSuggestion')}
          </Text>
        </>
      ),
    },
    {
      name: 'profileUpload',
      component: (
        <Flex align="center">
          <Image
            src={streamerDetails?.avatar_url || profilePlaceholder}
            objectFit="cover"
            size={['80px', '100px']}
            rounded="full"
          />
          <CropComponent
            {...props}
            id={'profileUpload'}
            title={t('onBoarding.profileSetup.changeProfile')}
            onChange={handlePhotoUpload}
            buttonStyle={{
              backgroundColor: 'transparent',
              width: 'fit-content',
            }}
            //@ts-ignore
            buttonProps={{ isLoading: isUploading }}
            hidePreview={true}
            aspectRatio={1}
            accept=".png,.jpg,.jpeg"
          />
        </Flex>
      ),
    },
    {
      name: 'divider',
      component: <Divider borderColor="brand.primary-text" opacity={0.2} />,
    },
    {
      name: 'tellUsAboutYou',
      component: (
        <Text fontWeight="black" fontSize="sm">
          {t('onBoarding.profileSetup.aboutYou')}
        </Text>
      ),
    },
    {
      name: 'name',
      placeholder: '',
      label: t('onBoarding.profileSetup.name'),
      wordCount: false,
      wordCountLimit: 20,
    },
    {
      name: 'username',
      placeholder: '',
      label: t('onBoarding.profileSetup.username'),
      wordCount: false,
      wordCountLimit: 30,
      onChange: handleUsernameChange,
      onBlur: handleBlurForUsername,
      matches:
        !isUsernameAvailableErrorFlag &&
        values['username'] !== streamerDetails?.username &&
        values['username']
          ? true
          : false,
      errorComponent: isUsernameAvailableErrorFlag ? (
        <FormErrorMessage
          fontSize="xs"
          fontWeight="lighter"
          color="brand.primary-red"
        >
          {isUsernameAvailableErrorFlag}
        </FormErrorMessage>
      ) : null,
    },
    {
      name: 'bio',
      placeholder: '',
      label: t('onBoarding.profileSetup.bio'),
      inputType: 'textarea',
      wordCount: false,
      wordCountLimit: 100,
    },
    {
      name: 'dob',
      placeholder: 'DD/MM/YYYY',
      label: (
        <Flex align="center" justify="space-between" w="full">
          <Text>{t('onBoarding.profileSetup.dob')}</Text>
          <Icon name="calendar" color="brand.primary-white-v2" />
        </Flex>
      ),
      inputType: 'date',
      onChange: (val: any) => setFieldValue('dob', val),
      extraProps: {
        //@ts-ignore
        maximumDate: maxDobObj,
        title: 'Select Date of Birth',
        name: 'dob',
      },
    },
    {
      name: 'country',
      placeholder: t('onBoarding.profileSetup.countryPlaceholder'),
      label: t('onBoarding.profileSetup.country'),
      onChange: (val: any) => setFieldValue('country', val.value),
      onBlur: () => setFieldTouched('country', true),
      inputType: 'countryPicker',
    },
    {
      name: 'gender',
      placeholder: '',
      label: t('onBoarding.profileSetup.gender'),
      inputType: 'radio',
      extraProps: {
        options: [
          { label: t('profile.male'), value: '1' },
          { label: t('profile.female'), value: '2' },
          { label: t('profile.other'), value: '3' },
        ],
      },
    },
    {
      name: 'game',
      placeholder: t('onBoarding.profileSetup.gamePlaceholder'),
      label: t('onBoarding.profileSetup.game'),
      onChange: (val: any) => setFieldValue('game', val.value),
      onBlur: () => setFieldTouched('game', true),
      inputType: 'gamesPicker',
    },
    {
      name: 'divider-2',
      component: <Divider borderColor="brand.primary-text" opacity={0.2} />,
    },
  ]

  return (
    <>
      <Form
        {...props}
        isSubmitting={submittingEnabled}
        inputFields={inputFields}
        buttonProps={{
          buttonText: t('onBoarding.profileSetup.btnText'),
          isDisabled: !!isUsernameAvailableErrorFlag,
        }}
      />
    </>
  )
}
const schema = yup.object().shape({
  isUpdate: yup.bool(),
  name: yup
    .string()
    .required('formValidationMessage.fullname.required')
    .min(4, 'formValidationMessage.fullname.min')
    .max(20, 'formValidationMessage.fullname.max'),
  username: yup
    .string()
    .required('formValidationMessage.username.required')
    .min(4, 'formValidationMessage.username.min')
    .max(30, 'formValidationMessage.username.max'),
  bio: yup
    .string()
    .required('formValidationMessage.bio.required')
    .min(10, 'formValidationMessage.bio.min')
    .max(100, 'formValidationMessage.bio.max'),
  gender: yup.number().required('formValidationMessage.gender.required'),
  game: yup.string().required('formValidationMessage.game.required'),
  country: yup.string().required('formValidationMessage.country.required'),
  phoneNumber: yup
    .string()
    .matches(/^[1-9]\d{9}$/, 'formValidationMessage.phonenumber.required')
    .min(10, 'formValidationMessage.phonenumber.min')
    .max(10, 'formValidationMessage.phonenumber.max'),
  emailAddress: yup.string().matches(
    // eslint-disable-next-line no-useless-escape
    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    'formValidationMessage.emailAddress.valid'
  ),
  dob: yup
    .string()
    .min(10, 'formValidationMessage.dob.valid')
    .max(10, 'formValidationMessage.dob.valid')
    .test('dob', 'formValidationMessage.dob.minimumAge', (value) => {
      if (moment(value, 'DD/MM/YYYY').isValid()) {
        return moment().diff(moment(value, 'DD/MM/YYYY'), 'years') > 13
      }
      return false
    })
    .required('formValidationMessage.dob.required'),
})
const EnhancedComponent = withFormik<OnboardingFormProps, OnboardingFormValues>(
  {
    enableReinitialize: true,
    mapPropsToValues: (value) => {
      const { streamerDetails } = value
      return {
        isUpdate: true,
        name: streamerDetails?.full_name || '',
        username: streamerDetails?.username || '',
        bio: streamerDetails?.bio || '',
        dob: moment(streamerDetails?.dob, 'DD/MM/YYYY').isValid()
          ? streamerDetails?.dob
          : '',
        gender: streamerDetails?.gender || '',
        game: streamerDetails?.primary_game || '',
        emailAddress: streamerDetails?.email || '',
        phoneNumber: '',
        country: streamerDetails?.iso_code,
      }
    },
    validationSchema: schema,
    handleSubmit: (values, { props }) => {
      const { handleProfileUpdate } = props

      handleProfileUpdate({
        bio: values.bio,
        full_name: values.name,
        username: values.username,
        gender: +values.gender!,
        dob: values.dob,
        country: values?.country,
        //@ts-ignore
        tags: values.tags?.map((tag) => tag.value),
        game: values.game,
      })
    },
  }
)(OnboardingForm)

export default EnhancedComponent
