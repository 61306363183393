import { RootState } from '@app/RootReducer'
import { Button, Flex, Icon, Text, useDisclosure } from '@chakra-ui/core'
import NewModal from '@components/NewModal'
import { paths } from '@routers/constants'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { kycPopupDismiss } from '../kyc_events'

const KycBannerWrapper = () => {
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure(false)
  const me = useSelector((state: RootState) => state.login.me)
  const showKycPrompt = useSelector(
    (state: RootState) => state.hypervergeKyc.showKycPrompt
  )
  const userName = me?.username || me?.full_name || null
  const isModalOpen = showKycPrompt && userName
  const onModalClose = () => {
    onClose()
    kycPopupDismiss({ isButtonClicked: false })
  }
  const onProceedClick = () => {
    onClose()
    history.push(paths.dashboard.manageAccounts)
    kycPopupDismiss({ isButtonClicked: true })
  }

  useEffect(() => {
    if (isModalOpen) {
      onOpen()
    } else {
      onClose()
    }
  }, [isModalOpen])

  const bgColor = 'linear-gradient(97.18deg, #511FFF 0%, #5338FF 100%)'

  if (!userName) return null
  return (
    <NewModal
      isOpen={isOpen}
      onClose={onModalClose}
      isCentered={true}
      modalContentStyle={{
        bg: ['brand.primary-light-black-v4'],
        width: ['80%', '700px'],
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        position: 'relative',
        top: null,
        bottom: null,
        mb: null,
      }}
      modalHeaderStyle={{
        px: 0,
        pb: 0,
      }}
      modalBodyStyle={{
        py: [24, 8],
        px: [0, 0],
      }}
      modalBodyComponent={
        <Flex
          w={'full'}
          justify={'center'}
          direction="column"
          alignSelf="center"
          alignItems="center"
          minH="fit-content"
          flex={1}
          px={[4, 8]}
          py={[2, 4]}
          overflow="auto"
          h="fit-content"
          gridRowGap={5}
        >
          <Icon name={'kycUserBanner'} size={'74px'} />
          <Text fontSize={'xl'} fontWeight={900} letterSpacing={'1px'}>
            KYC Verification
          </Text>
          <Text
            fontSize={'sm'}
            textAlign="center"
            letterSpacing={'1px'}
            mt={'4px'}
          >
            Hi {userName},<br /> Please complete/repeat* KYC verification to
            start withdrawing money
          </Text>
          <Text
            fontSize={'xs'}
            textAlign="center"
            letterSpacing={'1px'}
            color="rgba(255,255,255,0.5)"
          >
            *Note: KYC Verification will need to be repeated for currently
            verified users as well.
          </Text>
          <Button
            mt={'20px'}
            rounded={4}
            textTransform="uppercase"
            letterSpacing="1px"
            px={'4.5rem'}
            py={1}
            background={bgColor}
            _hover={{ background: bgColor }}
            _active={{ background: bgColor, transform: 'scale(1.02)' }}
            _focus={{ background: bgColor, outline: 'none' }}
            onClick={onProceedClick}
          >
            PROCEED
          </Button>
        </Flex>
      }
    />
  )
}

export default KycBannerWrapper
