import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  streamerAvatar,
  updateProfileParams,
  updateStreamerProfile,
} from '../../api/apiRequest'
import { RootState } from '../../app/RootReducer'
import { useCustomToast } from '../../components/customToast'
import Container from '../../components/NewContainer'
import { fetchMeStreamer, setProfileUrl } from '../Login/loginSlice'
import OnboardingForm from './OnboardingForm'
// import { useDisclosure } from '@chakra-ui/core';
/* TASK : show it when referral is live  */
// import OnboardingPopUp from './OnboardingForm/OnboardingPopUp';

const Onboarding = () => {
  const dispatch = useDispatch()
  // const { isOpen, onClose, onOpen } = useDisclosure();
  const { me: streamerDetails } = useSelector((state: RootState) => state.login)

  const [isUploading, setIsUploading] = useState(false)
  const [submittingEnabled, setSubmittingEnabled] = useState(false)
  const toast = useCustomToast()
  const isUserNoPresent = !streamerDetails
  useEffect(() => {
    if (isUserNoPresent) {
      dispatch(fetchMeStreamer())
    }
  }, [isUserNoPresent])
  // uncomment for reffral feature
  // const openModal = async (params: updateProfileParams) => {
  //   const result = await updateStreamerProfile(params);
  //   if (result?.success) {
  //     onOpen();
  //   } else if (result.statusCode) {
  //     toast({
  //       title: result.statusCode,
  //       position: 'top',
  //       description: result.message,
  //       status: 'error',
  //       duration: 2500,
  //       isClosable: true,
  //     });
  //   } else {
  //     toast({
  //       title: 'Error',
  //       position: 'top',
  //       description: 'Something went wrong',
  //       status: 'error',
  //       duration: 2500,
  //       isClosable: true,
  //     });
  //   }
  // };

  const handleProfileUpdate = async (params: updateProfileParams) => {
    setSubmittingEnabled(true)
    const result = await updateStreamerProfile(params)
    if (result?.success) {
      dispatch(fetchMeStreamer())
      toast({
        title: t('onBoarding.profileSetup.success'),
        position: 'top',
        description: t('onBoarding.profileSetup.profileSuccess'),
        status: 'success',
        duration: 2500,
        isClosable: true,
      })
    } else if (result?.statusCode) {
      setSubmittingEnabled(false),
        toast({
          title: result.statusCode,
          position: 'top',
          description: result.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
        })
    } else {
      setSubmittingEnabled(false),
        toast({
          title: 'Error',
          position: 'top',
          description: 'Something went wrong',
          status: 'error',
          duration: 2500,
          isClosable: true,
        })
    }
  }

  // uncommment this for referral

  // const handleProfileUpdate = () => {
  //   setLoading(true);
  //   toast({
  //     title: 'Success',
  //     position: 'top',
  //     description: 'Your profile is successfully updated',
  //     status: 'success',
  //     duration: 2500,
  //     isClosable: true,
  //   });
  //   dispatch(fetchMeStreamer());
  //   setLoading(false);
  // };

  const handlePhotoUpload = async (file: any) => {
    setIsUploading(true)
    const result = await streamerAvatar({
      avatar: file,
    })
    if (result.image_url) {
      toast({
        title: t('onBoarding.profileSetup.success'),
        position: 'top',
        description: t('onBoarding.profileSetup.profileImageSuccess'),
        status: 'success',
        duration: 2500,
        isClosable: true,
      })
      const me = {
        ...streamerDetails,
        avatar_url: result.image_url,
      }
      dispatch(setProfileUrl(me))
    } else if (result?.message === 'Failed to fetch') {
      toast({
        title: '400',
        position: 'top',
        description: 'Failed to fetch',
        status: 'error',
        duration: 2500,
        isClosable: true,
      })
    } else if (result.statusCode) {
      toast({
        title: result.statusCode,
        position: 'top',
        description: result.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
      })
    } else {
      toast({
        title: 'Error',
        position: 'top',
        description: 'Something went wrong',
        status: 'error',
        duration: 2500,
        isClosable: true,
      })
    }
    setIsUploading(false)
  }

  const { t } = useTranslation()
  return (
    <>
      <Container
        title={t('onBoarding.profileSetup.title')}
        subtitle={t('onBoarding.profileSetup.subtitle')}
        outerContainerStyle={{
          w: 'full',
          margin: 'auto',
          textAlign: 'left',
          overflow: 'auto',
        }}
        innerContainerStyle={{
          bg: ['transparent', 'brand.primary-light-black-v2'],
          alignItems: 'left',
          rounded: 'lg',
        }}
      >
        <OnboardingForm
          streamerDetails={streamerDetails}
          handleProfileUpdate={handleProfileUpdate}
          isUploading={isUploading}
          handlePhotoUpload={handlePhotoUpload}
          submittingEnabled={submittingEnabled}
        />
      </Container>
      {/* <OnboardingPopUp
        openStatus={isOpen}
        handleClose={onClose}
        handleSubmit={handleProfileUpdate}
      /> */}
    </>
  )
}

export default Onboarding
