export const HTS = [
  {
    id: 1,
    faqs: 'How to generate Stream Key & URL on LOCO ?',
    endText:
      'Note: The streaming KEY & LINK generated will be your default and you can change the streaming KEY in the ‘Stream Key & Preferences’ section.',
    ans: [
      {
        id: 11,
        text: 'Go to the ‘Stream Now’ section in the LOCO app.',
        img: {
          x3: require('./assets/img1-1.png'),
        },
      },
      {
        id: 12,
        text: 'Click on the ‘Start Your 1st Stream’ button.',
        img: {
          x3: require('./assets/img1-2.png'),
        },
      },
      {
        id: 13,
        text: 'Fill the form to set up your streaming.',
        img: {
          x3: require('./assets/img1-3.png'),
        },
      },
      {
        id: 14,
        text:
          'Click on the ‘NEXT’ button and submit the form. After submitting the form you will get the streaming KEY and LINK. Please note that information filled in the form will be saved as your default information.',
        img: {
          x3: require('./assets/img1-4.png'),
        },
      },
      {
        id: 15,
        text:
          'Please use the streaming KEY & LINK generated in the previous step to start streaming using your favorite streaming software.',
      },
    ],
  },
  {
    id: 2,
    faqs: 'How to stream from Mobile ?',
    ans: [
      {
        id: 21,
        // text:
        // 'Download and install Streamlabs Download Streamlabs from here Open Streamlabs and Login using Other Platforms option',
        text:
          'Download and install Streamlabs from here https://streamlabs.com/Open and Login to streamlabs using Other Platforms option',
        img: {
          x1: require('./assets/img2-1.png'),
          x2: require('./assets/img2-1@2x.png'),
          x3: require('./assets/img2-1@3x.png'),
        },
      },
      {
        id: 22,
        // text:
        // 'Enter the Loco Streaming Link and Key Enter link and key which was generated from the App in this Screen Click on Continue',
        text:
          'Enter the Loco Streaming Link and Key which was generated from the App and then Click on Continue',
        img: {
          x1: require('./assets/img2-2.png'),
          x2: require('./assets/img2-2@2x.png'),
          x3: require('./assets/img2-2@3x.png'),
        },
      },
      {
        id: 23,
        text:
          'After Successful Login Click on Settings Icon in the upper left corner',
        img: {
          x3: require('./assets/img2-3@3x.png'),
        },
      },
      {
        id: 24,
        text: 'Then click on ‘Editor’',
        img: {
          x1: require('./assets/img2-4.png'),
          x2: require('./assets/img2-4@2x.png'),
          x3: require('./assets/img2-4@3x.png'),
        },
      },
      {
        id: 25,
        text: 'On this Screen, click on the Icon on Upper Right Corner',
        img: {
          x1: require('./assets/img2-5.png'),
          x2: require('./assets/img2-5@2x.png'),
          x3: require('./assets/img2-5@3x.png'),
        },
      },
      {
        id: 26,
        text: "You'll get Layer Screen Click on '+' button Select add layer",
        img: {
          x1: require('./assets/img2-6.png'),
          x2: require('./assets/img2-6@2x.png'),
          x3: require('./assets/img2-6@3x.png'),
        },
      },
      {
        id: 27,
        text:
          "You'll get Screen Capture Widget on the Screen You can Resize the Screen Capture Widget",
        img: {
          x1: require('./assets/img2-7.png'),
          x2: require('./assets/img2-7@2x.png'),
          x3: require('./assets/img2-7@3x.png'),
        },
      },
      {
        id: 28,
        // text:
        // "After completing the above steps, you'll get something like this Now click on Red Icon. This will broadcast your Stream on the app and viewers will be able to see your Game Stream",
        text:
          "After completing the above steps, you'll get something like this, Now click on Red Icon. This will broadcast your Stream on the app and viewers will be able to see your Game Stream",
        img: {
          x3: require('./assets/img2-8@3x.png'),
        },
      },
    ],
  },
  {
    id: 3,
    faqs: 'How to stream from PC ?',
    ans: [
      {
        id: 31,
        // text:
        // 'Download OBS from https://obsproject.com/Open OBS and go to settings',
        text:
          'Download OBS from https://obsproject.com/download OBS and go to settings',
        img: {
          x1: require('./assets/img3-1.png'),
          x2: require('./assets/img3-1@2x.png'),
          x3: require('./assets/img3-1@3x.png'),
        },
      },
      {
        id: 32,
        text:
          'In settings, click on Stream Here Select Loco under Service, Streaming link and Key Then click on OK',
        img: {
          x1: require('./assets/img3-2.png'),
          x2: require('./assets/img3-2@2x.png'),
          x3: require('./assets/img3-2@3x.png'),
        },
      },
      {
        id: 33,
        text:
          'Click on Start Streaming This will broadcast your Stream on the Loco and viewers will be able to see your Game Stream',
        img: {
          x1: require('./assets/img3-3.png'),
          x2: require('./assets/img3-3@2x.png'),
          x3: require('./assets/img3-3@3x.png'),
        },
      },
    ],
  },
  {
    id: 4,
    faqs: 'Android to Windows Screen Mirroring',
    endText: 'Voila! You are all Set for Screen Mirroring.',
    ans: [
      {
        id: 41,
        // text:
        // 'Download and Install Apowermirror to your windows laptop/pc. You can download Apowermirror from https://www.apowersoft.com/phone-mirror.',
        text:
          'Download and Install Apowermirror to your windows laptop/pc. You can download Apowermirror from https://www.apowersoft.com/phone-mirror',
      },
      {
        id: 42,
        text:
          'Download and Install Apowermirror to your Android device Download Apowermirror to your phone from Google Play Store Turn on USB Debugging on your Android device (Settings > Developer options)',
      },
      {
        id: 43,
        text:
          'Launch Apowermirror on your Windows Create a profile and Select USB connection on Top of the Window',
        img: {
          x1: require('./assets/img4-3.png'),
          x2: require('./assets/img4-3@2x.png'),
          x3: require('./assets/img4-3@3x.png'),
        },
      },
      {
        id: 44,
        text:
          'Open Apowermirror app on your Android Device And Connect the phone to windows laptop/pc using a compatible wire',
      },
      {
        id: 45,
        text:
          'Allow USB Debugging prompt on your Android device And check Always allow from this computer and click on Enabled,continue on your Apowermirror window on laptop/pc.',
        img: {
          x1: require('./assets/img4-5.png'),
          x2: require('./assets/img4-5@2x.png'),
          x3: require('./assets/img4-5@3x.png'),
        },
      },
      {
        id: 46,
        text:
          'Now Apowermirror will connect the windows to android device And before the mirroring starts you will get a notification on your Android Device asking for the permission to start Screen Mirroring > press start now',
        img: {
          x1: require('./assets/img4-6.png'),
          x2: require('./assets/img4-6@2x.png'),
          x3: require('./assets/img4-6@3x.png'),
        },
      },
    ],
  },
  {
    id: 5,
    faqs: 'iOS to Windows Screen Mirroring',
    endText:
      'Yay! All set for screen mirroring from your iOS device to Windows laptop/pc.',
    ans: [
      {
        id: 51,
        text:
          'Download and Install Apowermirror to your windows laptop/pc. You can download Apowermirror from https://www.apowersoft.com/phone-mirror.',
      },
      {
        id: 52,
        text:
          'Launch Apowermirror on your computer Create you account and under the Wifi Connection tab , click on iOS. (make sure iPhone and laptop/pc are on same wifi)',
        img: {
          x1: require('./assets/img5-2.png'),
          x2: require('./assets/img5-2@2x.png'),
          x3: require('./assets/img5-2@3x.png'),
        },
      },
      {
        id: 53,
        text:
          'On your iOS device Open Control Centre and Select Screen Mirroring.',
        img: {
          x1: require('./assets/img5-3.png'),
          x2: require('./assets/img5-3@2x.png'),
          x3: require('./assets/img5-3@3x.png'),
        },
      },
      {
        id: 54,
        text:
          'In Screen Mirroring window on your iOS device You will be able to see Apowersoft screen mirror , select it on your iOS device',
        img: {
          x3: require('./assets/img5-4.png'),
        },
      },
    ],
  },
  {
    id: 6,
    faqs: 'Android To MacOS Screen Mirroring',
    endText: 'Voila! You are all Set for Screen Mirroring.',
    ans: [
      {
        id: 61,
        text:
          'Download and Install Apowermirror to your windows laptop/pc. You can download Apowermirror from https://www.apowersoft.com/phone-mirror.',
      },
      {
        id: 62,
        text:
          'Download and Install Apowermirror to your Android device Download Apowermirror to your phone from Google Play Store Turn on USB Debugging on your Android device (Settings > Developer options)',
      },
      {
        id: 63,
        text:
          'Launch Apowermirror on your Windows Create a profile and Select USB connection on Top of the Window',
        img: {
          x1: require('./assets/img6-3.png'),
          x2: require('./assets/img6-3@2x.png'),
          x3: require('./assets/img6-3@3x.png'),
        },
      },
      {
        id: 64,
        text:
          'Open Apowermirror app on your Android Device And Connect the phone to Mac using a compatible wire',
      },
      {
        id: 65,
        text:
          'Allow USB Debugging prompt on your Android device And check Always allow from this computer and click on Enabled,continue on your Apowermirror window on laptop/pc.',
        img: {
          x1: require('./assets/img6-5.png'),
          x2: require('./assets/img6-5@2x.png'),
          x3: require('./assets/img6-5@3x.png'),
        },
      },
      {
        id: 66,
        text: 'Select your Device from the application window on your Mac',
        img: {
          x1: require('./assets/img6-6.png'),
          x2: require('./assets/img6-6@2x.png'),
          x3: require('./assets/img6-6@3x.png'),
        },
      },
      {
        id: 67,
        text:
          'Apowermirror will connect the Mac to android device And before the mirroring starts you will get a notification on your Android Device asking for the permission to start Screen Mirroring > press start now',
        img: {
          x1: require('./assets/img6-7.png'),
          x2: require('./assets/img6-7@2x.png'),
          x3: require('./assets/img6-7@3x.png'),
        },
      },
    ],
  },

  {
    id: 7,
    faqs: 'iOS To MacOS Screen Mirroring',
    endText:
      'Cheers!! we have successfully started screen mirroring from iOS to Mac.',
    ans: [
      {
        id: 71,
        text:
          'Download And Install QuickTime Player You can download QuickTime Player from https://support.apple.com/downloads/quicktime.',
      },
      {
        id: 72,
        text: 'Connect your iOS device to Mac using a compatible wire.',
      },
      {
        id: 73,
        text:
          'Launch QuickTime Player on your Mac > Go To File > New Movie Recording',
        img: {
          x3: require('./assets/img7-3.png'),
        },
      },
      {
        id: 74,
        text:
          'Inside the New Movie Recording window Click on the dropdown menu next to Record button > select your iOS device (Ex: I have selected my iPad in the picture)',
        img: {
          x3: require('./assets/img7-4@3x.png'),
        },
      },
    ],
  },
]
