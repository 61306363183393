import { Box, BoxProps, Flex, Stack } from '@chakra-ui/core'
import { FormikProps } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import PrimaryButton from '../../components/buttons/PrimaryButton'
import FormInput from '../../components/formInput/FormInput'
import { FormLayout } from '../../components/layout/FormLayout'
import { AadharCardFormValues } from '../../modules/Kyc/components/AadharCardForm'
import { DrivingLicenceFormValues } from '../../modules/Kyc/components/DrivingLicenceForm'
import { PanCardFormValues } from '../../modules/Kyc/components/PanCardForm'
import { VoterIdFormValues } from '../../modules/Kyc/components/VoterIdForm'
import { BankTransferFormValues } from '../../modules/ManageAccounts/components/BankTransferAccount/BankTransferForm'
import { OnboardingFormValues } from '../../modules/Onboarding/OnboardingForm'
import { SetupStreamFormValues } from '../../modules/SetupStream/SetupStreamForm'
import { ProfileDetailsFromValues } from '../../modules/StreamerProfile/Components/ProfileDetails/ProfileDetailsFrom'
import { UploadFormValues } from '../../modules/Upload/UploadVideoForm'
export interface inputField {
  name: string
  placeholder?: string
  label?: string | React.ReactNode
  wordCount?: boolean
  wordCountLimit?: number
  renderComponent?: React.ReactNode
  component?: React.ReactNode
  errorComponent?: React.ReactNode
  isWebViewBuild?: boolean
  extraProps?: Record<string, unknown>
  type?: string
  inputType?: string
  onChange?: (val: any) => any
  onBlur?: (val: any) => any
  title?: string
  tooltipLabel?: string
  accept?: string
  videoInfo?: boolean
  isUploadClips?: boolean
  tagCount?: boolean
  tagCountLimit?: number
  onFocus?: () => void
  required?: boolean
}
export interface inputFieldsProps {
  inputFields: inputField[]
  buttonProps?: any
  isReadOnly?: boolean
  formWidth?: any
  formLayoutProps?: BoxProps
}
const Form = (
  props: inputFieldsProps &
    (
      | FormikProps<VoterIdFormValues>
      | FormikProps<AadharCardFormValues>
      | FormikProps<DrivingLicenceFormValues>
      | FormikProps<OnboardingFormValues>
      | FormikProps<SetupStreamFormValues>
      | FormikProps<UploadFormValues>
      | FormikProps<ProfileDetailsFromValues>
      | FormikProps<BankTransferFormValues>
      | FormikProps<PanCardFormValues>
      | FormikProps<any>
    )
): JSX.Element => {
  const {
    touched,
    values,
    errors,
    isSubmitting,
    handleSubmit,
    handleBlur,
    handleChange,
    inputFields,
    buttonProps,
    formWidth,
    formLayoutProps,
  } = props

  const { t } = useTranslation()
  return (
    <FormLayout formLayoutProps={formLayoutProps}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4}>
          {inputFields.map((inputField: inputField) => {
            if (inputField.component) {
              return <Box key={inputField.name}>{inputField.component}</Box>
            }
            return (
              <Box key={inputField.name}>
                <FormInput
                  {...inputField}
                  formWidth={formWidth}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Box>
            )
          })}
        </Stack>

        {!props.isReadOnly ? (
          <Flex justifyContent="center">
            <PrimaryButton
              mt={4}
              // ml={0}
              w={['full', 'xs', 'xs']}
              color="white"
              bg={'brand.primary-blue'}
              isLoading={isSubmitting}
              type="submit"
              alignSelf="center"
              fontSize="sm"
              fontWeight="bold"
              rounded="8px"
              _focus={{
                outline: 'none',
              }}
              _active={{
                outline: 'none',
                bg: 'brand.primary-blue',
              }}
              _hover={{
                outline: 'none',
                bg: 'brand.primary-blue',
              }}
              style={{
                marginInlineStart: 0,
              }}
              {...buttonProps}
            >
              {buttonProps?.buttonText
                ? buttonProps.buttonText
                : t('form.submit')}
            </PrimaryButton>
          </Flex>
        ) : null}
      </form>
    </FormLayout>
  )
}

export default Form
