import React from 'react'

export const contentData = {
  title: 'Content, Community, & Growth',
  text:
    'Come create content, showcase your skills and build your community along the way! Learn how you can better understand your audience and engage with them so that they come back to see more of you. Here are some tips to help you get started! ',
  data: [
    {
      option: 1,
      academyHeadline: 'Find your voice',
      points: [
        {
          video: {
            url:
              'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Videos/Find+your+voice_Sumitxbinks_revised.mp4',
            poster:
              'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Images/Find+Your+Voice.jpg',
          },
        },
        {
          heading: 'Stream schedule',
          text: `Create a stream schedule and stick with it. Sticking to a schedule lets your viewers know at what time you will be live. Thus, ensuring that they tune in regularly.`,
        },
        {
          heading: 'Content exploration ',
          text:
            'It’s best when you keep a balance between playing your favourite game and trying out new ones. Feel free to stream any new games,  and content that helps you stand out.  You can always just chill  by streaming some IRL content!',
        },
        {
          heading: 'Tips for better content',
          text:
            'Once you select your game or content theme,  you can choose your streaming style. You can showcase your gameplay and content  in a unique way to entertain your audience.  You can also make your stream informative so that your community can learn something new from it. ',
        },
      ],
    },
    {
      option: 2,
      academyHeadline: 'Engage with your community',
      points: [
        {
          heading: 'Greeting Audience',
          text:
            'In-stream communication is the most crucial aspect of making your streams entertaining and helps in developing a deeper connection with your audience. The more you interact, the more your community will feel connected to you. ',
        },
        {
          heading: 'Chat replies ',
          text:
            'Interacting with individual viewers is one way to gratify them, and that can be done with ease by replying to their chats. It will  help if you encourage your viewers to chat with each other as it will keep your chat active while you stream! ',
        },
        {
          heading: 'Expressing your gameplay',
          text:
            'As a streamer, you must always talk about what’s happening in your game, communicate with your teammates playing with you,  or interact with your viewers for most of your stream. A viewer can join your stream at any time, so every second of your stream should be worth watching! ',
        },
      ],
    },
    {
      option: 3,
      academyHeadline: 'Build your community',
      points: [
        {
          video: {
            url:
              'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Videos/Build+your+community_Kani+Gaming+x+Sangwan.mp4',
            poster:
              'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Images/Build+your+community.jpg',
          },
        },
        {
          heading: 'Community and Streaming',
          text:
            'Bringing people who share the same ideas and thoughts under one roof is known as community building. At Loco, with your tremendous love for gaming and live streaming, you can connect with many more like you!',
        },
        {
          heading: 'Off-stream interactions ',
          text:
            'The first step in community building is creating profiles and groups on different platforms where your loyal and top fans can join in to have off-stream interactions.  By this, the viewer has an opportunity to talk to you even when the stream is not live.',
        },
        {
          heading: 'Discord',
          text: (
            <span>
              Discord is a platform where you can connect with people who share
              the same likes and interests. First and foremost, when you join
              <a
                style={{
                  color: '#FF5155',
                }}
                href="https://discord.com/invite/yfpKadP"
                target="_blank"
                rel="noreferrer"
              >
                {' Loco’s discord '}
              </a>
              channel, add your intro in the general-chat channel to introduce
              yourself to other streamers who have been streaming on our
              platform. You can share your stream while going live to get
              like-minded streamers and viewers to watch your stream.{' '}
            </span>
          ),
        },
      ],
    },
    {
      option: 4,
      academyHeadline: 'Goal Setting',
      points: [
        {
          heading: 'Target setting',
          text:
            'Setting short-term and long-term targets for your channel and letting your community know about the same gives them a strong purpose to support you. You can always track your performance in the analytics section and set monthly and weekly follower goals.',
        },
      ],
    },
  ],
}
