import { Box, Flex, FlexProps, Text } from '@chakra-ui/core'
import { withTheme } from 'emotion-theming'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { Container } from '../Container/Container'

export interface MainContainerProps {
  children?: React.ReactNode
  title?: string
  titleComponent?: React.ReactNode
  subtitle?: string
  innerContainerStyle?: FlexProps
  outerContainerStyle?: FlexProps
  titleTextAlign?: any
  documentTitle?: string
  width?: string
}

const MainContainer = ({
  children,
  documentTitle,
  title,
  subtitle,
  innerContainerStyle,
  outerContainerStyle,
  // titleTextAlign,
  titleComponent,
  width,
}: MainContainerProps): JSX.Element => {
  const { i18n } = useTranslation()
  const isRTL = i18n.resolvedLanguage !== 'en'

  return (
    <Container
      alignItems="center"
      bg="brand.primary-dark-black-v2"
      {...outerContainerStyle}
    >
      {documentTitle && (
        <Helmet>
          <title>{documentTitle}</title>
        </Helmet>
      )}
      <Flex maxW={width ? width : '700px'} w="full" direction="column">
        {titleComponent && titleComponent}
        {title || subtitle ? (
          <Box textAlign={isRTL ? 'right' : 'left'} pb={2} w="full">
            <>
              {title && (
                <Text fontSize={'sm'} fontWeight={900} letterSpacing={'1px'}>
                  {title}
                </Text>
              )}
              {subtitle && (
                <Text fontSize={['xs']} mt={2} fontStyle={'italic'}>
                  {subtitle}
                </Text>
              )}
            </>
          </Box>
        ) : null}
        <Flex
          w={'full'}
          justify={'center'}
          direction="column"
          alignSelf="center"
          alignItems="center"
          minH="fit-content"
          {...innerContainerStyle}
        >
          {children}
        </Flex>
      </Flex>
    </Container>
  )
}

export default withTheme(MainContainer)
