import { RootState } from '@app/RootReducer'
import { Box, Flex, Icon, Image, Text } from '@chakra-ui/core'
import {
  LatoFont,
  PoppinsFont,
  VT323Font,
} from '@components/lookback2022/styles.css'
import { paths } from '@routers/constants'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { isWebViewBuild } from '../../constent'
const Index = () => {
  const history = useHistory()
  const { accessToken } = useSelector((state: RootState) => state.app)
  const { me } = useSelector((state: RootState) => state.login)

  return (
    <Flex direction="column" width="100%">
      <Box bg="#230845" w="100%" h="56px">
        <Flex direction="row" padding="12px">
          <Icon
            name="leftArrow"
            size="24px"
            onClick={history.goBack}
            cursor="pointer"
            marginLeft="32px"
          />
          <Text marginLeft="24px" color="white" fontWeight="bold">
            <LatoFont> LookBack</LatoFont>
          </Text>
        </Flex>
      </Box>
      <div
        dangerouslySetInnerHTML={{
          __html: isWebViewBuild
            ? `<iframe src="https://loco.gg/lookback?type=streamer&token=${accessToken}&userId=${me?.user_uid}&isWebViewBuild=true" style="overflow:hidden;height:calc(100vh - 112px);width:100%" ></iframe>`
            : `<iframe src="https://loco.gg/lookback?type=streamer&token=${accessToken}&userId=${me?.user_uid}" style="overflow:hidden;height:calc(100vh - 112px);width:100%" ></iframe>`,
        }}
      />
      <Box
        bg="#230845"
        w="100%"
        h="56px"
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        color="white"
      >
        <Flex margin="auto">
          <Image
            src="/static/images/lookback/heart_pink.svg"
            alt="heart"
            width="30px"
            height="24px"
            mr={2}
          />
          <VT323Font>Your fans have left messages for you!</VT323Font>
        </Flex>
        <Flex margin="auto">
          <Box
            onClick={() => {
              history.push(paths.dashboard.MessageWall)
            }}
            fontSize="12px"
            fontWeight="bold"
            color="white"
            cursor="pointer"
            textAlign="center"
          >
            <PoppinsFont>VIEW MESSAGES</PoppinsFont>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

export default React.memo(Index)
