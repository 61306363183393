// import { batch } from 'react-redux';
import { Checkbox, Flex, Image, Stack, Text } from '@chakra-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  commonNotificationInfoParams,
  emailNotificationInfoStatus,
  updateNotificationInfoParams,
  whatsppNotificationInfoStatus,
} from '../../../api/notification'
import { RootState } from '../../../app/RootReducer'
import gmail from '../../../images/gmail_icon.png'
import whatsapp from '../../../images/whatsapp.png'
import { setModalStatus } from '../../Login/loginSlice'

interface props {
  handleWhatsappNotif: ({ channel, flag }: updateNotificationInfoParams) => void
  handleEmailNotification: ({
    channel,
    flag,
  }: updateNotificationInfoParams) => void
}

export const CommonModalHeader = () => {
  return (
    <Flex alignItems="center">
      <Text fontSize="md" fontWeight="bold" w="full" textAlign="left">
        Enable Notifications
      </Text>
    </Flex>
  )
}

const CommonModalBody = ({
  handleWhatsappNotif,
  handleEmailNotification,
}: props) => {
  const { notifPhoneNumber, notifEmail } = useSelector(
    (state: RootState) => state.login
  )
  const [checkedItems, setCheckedItems] = React.useState([false, false])
  const allChecked = checkedItems.every(Boolean)
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked
  const dispatch = useDispatch()

  const handleCommonNotification = ({
    whatsapp_status,
    email_status,
  }: commonNotificationInfoParams) => {
    email_status
      ? handleEmailNotification({
          channel: 'email',
          flag: emailNotificationInfoStatus.NOTIFON,
        })
      : handleEmailNotification({
          channel: 'email',
          flag: emailNotificationInfoStatus.NOTIFOFF,
        })

    whatsapp_status
      ? handleWhatsappNotif({
          channel: 'whatsapp',
          flag: whatsppNotificationInfoStatus.NOTIFON,
        })
      : handleWhatsappNotif({
          channel: 'whatsapp',
          flag: whatsppNotificationInfoStatus.NOTIFOFF,
        })

    dispatch(setModalStatus('CLOSE'))
  }

  return (
    <Flex w="full" height="full" flexDirection="column">
      <Flex maxW="xs" direction="column" flex={1}>
        <Text fontSize="sm">
          Select where you would you like to recieve alerts and updates for your
          streamer account :
        </Text>
      </Flex>
      <Flex maxW="xs" direction="column" flex={1}>
        <Stack marginTop="16px">
          <Checkbox
            variantColor="green"
            isChecked={checkedItems[0]}
            onChange={(e) =>
              setCheckedItems([e.target.checked, checkedItems[1]])
            }
          >
            <Text fontSize="sm">
              <Image
                display="inline-block"
                width="16px"
                height="16px"
                mr={2}
                ml={2}
                src={whatsapp}
              />{' '}
              whatsapp
              {notifPhoneNumber ? ` (+91 ******${notifPhoneNumber})` : ''}
            </Text>
          </Checkbox>
          <Checkbox
            variantColor="green"
            isChecked={checkedItems[1]}
            onChange={(e) =>
              setCheckedItems([checkedItems[0], e.target.checked])
            }
          >
            <Text fontSize="sm">
              <Image
                display="inline-block"
                width="16px"
                height="16px"
                mr={2}
                ml={2}
                src={gmail}
              />{' '}
              Email {notifEmail ? `(${notifEmail})` : ''}
            </Text>
          </Checkbox>
        </Stack>
      </Flex>
      <Flex fontSize="12px" justifyContent="flex-end" marginTop="16px">
        <Text
          cursor="pointer"
          onClick={() => {
            handleCommonNotification({
              whatsapp_status: false,
              email_status: false,
            })
          }}
          mr={4}
          fontSize="sm"
          fontWeight="bold"
        >
          May be later
        </Text>
        <Text
          as="button"
          color={isIndeterminate || allChecked ? '#744CFF' : '#999999'}
          fontSize="sm"
          fontWeight="bold"
          onClick={() => {
            handleCommonNotification({
              whatsapp_status: checkedItems[0],
              email_status: checkedItems[1],
            })
          }}
        >
          Enable
        </Text>
      </Flex>
    </Flex>
  )
}

export default CommonModalBody
