import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { batch } from 'react-redux'

import {
  beanWalletDetails,
  convertBeansToDiamond,
  validateConvertBeans,
  validateWithdrawBeans,
  walletTranscationsLog,
  withdrawBeansDetails,
  withdrawBeansRequest,
} from '../../api/apiRequest'
import { AppThunk } from '../../app/Store'
import { setToasts } from '../../hoc/WithToasts/withToastsSlice'
import { fetchGoldAndBeansAndGifts } from '../Analytics/analyticsSlice'

interface beanWalletRes {
  balance: number
  beans_to_diamond_detail: {
    conversion_rate: { left: number; right: number }
    status_message: string
  }
  beans_to_inr_detail: {
    conversion_rate: { left: number; right: number }
    status_message: string
  }
}

export interface gift {
  amount: number
  currency_type: number
  icon: string
  message: string
  timestamp: number
  transaction_status: number
  transaction_type: number
  transaction_uid: string
  user_uid: string
}
export interface withdrawalSuccessInterface {
  amount_deposited: string
  amount_withdrawn: string
  content_type: number
  created_at: string
  komparify_response: string
  loco_cut_amount: string
  meta: string
  object_id: number
  profile_details: {
    category_name: string
    phone?: string
    account_number?: string
    upi_id?: string
  }
  request_id: string
  status_check_due_at: null
  status_checks_count: number
  tds_amount: string
  transaction_scheduled_at: string
  transaction_state: number
  txn_uid: string
  updated_at: string
  user_uid: string
  withdrawal_category: number
}
const initialState: {
  loading: boolean
  transcationLoading: boolean
  error: any
  beanWallet: null | {
    balance: number
    beans_to_diamond_detail: {
      conversion_rate: { left: number; right: number }
      status_message: string
    }
    beans_to_inr_detail: {
      conversion_rate: { left: number; right: number }
      status_message: string
      redeemable_inr_factor: number
    }
    total_amount?: number
    tds?: number
    redeemable_amount?: number
  }
  next: string | null
  transcationLogError: any
  giftLogs: gift[]
  transcationLog: any[]
  transcationLogNext: string | null
  //transactionBeans
  beansTransactionValue: string | number | null
  //conversion
  conversion: {
    diamonds?: string | number | null
    deductibleBeans?: string | number | null
  } | null
  conversionError: string | null
  conversionSuccess: any
  //withdrawal
  withdrawal: {
    amount_withdrawn?: number | string | undefined
    tds?: number | string | undefined
    amount_deposited?: number | string | undefined
  } | null
  withdrawalError: string | null
  withdrawalSuccess: withdrawalSuccessInterface | null
  //wallet_details
  walletDetails:
    | {
        exists: boolean
        is_eligible: boolean
        logo_url: string
        name: string
        reason: string | null
        code: string | number | null
        user_details: { phone: string | null }
      }[]
    | null
  //amount_details
  amountDetails: {
    amount_withdrawn?: number | string | undefined
    tds?: number | string | undefined
    amount_deposited?: number | string | undefined
  } | null
} = {
  loading: false,
  transcationLoading: false,
  error: null,
  beanWallet: null,
  next: '0',
  giftLogs: [],
  transcationLogError: null,
  transcationLog: [],
  transcationLogNext: null,
  //transactionBeans
  beansTransactionValue: null,
  //conversion
  conversion: null,
  conversionError: null,
  conversionSuccess: null,
  //withdrawal
  withdrawal: null,
  withdrawalError: null,
  withdrawalSuccess: null,
  //wallet_details
  walletDetails: null,
  //amount_details
  amountDetails: null,
}

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setBeanWalletStart(state) {
      state.loading = true
      state.error = null
    },
    setBeanWalletEnd(state, action) {
      state.error = action.payload
      state.loading = false
    },
    setBeanWalletSuccess(state, action) {
      state.beanWallet = action.payload
      state.loading = false
      state.error = null
    },
    setBeanWalletLoader(state, action) {
      state.loading = action.payload
    },
    // transcation log
    setTranscationLogStart(state) {
      state.transcationLoading = true
      state.error = null
    },
    setTranscationLogEnd(state, action) {
      state.error = action.payload
      state.transcationLoading = false
    },
    setTranscationSuccess(state, action) {
      let updateGiftLogs = null
      if (state.next) {
        updateGiftLogs = _.unionWith(state.giftLogs, action.payload, _.isEqual)
      } else {
        updateGiftLogs = action.payload
      }

      state.giftLogs = updateGiftLogs
      state.loading = false
      state.error = null
    },
    setTranscationLoader(state, action) {
      state.loading = action.payload
    },
    setNext(state, action) {
      state.next = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    //transactionBeans
    setBeansTransactionValue(state, action) {
      state.beansTransactionValue = action.payload
    },
    //conversion
    setConversionError(state, action) {
      state.conversionError = action.payload
    },
    setConversion(state, action) {
      state.conversion = action.payload
    },
    setConsversionSuccess(state, action) {
      state.conversionSuccess = action.payload
    },
    //withdrawal
    setWithdrawal(state, action) {
      state.withdrawal = action.payload
    },
    setWithdrawalError(state, action) {
      state.withdrawalError = action.payload
    },
    setWithdrawalSuccess(state, action) {
      state.withdrawalSuccess = action.payload
    },
    //wallet_details
    setWalletDetails(state, action) {
      state.walletDetails = action.payload
    },
    //amount_details
    setAmountDetails(state, action) {
      state.amountDetails = action.payload
    },
    //transactionLog
    setBeansTransactionLog(state, action) {
      let updateGiftLogs = null
      if (state.next) {
        updateGiftLogs = _.unionWith(
          state.transcationLog,
          action.payload,
          _.isEqual
        )
      } else {
        updateGiftLogs = action.payload
      }

      state.transcationLog = updateGiftLogs
      state.loading = false
      state.error = null
    },
    setTranscationLogNext(state, action) {
      state.transcationLogNext = action.payload
    },
  },
})

export const {
  setBeanWalletStart,
  setBeanWalletEnd,
  setBeanWalletSuccess,
  setTranscationLogStart,
  setTranscationLogEnd,
  setTranscationSuccess,
  setNext,
  setLoading,
  setError,
  //beans Transaction
  setBeansTransactionValue,
  //conversion
  setConversionError,
  setConversion,
  setConsversionSuccess,
  //withdrawal
  setWithdrawal,
  setWithdrawalError,
  setWithdrawalSuccess,
  //wallet_details
  setWalletDetails,
  //amount_details
  setAmountDetails,
  setTranscationLogNext,
  setBeansTransactionLog,
} = walletSlice.actions

export default walletSlice.reducer

export const fetchBeanWallet = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setBeanWalletStart())
    const res = await beanWalletDetails()
    //@ts-ignore
    if (res.beans_to_diamond_detail) {
      dispatch(setBeanWalletSuccess(res as beanWalletRes))
      dispatch(setBeanWalletEnd(res))
      //@ts-ignore
    } else if (res?.statusCode) {
      dispatch(setBeanWalletEnd(res))
    }
  } catch (err) {
    dispatch(setBeanWalletEnd(err))
  }
}

export const fetchTranscationLogs = (
  limit: number,
  offset: string,
  transaction_type: number
): AppThunk => async (dispatch) => {
  try {
    dispatch(setBeanWalletStart())
    const res = await walletTranscationsLog({
      limit: limit,
      offset: offset,
      transaction_type: transaction_type,
    })
    //@ts-ignore
    if (res?.results?.length) {
      if (transaction_type === 10) {
        dispatch(setTranscationLogNext(''))
        //@ts-ignore
        dispatch(setTranscationSuccess(res.results as gift[]))
        //@ts-ignore
        dispatch(setNext(res.next))
      }
      if (transaction_type === 20) {
        dispatch(setNext(''))
        //@ts-ignore
        //@ts-ignore
        dispatch(setBeansTransactionLog(res.results as gift[]))
        //@ts-ignore
        dispatch(setTranscationLogNext(res.next))
      }
      //@ts-ignore
    } else if (res?.results?.length === 0) {
      dispatch(setNext(''))
      dispatch(setTranscationLogNext(''))
      //@ts-ignore
    } else if (res?.statusCode) {
      dispatch(setTranscationLogEnd(res))
    }
  } catch (err) {
    dispatch(setTranscationLogEnd(err))
  }
}

export const fetchValidateConvertBeansToDiamonds = (
  beans: string | number
): AppThunk => async (dispatch) => {
  try {
    batch(() => {
      dispatch(setLoading(true))
      dispatch(setConversionError(null))
      dispatch(setConversion(null))
    })
    const res = await validateConvertBeans({ beans })
    if (res?.statusCode) {
      dispatch(setConversionError(res?.message))
      dispatch(setConversion(null))
    } else {
      dispatch(setConversion(res))
    }
  } catch (err) {
    dispatch(setError(err))
  } finally {
    dispatch(setLoading(false))
  }
}
export const fetchConvertBeansToDiamond = (
  beans: string | number
): AppThunk => async (dispatch) => {
  try {
    batch(() => {
      dispatch(setLoading(true))
      dispatch(setConversionError(null))
      dispatch(setConsversionSuccess(null))
    })
    const res = await convertBeansToDiamond({ beans })
    if (res?.statusCode) {
      batch(() => {
        dispatch(setConversionError(res?.message))
        dispatch(setConsversionSuccess(null))
      })
    } else {
      if (res['30']) {
        dispatch(setConsversionSuccess(res))
      }
      batch(() => {
        dispatch(fetchBeanWallet())
        dispatch(fetchGoldAndBeansAndGifts())
      })
    }
  } catch (err) {
    dispatch(setError(err))
  } finally {
    dispatch(setLoading(false))
  }
}
export const fetchValidateWithdrawBeansToMoney = (
  beans: string | number
): AppThunk => async (dispatch) => {
  try {
    batch(() => {
      dispatch(setLoading(true))
      dispatch(setWithdrawalError(null))
      dispatch(setWithdrawal(null))
    })
    const res = await validateWithdrawBeans({ beans })
    if (res?.statusCode) {
      dispatch(setWithdrawalError(res?.message))
      dispatch(setWithdrawal(null))
    } else {
      dispatch(setWithdrawal(res))
    }
  } catch (err) {
    dispatch(setError(err))
  } finally {
    dispatch(setLoading(false))
  }
}
export const fetchWithdrawBeansDetails = (): AppThunk => async (
  dispatch,
  state
) => {
  const {
    wallet: { beansTransactionValue: beans },
  } = state()
  try {
    batch(() => {
      dispatch(setLoading(true))
      dispatch(setWithdrawalError(null))
      dispatch(setAmountDetails(null))
      dispatch(setWalletDetails(null))
    })
    if (beans) {
      const res = await withdrawBeansDetails({ beans })
      if (res?.statusCode) {
        // dispatch(setWithdrawalError(res?.message));
        dispatch(setWithdrawal(null))
      } else {
        if (res['wallet_details']) {
          dispatch(setWalletDetails(res.wallet_details))
        }
        if (res['amount_details']) {
          dispatch(setAmountDetails(res.amount_details))
        }
      }
      return res
    }
  } catch (err) {
    dispatch(setError(err))
    return err
  } finally {
    dispatch(setLoading(false))
  }
}

export const createWithdrawBeansRequest = ({
  code,
}: {
  code: string | number
}): AppThunk => async (dispatch, state) => {
  const {
    wallet: { beansTransactionValue: beans },
  } = state()
  try {
    dispatch(setLoading(true))
    if (beans && code) {
      const res = await withdrawBeansRequest({ beans: +beans, code: +code })
      if (res?.txn_uid) {
        batch(() => {
          dispatch(setWithdrawalSuccess(res))
          dispatch(fetchBeanWallet())
          dispatch(fetchGoldAndBeansAndGifts())
        })
      } else {
        dispatch(
          setToasts({
            position: 'top',
            title: 'Something went wrong',
            description: 'Cannot fetch transaction details. Try again later.',
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        )
      }
    }
  } catch (err) {
    dispatch(setWithdrawalError(err))
  } finally {
    dispatch(setLoading(false))
  }
}
