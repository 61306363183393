import React, { useState } from 'react'
import { Text, Flex, Image, Icon } from '@chakra-ui/core'

const AnsComponent = ({ item, idx, faqId }) => {
  let src = item.img?.x3?.default
  const [imageLoaded, setImageLoaded] = useState(false)
  return (
    <Flex direction="column" align="center" justify="center">
      <Flex Direction="row" width="full" p={5}>
        <Flex
          height="24px"
          width="24px"
          minHeight="24px"
          minW="24px"
          borderRadius="50%"
          bg="brand.primary-blue"
          justify="center"
          align="center"
        >
          <Text textAlign="center" fontSize={12} verticalAlign="middle">
            {idx + 1}
          </Text>
        </Flex>
        <Text
          ml={2}
          mb={5}
          textAlign="left"
          fontSize="14px"
          wordBreak="break-word"
        >
          {item.text}
        </Text>
      </Flex>
      <Flex
        align="center"
        width="full"
        justify="center"
        bg="brand.primary-light-black-v2"
      >
        {item.img ? (
          <>
            <Flex
              w="100%"
              h={'300px'}
              justify="center"
              align="center"
              bg="brand.primary-light-black-v2"
              display={!imageLoaded ? 'flex' : 'none'}
            >
              <Icon name="hippoLoco" size="100px" />
            </Flex>
            <Image
              src={src}
              loading="lazy"
              onLoad={() => setImageLoaded(true)}
              alt="Image"
              h={[faqId === '0' ? '350px' : null]}
              objectFit={'contain'}
              w={'100%'}
              maxHeight={'350px'}
              display={imageLoaded ? 'flex' : 'none'}
              maxWidth={
                faqId === '6' ? 350 : faqId === '4' && idx === 3 ? 150 : null
              }
              p={faqId === '0' ? 4 : null}
              py={(faqId === '4' && idx === 3) || faqId === '0' ? 3 : null}
            />
          </>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default AnsComponent
