import { Box, Button, Icon, Stack, Text } from '@chakra-ui/core'
import React from 'react'

export const NoProfileOnLocoModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <Stack spacing={8} align={'center'} w="full">
      <Icon name="warning" width="60px" height="50px" color="#ffe351" />
      <Text fontWeight="bold" fontSize="18px">
        Account not found on Loco
      </Text>
      <Box fontSize="18px" opacity={0.6} textAlign="center">
        <Text>Please setup your stream centre on the LOCO</Text>
        <Text>app to access the LOCO web dashboard</Text>
      </Box>
      <Button
        width="150px"
        height="44px"
        fontSize="14px"
        borderRadius="8px"
        box-shadow="0 2px 9px 0 rgba(81, 31, 255, 0.08)"
        variantColor="progress"
        onClick={() => {
          onClose()
        }}
      >
        GOT IT!
      </Button>
    </Stack>
  )
}
