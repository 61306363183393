import React from 'react'
import { useRef } from 'react'
import ReactPlayer from 'react-player'

import playIcon from '../../modules/HowToStream/assets/playIcon.png'

const VideoPlayer = ({
  url,
  poster,
  onPlay,
}: {
  url: string
  poster: string
  onPlay?: () => void
}) => {
  const observer = React.useRef<IntersectionObserver>()
  const videoRef = useRef<ReactPlayer | null>(null)

  const observe = React.useCallback((node) => {
    observer.current = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        const player = videoRef.current?.getInternalPlayer() as HTMLVideoElement
        player?.pause()
      }
    })
    if (node) observer.current.observe(node)
  }, [])

  return (
    <div ref={observe}>
      <ReactPlayer
        ref={videoRef}
        url={url}
        playing={false}
        muted={false}
        controls={true}
        width="100%"
        height="100%"
        volume={1}
        playbackRate={1.0}
        preload={'true'}
        pip={false}
        playIcon={<img src={playIcon} />}
        onPlay={onPlay}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload noremoteplayback noplaybackrate',
              onContextMenu: (e: Event): void => e.preventDefault(),
              disablePictureInPicture: true,
              poster: poster,
            },
          },
        }}
      />
    </div>
  )
}

export default VideoPlayer
