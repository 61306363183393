import { Button, ButtonProps } from '@chakra-ui/core'
import React from 'react'

const PrimaryButton = (props: ButtonProps) => {
  return (
    <Button
      bg="brand.primary-blue"
      w={['full', 'fit-content']}
      minW="300px"
      rounded="8px"
      _active={{
        bg: 'brand.primary-blue',
      }}
      _hover={{
        bg: 'brand.primary-blue',
      }}
      _disabled={{
        bg: 'brand.disabled-button',
        color: 'brand.primary-white-v2',
        opacity: 0.6,
        cursor: 'not-allowed',
      }}
      {...props}
    >
      {props.children}
    </Button>
  )
}

export default PrimaryButton
