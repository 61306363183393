import { Box, useDisclosure } from '@chakra-ui/core'
import React from 'react'

import NewModal from '../../NewModal'
import LogoutConfirmationModal from './LogoutConfirmationModal'
import Menu from './Menu'

const SideBar = ({
  routes,
  menuListDisabled,
}: {
  routes: any
  menuListDisabled: boolean
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      <Box
        bg={'brand.primary-light-black-v2'}
        w="100%"
        h="100%"
        overflow="auto"
      >
        <Menu
          routes={routes}
          menuListDisabled={menuListDisabled}
          onOpen={onOpen}
        />
      </Box>
      <NewModal
        isOpen={isOpen}
        onClose={onClose}
        modalSize="md"
        isCentered={true}
        scrollBehaviour="outside"
        modalOverlayStyle={{
          zIndex: 1400,
        }}
        modalContentStyle={{
          bg: 'brand.primary-light-black-v4',
        }}
        modalHeaderStyle={{
          px: 0,
          pb: 0,
        }}
        modalBodyStyle={{
          p: 4,
        }}
        modalBodyComponent={<LogoutConfirmationModal onClose={onClose} />}
      />
    </>
  )
}

export default SideBar
