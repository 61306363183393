// @ts-nocheck
import { LoginScreenENUM, setLoginScreen } from '@app/appSlice'
import { RootState } from '@app/RootReducer'
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/core'
import { FullSpinnerPage } from '@components/FullSpinnerPage'
import MobileDrawer from '@components/layout/MobileDrawer'
import SideBar from '@components/layout/Sidebar'
import { fetchCurrentLiveStream } from '@modules/LiveStreamManager/LiveStreamManagerSlice'
import { getNotificationDetails } from '@modules/Login/loginSlice'
import CommonModal from '@modules/NotificationSettings/NotificationSettingsModal/CommonModal'
import Onboarding from '@modules/Onboarding'
import SetupStream from '@modules/SetupStream'
import React, { lazy, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import { isWebViewBuild } from '../../constent'
import { paths } from '../constants'

export interface RoutesProps {
  key: string
  path?: string
  exact: boolean
  component?: React.ElementType
  icon?: string
  name?: string
  isNew?: boolean
  tooltip?: string
  showInSidebar?: boolean
  withSidebar: boolean
  showInWebViewMenu?: boolean
  level?: number
  routes?: [RoutesProps]
  onlyAvailableInIndia?: boolean
  arbicName?: string
}

const webRoutes: RoutesProps[] = [
  {
    key: paths.dashboard.home,
    path: paths.dashboard.home,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: `home` */ '@modules/Home')
    ),
    icon: 'sidebar_home',
    name: 'Home',
    arbicName: 'الصفحة الرئيسية',
    isNew: false,
    showInSidebar: true,
    withSidebar: true,
  },
  {
    key: paths.dashboard.stream,
    path: paths.dashboard.stream,
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: `liveStreamManager` */ '@modules/LiveStreamManager'
      )
    ),
    icon: 'sidebar_stream',
    name: 'Manage Live Stream',
    arbicName: 'إدارة البث المباشر',
    isNew: true,
    showInSidebar: true,
    withSidebar: false,
  },
  {
    key: paths.dashboard.stickers,
    path: paths.dashboard.stickers,
    exact: false,
    component: lazy(() => import('@modules/Stickers/StickerRoutes')),
    icon: 'sidebar_stickers',
    name: 'My Stickers',
    isNew: true,
    showInSidebar: true,
    showInWebViewMenu: true,
    arbicName: 'My Stickers',
    onlyAvailableInIndia: true,
  },
  {
    key: paths.dashboard.analytics,
    path: paths.dashboard.analytics,
    exact: true,
    component: lazy(() => import('@modules/Analytics/Dashboard')),
    icon: 'sidebar_analytics',
    name: 'Streamer Analytics',
    isNew: false,
    showInSidebar: true,
    withSidebar: true,
    arbicName: 'تحليلات الستريمر',
  },
  {
    key: paths.dashboard.StreamEditId,
    path: paths.dashboard.StreamEditId,
    exact: true,
    name: 'Edit Videos',
    isNew: false,
    component: lazy(() =>
      import(
        /* webpackChunkName: `editVideos` */ '@modules/EditStreamOrVOD/EditStreamContainer'
      )
    ),
    showInSidebar: false,
    withSidebar: true,
    arbicName: 'فيديوهات',
  },
  {
    key: paths.dashboard.videos,
    path: paths.dashboard.videos,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: `videos` */ '@modules/Videos')
    ),
    icon: 'sidebar_videos',
    name: 'Videos',
    isNew: false,
    showInSidebar: true,
    withSidebar: true,
    arbicName: 'فيديوهات',
  },
  {
    key: paths.dashboard.clips,
    path: paths.dashboard.clips,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: `videos` */ '@modules/Clips')
    ),
    icon: 'sidebar_videos',
    name: 'Clips',
    isNew: false,
    showInSidebar: true,
    withSidebar: true,
    arbicName: 'مقاطع',
  },
  {
    key: paths.dashboard.kyc,
    path: paths.dashboard.kyc,
    exact: false,
    component: lazy(() =>
      import(/* webpackChunkName: `hypervergeKyc` */ '@modules/HypervergeKyc')
    ),
    // icon: 'kyc',
    name: 'KYC Verification',
    isNew: false,
    showInSidebar: false,
    withSidebar: true,
    onlyAvailableInIndia: true,
  },
  {
    key: paths.dashboard.manageAccounts,
    path: paths.dashboard.manageAccounts,
    exact: false,
    component: lazy(() =>
      import(/* webpackChunkName: `hypervergeKyc1` */ '@modules/HypervergeKyc')
    ),
    // icon: 'sidebar_manageAccount',
    name: 'KYC Verification',
    isNew: false,
    showInSidebar: false,
    withSidebar: true,
    onlyAvailableInIndia: true,
  },
  {
    key: paths.dashboard.wallet,
    path: paths.dashboard.wallet,
    exact: false,
    component: lazy(() =>
      import(/* webpackChunkName: `streamerWallet` */ '@modules/Wallet')
    ),
    icon: 'sidebar_streamerwallet',
    name: 'Streamer Wallet',
    isNew: false,
    showInSidebar: true,
    withSidebar: true,
    onlyAvailableInIndia: true,
    arbicName: 'محفظة الستريمر',
  },
  {
    key: paths.dashboard.streamerLeaderboard,
    path: paths.dashboard.streamerLeaderboard,
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: `streamerLeaderboard` */ '@modules/StreamerLeaderboard'
      )
    ),
    icon: 'sidebar_streamerleaderboard',
    name: 'Streamer Leaderboard',
    isNew: false,
    showInSidebar: true,
    withSidebar: true,
    arbicName: 'لوحة صدارة الستريمرز',
  },
  {
    key: 'community',
    icon: 'sidebar_community',
    name: 'Community',
    arbicName: 'المجتمع',
    isNew: false,
    showInSidebar: true,
    withSidebar: true,
    routes: [
      {
        key: paths.dashboard.community.moderators,
        path: paths.dashboard.community.moderators,
        exact: true,
        component: lazy(() =>
          import(
            /* webpackChunkName: `moderatorsList` */ '@modules/Community/Moderator/Moderator'
          )
        ),
        name: 'Moderators',
        withSidebar: true,
        showInSidebar: true,
        level: 1,
        arbicName: 'المشرفون',
      },
      {
        key: paths.dashboard.community.blockedWords,
        path: paths.dashboard.community.blockedWords,
        exact: true,
        component: lazy(() =>
          import(
            /* webpackChunkName: `moderatorsList` */ '@modules/Community/BlockWords/BlockWords'
          )
        ),
        name: 'Block Words',
        withSidebar: true,
        showInSidebar: true,
        level: 1,
        arbicName: 'الكلمات الممنوعة',
      },
      {
        key: paths.dashboard.community.blockedUsers,
        path: paths.dashboard.community.blockedUsers,
        exact: true,
        component: lazy(() =>
          import(
            /* webpackChunkName: `moderatorsList` */ '@modules/Community/MutedUsers/index'
          )
        ),
        name: 'Muted Users',
        withSidebar: true,
        showInSidebar: true,
        level: 1,
        arbicName: 'المستخدمون الصامتون',
      },
      {
        key: paths.dashboard.community.activities,
        path: paths.dashboard.community.activities,
        exact: true,
        component: lazy(() =>
          import(
            /* webpackChunkName: `moderatorsList` */ '@modules/Community/Activities/Logs'
          )
        ),
        name: 'Activities',
        withSidebar: true,
        showInSidebar: true,
        level: 1,
        arbicName: 'أنشطة',
      },
    ],
  },
  {
    key: paths.dashboard.guidelines,
    path: paths.dashboard.guidelines,
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: `streamerLeaderboard` */ '@modules/Guidelines'
      )
    ),
    icon: 'sidebar_guideline',
    name: 'Streamer Guidelines',
    arbicName: 'إرشادات الستريمر',
    isNew: false,
    showInSidebar: true,
    withSidebar: true,
    onlyAvailableInIndia: true,
  },
  {
    key: paths.dashboard.notificationsettings,
    path: paths.dashboard.notificationsettings,
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: `streamerLeaderboard` */ '@modules/NotificationSettings'
      )
    ),
    icon: 'notificationBellIcon',
    name: 'Notification Settings',
    arbicName: 'إعدادات الإشعارات',
    isNew: false,
    showInSidebar: true,
    withSidebar: true,
    onlyAvailableInIndia: true,
  },
  {
    key: paths.dashboard.profile,
    path: paths.dashboard.profile,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: `profile` */ '@modules/StreamerProfile')
    ),
    icon: 'sidebar_profile',
    name: 'Profile',
    isNew: false,
    showInSidebar: true,
    withSidebar: true,
    arbicName: 'الملف الشخصي',
  },
  {
    key: paths.dashboard.phoneNumberLink,
    path: paths.dashboard.phoneNumberLink,
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: `profile` */ '@modules/StreamerProfile/PhoneNumberLink'
      )
    ),
    name: 'Phonenumebr Link',
    withSidebar: true,
    onlyAvailableInIndia: true,
  },
  {
    key: paths.dashboard.help,
    path: paths.dashboard.help,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: `help&support` */ '@modules/Help')
    ),
    icon: 'sidebar_help',
    name: 'Help & Support',
    arbicName: 'المساعدة والدعم',
    isNew: false,
    showInSidebar: true,
    withSidebar: true,
    onlyAvailableInIndia: true,
  },
  {
    key: paths.logout,
    path: paths.logout,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: `logout` */ '@modules/Logout')
    ),
    icon: 'logout',
    name: 'Logout',
    arbicName: 'خروج',
    showInSidebar: true,
    withSidebar: true,
  },

  {
    key: paths.dashboard.ITRInformation,
    path: paths.dashboard.ITRInformation,
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: `logout` */ '@modules/Wallet/IncomeTax/ITRInformation'
      )
    ),
    name: 'ITRInformation',
    withSidebar: true,
    onlyAvailableInIndia: true,
  },
  {
    key: paths.dashboard.WhatsNew,
    path: paths.dashboard.WhatsNew,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: `WhatsNew` */ '@modules/WhatsNew')
    ),
    name: 'WhatsNew',
    withSidebar: true,
  },
]
const webViewRoutes: RoutesProps[] = [
  {
    key: paths.dashboard.streamConfig,
    exact: true,
    // component: lazy(() => import('@modules/Home')),
    icon: 'streamSetup',
    name: 'Stream Key & URL',
    isNew: false,
    showInWebViewMenu: true,
  },
  {
    key: paths.dashboard.home,
    path: paths.dashboard.home,
    exact: true,
    component: lazy(() => import('@modules/Home')),
    icon: 'sidebar_stream',
    name: "Today's Stream Details",
    isNew: false,
    showInWebViewMenu: true,
  },
  {
    key: paths.dashboard.stream,
    path: paths.dashboard.stream,
    exact: true,
    component: lazy(() => import('@modules/LiveStreamManager')),
    icon: 'sidebar_stream',
    name: 'Manage Live Stream',
    isNew: true,
    showInWebViewMenu: false,
  },
  {
    key: paths.dashboard.menu,
    path: paths.dashboard.menu,
    exact: true,
    component: lazy(() => import('@modules/Menu')),
    icon: 'sidebar_home',
    name: 'Menu',
    isNew: false,
    showInWebViewMenu: false,
  },
  {
    key: paths.dashboard.stickers,
    path: paths.dashboard.stickers,
    exact: false,
    component: lazy(() => import('@modules/Stickers/StickerRoutes')),
    component: lazy(() => import('@modules/Stickers/StickerRoutes')),
    icon: 'sidebar_stickers',
    name: 'My Stickers',
    isNew: true,
    showInSidebar: true,
    showInWebViewMenu: true,
    arbicName: 'My Stickers',
    onlyAvailableInIndia: true,
  },
  {
    key: paths.dashboard.analytics,
    path: paths.dashboard.analytics,
    exact: true,
    component: lazy(() => import('@modules/Analytics/Dashboard')),
    icon: 'sidebar_analytics',
    name: 'Streamer Analytics',
    isNew: false,
    showInWebViewMenu: true,
  },
  {
    key: paths.dashboard.videos,
    path: paths.dashboard.videos,
    exact: true,
    component: lazy(() => import('@modules/Videos')),
    icon: 'sidebar_videos',
    name: 'Videos',
    isNew: false,
    showInWebViewMenu: true,
    arbicName: 'فيديوهات',
  },
  {
    key: paths.dashboard.clips,
    path: paths.dashboard.clips,
    exact: true,
    component: lazy(() => import('@modules/Clips')),
    icon: 'sidebar_videos',
    name: 'Clips',
    isNew: false,
    showInWebViewMenu: true,
    arbicName: 'مقاطع',
  },
  {
    key: paths.dashboard.StreamEditId,
    path: paths.dashboard.StreamEditId,
    exact: true,
    name: 'Edit Videos',
    isNew: false,
    component: lazy(() =>
      import(
        /* webpackChunkName: `editVideos` */ '@modules/EditStreamOrVOD/EditStreamContainer'
      )
    ),
    showInSidebar: false,
    withSidebar: true,
  },
  {
    key: paths.dashboard.manageAccounts,
    path: paths.dashboard.manageAccounts,
    exact: false,
    component: lazy(() =>
      import(/* webpackChunkName: `hypervergeKyc1` */ '@modules/HypervergeKyc')
    ),
    // icon: 'sidebar_manageAccount',
    name: 'KYC Verification',
    isNew: false,
    showInWebViewMenu: false,
    showInSidebar: false,
    onlyAvailableInIndia: true,
  },
  {
    key: paths.dashboard.kyc,
    path: paths.dashboard.kyc,
    exact: false,
    component: lazy(() =>
      import(/* webpackChunkName: `hypervergeKyc` */ '@modules/HypervergeKyc')
    ),
    // icon: 'kyc',
    name: 'KYC Verification',
    isNew: false,
    showInWebViewMenu: false,
    onlyAvailableInIndia: true,
  },
  {
    key: paths.dashboard.wallet,
    path: paths.dashboard.wallet,
    exact: false,
    component: lazy(() => import('@modules/Wallet')),
    icon: 'sidebar_streamerwallet',
    name: 'Streamer Wallet',
    isNew: false,
    showInWebViewMenu: true,
    onlyAvailableInIndia: true,
  },
  {
    key: paths.dashboard.streamerLeaderboard,
    path: paths.dashboard.streamerLeaderboard,
    exact: true,
    component: lazy(() => import('@modules/StreamerLeaderboard')),
    icon: 'sidebar_streamerleaderboard',
    name: 'Streamer Leaderboard',
    isNew: false,
    showInWebViewMenu: true,
  },
  // {
  //   key: paths.dashboard.viewerLeaderboard,
  //   path: paths.dashboard.viewerLeaderboard,
  //   exact: true,
  //   component: lazy(() => import('@modules/ViewerLeaderboard')),
  //   icon: 'sidebar_streamerleaderboard',
  //   name: 'Viewer Leaderboard',
  //   isNew: false,
  //   showInWebViewMenu: true,
  //   onlyAvailableInIndia: true,
  // },
  {
    key: 'community',
    icon: 'sidebar_community',
    name: 'Community',
    showInWebViewMenu: true,
    isNew: false,
    routes: [
      {
        key: paths.dashboard.community.moderators,
        path: paths.dashboard.community.moderators,
        exact: true,
        component: lazy(() =>
          import(
            /* webpackChunkName: `moderatorsList` */ '@modules/Community/Moderator/Moderator'
          )
        ),
        name: 'Moderators',
        showInWebViewMenu: true,
        level: 1,
      },
      {
        key: paths.dashboard.community.blockedWords,
        path: paths.dashboard.community.blockedWords,
        exact: true,
        component: lazy(() =>
          import(
            /* webpackChunkName: `moderatorsList` */ '@modules/Community/BlockWords/BlockWords'
          )
        ),
        name: 'Block Words',
        showInWebViewMenu: true,
        level: 1,
      },
      {
        key: paths.dashboard.community.blockedUsers,
        path: paths.dashboard.community.blockedUsers,
        exact: true,
        component: lazy(() =>
          import(
            /* webpackChunkName: `moderatorsList` */ '@modules/Community/MutedUsers/index'
          )
        ),
        name: 'Muted Users',
        showInWebViewMenu: true,
        level: 1,
      },
      {
        key: paths.dashboard.community.activities,
        path: paths.dashboard.community.activities,
        exact: true,
        component: lazy(() =>
          import(
            /* webpackChunkName: `moderatorsList` */ '@modules/Community/Activities/Logs'
          )
        ),
        name: 'Activities',
        showInWebViewMenu: true,
        level: 1,
      },
    ],
  },
  {
    key: paths.dashboard.guidelines,
    path: paths.dashboard.guidelines,
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: `streamerLeaderboard` */ '@modules/Guidelines'
      )
    ),
    icon: 'sidebar_guideline',
    name: 'Streamer Guidelines',
    isNew: false,
    showInWebViewMenu: true,
    onlyAvailableInIndia: true,
  },
  {
    key: paths.dashboard.notificationsettings,
    path: paths.dashboard.notificationsettings,
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: `streamerLeaderboard` */ '@modules/NotificationSettings'
      )
    ),
    icon: 'notificationBellIcon',
    name: 'Notification Settings',
    isNew: false,
    showInWebViewMenu: true,
    onlyAvailableInIndia: true,
  },
  {
    key: paths.dashboard.profile,
    path: paths.dashboard.profile,
    exact: true,
    component: lazy(() => import('@modules/StreamerProfile')),
    icon: 'sidebar_profile',
    name: 'Profile',
    isNew: false,
    showInWebViewMenu: true,
  },
  {
    key: paths.dashboard.phoneNumberLink,
    path: paths.dashboard.phoneNumberLink,
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: `profile` */ '@modules/StreamerProfile/PhoneNumberLink'
      )
    ),
    isNew: false,
    name: 'Phonenumebr Link',
    showInWebViewMenu: false,
    onlyAvailableInIndia: true,
  },

  {
    key: paths.dashboard.help,
    path: paths.dashboard.help,
    exact: true,
    component: lazy(() => import('@modules/Help')),
    name: 'Help',
    isNew: false,
    showInWebViewMenu: false,
  },
  {
    key: paths.dashboard.WhatsNew,
    path: paths.dashboard.WhatsNew,
    exact: true,
    component: lazy(() => import('@modules/WhatsNew')),
    showInWebViewMenu: false,
    name: 'WhatsNew',
  },
  {
    key: paths.dashboard.ITRInformation,
    path: paths.dashboard.ITRInformation,
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: `logout` */ '@modules/Wallet/IncomeTax/ITRInformation'
      )
    ),
    name: 'ITRInformation',
    onlyAvailableInIndia: true,
  },
]

function renderRoutes(routes) {
  return routes.map((route) => {
    const temp = []
    if (route.component) {
      temp.push(
        <Route
          key={route.key || route.name}
          path={route.path}
          exact={route.exact}
          render={(routeProps) => (
            <Suspense fallback={<FullSpinnerPage />}>
              <route.component {...routeProps} routes={routes} />
            </Suspense>
          )}
        />
      )
    }
    if (route.routes?.length) {
      temp.push(...renderRoutes(route.routes))
    }
    return temp
  })
}

const performanceRoute = {
  key: paths.dashboard.performance,
  path: paths.dashboard.performance,
  exact: true,
  component: lazy(() => import('@modules/Performance')),
  icon: 'sidebar_analytics',
  name: 'Streamer Performance',
  isNew: false,
  showInSidebar: true,
  withSidebar: true,
  showInWebViewMenu: true,
}

const vipLeaderboard = {
  key: paths.dashboard.vipLeaderboard,
  path: paths.dashboard.vipLeaderboard,
  exact: true,
  component: lazy(() => import('@modules/VipLeaderboard')),
  icon: 'sidebar_vip_leaderboard',
  name: 'VIP Leaderboard',
  isNew: true,
  showInSidebar: true,
  withSidebar: true,
  showInWebViewMenu: true,
  onlyAvailableInIndia: true,
}

const NewDashboardRoute = () => {
  const dispatch = useDispatch()

  const {
    isOpen: isWhatsappNotifOpen,
    onClose: onWhatsappNotifClose,
  } = useDisclosure()
  const permissions = useSelector(
    (state: RootState) => state.login.viewerLeaderboardPermissions
  )

  const { whatsappNotifStatus, emailNotificationStatus } = useSelector(
    (state: RootState) => state.login
  )

  const {
    login: { me, loading: meLoading, vipLeaderboardPermissions },
    streamDetails: { defaultStreamDetails, loading: streamDetailsLoading },
    liveStreamManager: { isLive },
  } = useSelector((state: RootState) => state)

  const vipLeaderboardEnabled = vipLeaderboardPermissions?.vip_leaderboard

  const webViewBuild = isWebViewBuild
  const _webRoutes =
    permissions?.show_performance_target_analytics === 20
      ? [...webRoutes.slice(0, 5), performanceRoute, ...webRoutes.slice(5)]
      : webRoutes

  const _webViewRoutes =
    permissions?.show_performance_target_analytics === 20
      ? [
          ...webViewRoutes.slice(0, 10),
          performanceRoute,
          ...webViewRoutes.slice(10),
        ]
      : webViewRoutes

  const updatedWebRoutes = _webRoutes.map((route) => {
    if (
      vipLeaderboardEnabled &&
      route.key === paths.dashboard.viewerLeaderboard
    ) {
      return vipLeaderboard
    }
    return route
  })

  const updatedWebviewRoutes = _webViewRoutes.map((route) => {
    if (
      vipLeaderboardEnabled &&
      route.key === paths.dashboard.viewerLeaderboard
    ) {
      return vipLeaderboard
    }
    return route
  })

  const routesWithoutCountryFilter = webViewBuild
    ? updatedWebviewRoutes
    : updatedWebRoutes
  const isAVaialbeInYourCountry = me?.iso_code?.toUpperCase() === 'IN'

  let routes = routesWithoutCountryFilter.filter((route) => {
    if (route.onlyAvailableInIndia) {
      if (isAVaialbeInYourCountry) return route
    } else {
      return route
    }
  })

  const showOnboardingForm =
    !me?.avatar_url ||
    !me?.full_name ||
    !me?.gender ||
    !me?.primary_game ||
    !me?.bio ||
    !me?.username ||
    !me?.iso_code
  const showSetupStream =
    !defaultStreamDetails?.title ||
    !defaultStreamDetails?.description ||
    !defaultStreamDetails?.game ||
    !defaultStreamDetails?.tags ||
    !defaultStreamDetails?.thumbnail

  const withSidebarPaths = []
  const withoutSidebarPaths = []
  if (!defaultStreamDetails?.can_battleup) {
    routes = routes.filter((route) => route.name !== 'Battle Up')
  }

  function mapRoutesToSideBar(routes) {
    routes.forEach((route) => {
      if (route.routes) {
        mapRoutesToSideBar(route.routes)
      } else if (route.path) {
        route.withSidebar || route.showInWebViewMenu
          ? withSidebarPaths.push(route.path)
          : withoutSidebarPaths.push(route.path)
      }
    })
  }

  mapRoutesToSideBar(routes)

  useEffect(() => {
    dispatch(setLoginScreen(LoginScreenENUM.LoginOptionsScreen))
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.navigator.onLine) {
        ;(async () => {
          if (me?.user_uid) {
            dispatch(fetchCurrentLiveStream(me?.user_uid))
          }
        })()
      }
    }, 10 * 1000)
    return () => clearInterval(interval)
  }, [isLive, dispatch, me])

  // make sure me details and default stream details are there. user have accepted terms and condition and whatsappNotifStatus is null

  useEffect(() => {
    if (
      !showOnboardingForm &&
      !showSetupStream &&
      me?.is_sip_agreement_accept &&
      (whatsappNotifStatus === null || emailNotificationStatus === null)
    ) {
      dispatch(getNotificationDetails())
    }
  }, [
    me,
    me?.is_sip_agreement_accept,
    !me?.avatar_url,
    !me?.full_name,
    !me?.gender,
    !me?.primary_game,
    !me?.bio,
    !me?.username,
    !defaultStreamDetails?.title,
    !defaultStreamDetails?.description,
    !defaultStreamDetails?.game,
    !defaultStreamDetails?.tags,
    !defaultStreamDetails?.thumbnail,
    whatsappNotifStatus,
    emailNotificationStatus,
  ])

  return (
    <Flex
      h="93%"
      flexDirection="column"
      boxSizing="border-box"
      maxH="93vh"
      overflow="hidden"
    >
      <Flex h="full" w="full" overflow="hidden">
        {meLoading || streamDetailsLoading ? (
          <FullSpinnerPage position="relative" />
        ) : showOnboardingForm ? (
          <Flex h="full" w="full" overflow="auto">
            <Onboarding />
          </Flex>
        ) : showSetupStream ? (
          <>
            {!webViewBuild && (
              <Flex w={['18%']} display={['none', 'flex', 'flex']} h="100%">
                <SideBar routes={routes} menuListDisabled={showSetupStream} />
              </Flex>
            )}
            <Flex h="100%" w={['100%', '82%']} overflow="auto">
              <SetupStream defaultStreamDetails={defaultStreamDetails} />
            </Flex>
          </>
        ) : (
          <Switch>
            <Route path={[...withoutSidebarPaths]}>
              {routes.map((route) => {
                return route.component ? (
                  <Route
                    key={route.key || route.name}
                    path={route.path}
                    exact={route.exact}
                    render={(routeProps) => (
                      <Suspense fallback={<FullSpinnerPage />}>
                        <route.component {...routeProps} routes={routes} />
                      </Suspense>
                    )}
                  />
                ) : null
              })}
            </Route>
            <Route path={[...withSidebarPaths]}>
              <>
                {!webViewBuild && (
                  <Flex
                    w={'18%'}
                    display={['none', 'flex', 'flex']}
                    minWidth={['234px']}
                  >
                    <SideBar
                      routes={routes}
                      menuListDisabled={showSetupStream}
                    />
                  </Flex>
                )}
                {!webViewBuild && <MobileDrawer routes={routes} />}
                <Flex h="100%" w={['100%', '82%', '82%']} overflow="auto">
                  <Switch>{renderRoutes(routes)}</Switch>
                </Flex>
              </>
            </Route>
            <Redirect
              exact
              from={paths.dashboard.default}
              to={webViewBuild ? paths.dashboard.menu : paths.dashboard.home}
            />
            <Route component={NotFound} />
          </Switch>
        )}
      </Flex>
      {isWhatsappNotifOpen && (
        <CommonModal
          isOpen={isWhatsappNotifOpen}
          onClose={onWhatsappNotifClose}
        />
      )}
    </Flex>
  )
}

const NotFound = () => {
  const history = useHistory()
  return (
    <Flex
      as="section"
      height="full"
      width="full"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Text fontSize="48px" color="#fff" fontWeight="bold">
        404
      </Text>
      <Text fontSize="14px" color="#fff" fontWeight="bold">
        PAGE NOT FOUND
      </Text>
      <Box
        as="button"
        backgroundColor="#511fff"
        color="#fff"
        fontSize="16px"
        marginTop="32px"
        onClick={() => history.push('/dashboard/home/')}
        padding="10px 20px"
        borderRadius="8px"
      >
        BACK TO HOME
      </Box>
    </Flex>
  )
}

export default React.memo(NewDashboardRoute)
