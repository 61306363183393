import { Box, Flex, Text } from '@chakra-ui/core'
import React from 'react'

const tableContent = [
  {
    col1: 'Update your stream thumbnail & description before each stream',
    col2: 'Never upload a thumbnail which demean your viewers or followers',
  },
  {
    col1:
      'Include a little bit of text in the thumbnail depicting your stream for the day',
    col2: 'Don’t use inappropriate images for your thumbnail',
  },
  {
    col1:
      'Images used should be clear and easily understandable from the first look',
    col2: `Don’t copy other's content, create your unique content, which stands out`,
  },
  {
    col1:
      'Make sure to add overlays which match with the game you play while streaming.',
    col2:
      'Also make sure you can see all parts of the screen and the overlay doesn’t omit major screen parts out.',
  },
  {
    col1:
      'Streaming with your face-cam on helps your audience to connect with you better, so that’s a big YES!',
    col2: 'Don’t violate the Streamer Guidelines!',
  },
]

const AcademyTable = () => {
  return (
    <Flex direction={['column', 'row']} mt={4}>
      <Box>
        <Box
          key={0}
          border="1px solid #3F4C6B"
          backgroundColor={['rgba(34, 185, 100, 0.6)', 'rgba(34, 185, 100, 1)']}
        >
          <Text
            fontSize="lg"
            color="#FFFFFF"
            textAlign={['left', 'center']}
            p="4 4 3"
            pt="4"
            pb="4"
            pl="3"
          >
            {`Do's`}
          </Text>
        </Box>
        {tableContent.map((ele, index) => {
          return (
            <Box
              key={index + 1}
              border="1px solid #3F4C6B"
              backgroundColor="rgba(34, 185, 100, 0.2)"
              h={['auto', 20]}
            >
              <Text p="3" color="#FFFFFF" fontSize="sm">
                {ele.col1}
              </Text>
            </Box>
          )
        })}
      </Box>

      <Box mt={[6, 0]} h={['auto', '422px']}>
        <Box
          key={0}
          border="1px solid #3F4C6B"
          backgroundColor={[
            'rgba(255, 102, 102, 0.6)',
            'rgba(255, 102, 102, 1)',
          ]}
        >
          <Text
            fontSize="lg"
            color="#FFFFFF"
            textAlign={['left', 'center']}
            pt="4"
            pb="4"
            pl="3"
          >
            {`Don'ts`}
          </Text>
        </Box>
        {tableContent.map((ele, index) => {
          return (
            <Box
              key={index + 1}
              border="1px solid #3F4C6B"
              backgroundColor="rgba(255, 102, 102, 0.2)"
              h={['auto', 20]}
            >
              <Text p="3" color="#FFFFFF" fontSize="sm">
                {ele.col2}
              </Text>
            </Box>
          )
        })}
      </Box>
    </Flex>
  )
}

export default AcademyTable
