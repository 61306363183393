import * as amplitude from '@amplitude/analytics-browser'
import { LogLevel } from '@amplitude/analytics-types'
import { userAgentEnrichmentPlugin } from '@amplitude/plugin-user-agent-enrichment-browser'

import { amplitude_API_KEY } from '../../constent'
import { streamer_category_map } from './constants'

const DEFAULT_PARAMS = {
  logLevel:
    process.env.NEXT_PUBLIC_APP_STAGE === 'production'
      ? LogLevel.None
      : LogLevel.Warn,
  cookieOptions: { expiration: 365 }, // Expires in 1 year (would fallback to 10 years by default, which isn't GDPR compliant),
  attribution: { disabled: true },
  defaultTracking: {
    formInteractions: false,
    pageViews: false,
    sessions: false,
  },
}

export const initAmplitude = (): void => {
  const uaPlugin = userAgentEnrichmentPlugin({
    osName: true,
    osVersion: true,
    deviceManufacturer: true,
    deviceModel: true,
  })
  amplitude.add(uaPlugin)
  amplitude.init(amplitude_API_KEY, undefined, DEFAULT_PARAMS)
}

export const setAmplitudeUserId = (userId: string): void => {
  amplitude.setUserId(userId || undefined)
}

export const setAmplitudeUserProperties = (properties: {
  [key: string]: any
}): void => {
  if (!properties) {
    return
  }
  const identify = new amplitude.Identify()
  const Properties = Object.entries(properties)
  for (const data of Properties) {
    const [key, value] = data
    identify.set(key, value)
  }
  amplitude?.identify(identify)
}

export const sendAmplitudeData = (
  eventType: string,
  eventProperties: any
): void => {
  let updatedEventProperties = { ...eventProperties }
  if (updatedEventProperties?.streamer_type) {
    updatedEventProperties = {
      ...updatedEventProperties,
      streamer_category:
        //@ts-ignore
        streamer_category_map[updatedEventProperties.streamer_type],
    }
  }
  amplitude.track(eventType, updatedEventProperties)
}
