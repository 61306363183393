import { Box, Flex, Heading, Image, Stack } from '@chakra-ui/core'
import VideoPlayer from '@components/howToStreamVideoModal/videoPlayer'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const AcademyPointRow = ({
  image,
  text,
  heading,
  video,
  index,
}: {
  image: { default: string }
  text: string | JSX.Element
  heading: string | JSX.Element
  video: any
  index: number
}) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { i18n } = useTranslation()
  const isRTL = i18n.resolvedLanguage !== 'en'

  return (
    <Flex key={index} alignItems="center">
      <Stack>
        {heading && (
          <Box mt={4}>
            <Heading
              size="sm"
              color="white"
              textAlign={isRTL ? 'right' : 'left'}
            >
              {heading}
            </Heading>
          </Box>
        )}
        {image && (
          <Box>
            <Image
              src={image?.default}
              loading="lazy"
              onLoad={() => setImageLoaded(true)}
              display={imageLoaded ? 'flex' : 'none'}
              objectFit={'contain'}
              alt="guideline section images"
            />
          </Box>
        )}
        {text && (
          <Box pb={2} pt={2} fontSize="sm" fontWeight="400" flex={1}>
            {text}
          </Box>
        )}
        {video && (
          <Box>
            <VideoPlayer url={video?.url} poster={video?.poster} />
          </Box>
        )}
      </Stack>
    </Flex>
  )
}

export default AcademyPointRow
