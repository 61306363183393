import { createSlice } from '@reduxjs/toolkit'

import {
  getIncentiveData,
  getPerformanceData,
  getTableData,
  postCalculateIncentive,
} from '../../../api/apiRequest'
import { AppThunk } from '../../../app/Store'

interface tableitem {
  structure_id: string
  lwh_rate_start: number
  lwh_rate_end: number
  inr_per_lwh: number
  unique_live_views: number
  fixed_inr_per_lwh: number
  bonus_inr_per_lwh: number
  fixed_beans_per_lwh: number
  bonus_beans_per_lwh: number
  primary_order: number
  is_active: boolean
  created_at: string
  updated_at: string
}

interface initialStateInterface {
  tableData: tableitem[]
  loading: boolean
  error: any
  calculatedIncentive: null | {
    beans: number
    inr: number
    inr_to_beans_ratio: number
  }
  incentiveData: null | {
    isLoading: boolean
    sip_status: number
    current_cycle_performance_details: null | {
      total_hours_streamed: string
      no_of_streams: string
      live_watch_hours: string
      unique_live_users: string
      followers_gained: string
      no_of_valid_days: string
      beans_eligible: string
      tier_type: string
    }
    previous_cycle_performance_details: null | {
      total_hours_streamed: string
      no_of_streams: string
      live_watch_hours: string
      unique_live_users: string
      followers_gained: string
      no_of_valid_days: string
      beans_eligible: string
    }
  }
  performanceData: {
    isLoading: boolean
    target_details: {
      minimum_stream_hours: number
      minimum_valid_days: number
      minimum_live_watch_hours: number
      minimum_unique_live_viewers: number
    }
    performance_details: {
      total_hours_streamed: string
      live_watch_hours: string
      unique_live_users: string
      no_of_valid_days: string
    }
  }
}

const initialState: initialStateInterface = {
  tableData: [],
  loading: false,
  error: null,
  calculatedIncentive: null,
  incentiveData: {
    isLoading: true,
    sip_status: 0,
    current_cycle_performance_details: {
      total_hours_streamed: '0',
      no_of_streams: '0',
      live_watch_hours: '0',
      unique_live_users: '0',
      followers_gained: '0',
      no_of_valid_days: '0',
      beans_eligible: '0',
      tier_type: '',
    },
    previous_cycle_performance_details: {
      total_hours_streamed: '0',
      no_of_streams: '0',
      live_watch_hours: '0',
      unique_live_users: '0',
      followers_gained: '0',
      no_of_valid_days: '0',
      beans_eligible: '0',
    },
  },
  performanceData: {
    isLoading: true,
    target_details: {
      minimum_stream_hours: 0,
      minimum_valid_days: 0,
      minimum_live_watch_hours: 0,
      minimum_unique_live_viewers: 0,
    },
    performance_details: {
      total_hours_streamed: '0',
      live_watch_hours: '0',
      unique_live_users: '0',
      no_of_valid_days: '0',
    },
  },
}
const incentiveSlice = createSlice({
  name: 'incentive',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setTableData(state, action) {
      state.tableData = action.payload
    },
    setCalculatedIncentive(state, action) {
      state.calculatedIncentive = action.payload
    },
    setIncentiveData(state, action) {
      state.incentiveData = action.payload
    },
    setPerformanceData(state, action) {
      state.performanceData = action.payload
    },
  },
})

export const {
  setLoading,
  setError,
  setTableData,
  setCalculatedIncentive,
  setIncentiveData,
  setPerformanceData,
} = incentiveSlice.actions

export default incentiveSlice.reducer

export const fetchTableData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true))
    const res = await getTableData()
    dispatch(setTableData(res))
  } catch (err) {
    dispatch(setError(err))
  } finally {
    dispatch(setLoading(false))
  }
}
export const fetchCalculateIncentive = (hours: number): AppThunk => async (
  dispatch
) => {
  try {
    const res = await postCalculateIncentive({ live_watch_hour: hours })
    if (res['inr']) {
      dispatch(setCalculatedIncentive(res))
    }
  } catch (err) {
    // do nothing
  } finally {
    // do nothing
  }
}

export const fetchIncentiveData = (): AppThunk => async (dispatch) => {
  try {
    const res = await getIncentiveData()
    dispatch(setIncentiveData({ isLoading: false, ...res }))
  } catch (err) {
    // do nothing
  } finally {
    // do nothing
  }
}

export const fetchPerformanceData = (): AppThunk => async (dispatch) => {
  try {
    const res = await getPerformanceData()
    dispatch(
      setPerformanceData({
        isLoading: false,
        ...res,
      })
    )
  } catch (err) {
    // do nothing
  } finally {
    // do nothing
  }
}
