import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { Container } from '../../components/Container/Container'
import { Text, Box } from '@chakra-ui/core'
import AnsComponent from './AnsComponent'
import { HTS } from './HowToStreamData'

const HTSans = () => {
  let data = HTS
  const { id } = useParams()
  data = data[id]
  return (
    <Container
      sideBar={true}
      hideNavBar={true}
      p={0}
      bg="brand.primary-black-v2"
      maxH="100vh"
      overflow="auto"
      h="full"
      webViewChild={
        <Text fontSize="16px" fontWeight="bolder">
          {data.faqs}
        </Text>
      }
    >
      <Helmet>
        <title>{data.faqs}</title>
      </Helmet>
      <Box p={0} bg="brand.primary-black-v2">
        {data.ans?.map((item, idx) => {
          return <AnsComponent key={item.id} item={item} idx={idx} faqId={id} />
        })}
        <Text p={5} fontSize="14px">
          {data.endText}
        </Text>
      </Box>
    </Container>
  )
}

export default HTSans
