import {
  Accordion,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
} from '@chakra-ui/core'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import AcademyPointRow from '../AcademyPointRow'
import StreamerAcademyDropdown from '../StreamerAcademyDropdown'

const AcademyCategoryAccordion = ({
  academyData,
  setCardsMap,
  cardsMap,
}: {
  academyData: any
  setCardsMap: any
  cardsMap: any
}) => {
  useEffect(() => {
    cardsMap.set(academyData[0]?.academyHeadline, 1)
  }, [cardsMap])
  const { i18n } = useTranslation()
  const isRTL = i18n.resolvedLanguage !== 'en'
  return (
    <Accordion allowToggle defaultIndex={[0]} w="full">
      {academyData?.map((data: any, id: number) => {
        return (
          <AccordionItem
            key={id}
            border="none"
            style={{
              outline: 'none',
              borderTopColor: 'red',
              borderRadius: '12px',
            }}
            _focus={{ outline: 'none' }}
            bg="#0E0E0E"
            onClick={() => {
              cardsMap.set(data?.academyHeadline, 1)
              setCardsMap(cardsMap)
            }}
          >
            <AccordionHeader
              outline="none"
              style={{
                outline: 'none',
              }}
              _focus={{ outline: 'none' }}
            >
              <Box
                flex="1"
                textAlign={isRTL ? 'right' : 'left'}
                fontWeight="700"
              >
                {data?.academyHeadline}
              </Box>
              <AccordionIcon />
            </AccordionHeader>
            <AccordionPanel pb={4} color="#989898">
              {data?.containsDropDown && (
                <StreamerAcademyDropdown
                  id={data?.id}
                  containsTable={data?.containsTable}
                />
              )}
              {data?.points?.map(
                (
                  point: {
                    image: { default: string }
                    text: string | JSX.Element
                    heading: string | JSX.Element
                    video: any
                  },
                  index: number
                ) => {
                  return (
                    <AcademyPointRow
                      key={index}
                      image={point.image}
                      text={point.text}
                      heading={point.heading}
                      video={point.video}
                      index={index}
                    />
                  )
                }
              )}
            </AccordionPanel>
            <Divider height="1px" opacity={0} />
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

export default AcademyCategoryAccordion
