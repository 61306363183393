//@ts-nocheck
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../app/RootReducer'

const useVerloopChat = (): null => {
  const { me } = useSelector((state: RootState) => state.login)
  useEffect(() => {
    ;(function(w, d, s, u) {
      w.Verloop = function(c) {
        w.Verloop._.push(c)
      }
      w.Verloop._ = []
      w.Verloop.url = u
      const h = d.getElementsByTagName(s)[0],
        j = d.createElement(s)
      j.async = true
      j.id = 'verloop-script'
      j.src = 'https://locoapp.verloop.io/livechat/script.min.js'
      h.parentNode.insertBefore(j, h)
    })(window, document, 'script', 'https://locoapp.verloop.io/livechat')
  }, [])
  useEffect(() => {
    if (me?.username) {
      //@ts-ignore
      Verloop(function() {
        this.setUserParams({
          name: me?.username,
          email: me?.email,
        })
        this.setCustomField('user_uid', me?.user_uid, { scope: 'user' })
        this.setCustomField('source', 'dashboard', { scope: 'user' })
        this.setRecipe('aBRLqDgST7WH5bvSH')
      })
    }
  }, [me?.username])
  return null
}

export default useVerloopChat
