import { setIsDrawerOpen } from '@app/appSlice'
import { RootState } from '@app/RootReducer'
import { Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/core'
import { paths } from '@routers/constants'
//@types
import { RoutesProps } from '@routers/dashboardRoutes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

const MenuItem = ({
  route,
  menuListDisabled,
  onOpen,
}: {
  route: RoutesProps
  menuListDisabled?: boolean
  onOpen?: () => any
}) => {
  let isSelected = false
  const { pathname } = useLocation()
  const { isDrawerOpen } = useSelector((state: RootState) => state.app)
  const dispatch = useDispatch()
  const { i18n, t } = useTranslation()

  const isRTL = i18n.resolvedLanguage !== 'en'

  const history = useHistory()
  if (pathname === route.path || pathname === `${route.path}/`) {
    isSelected = true
  }
  const showTooltip = menuListDisabled ? undefined : false
  const tooltipLabel = t('onBoarding.streamSetup.disabledMsg')
  const Component = () => (
    <Tooltip
      label={tooltipLabel}
      aria-label={tooltipLabel}
      placement={'right'}
      hasArrow
      bg="white"
      color="black"
      w="220px"
      fontSize="xs"
      isOpen={showTooltip}
    >
      <Flex
        bg={isSelected ? 'brand.primary-light-black-v5' : ''}
        paddingLeft={3}
        paddingRight={[0, 3]}
        paddingBottom={route.level === 1 ? 2 : 3}
        paddingTop={route.level === 1 ? 2 : 3}
        rounded="8px"
        boxSizing="border-box"
        w="full"
        margin={0}
      >
        {route?.icon ? (
          <>
            <Icon
              //@ts-ignore
              name={isSelected ? `${route?.icon}_selected` : route?.icon}
              color="white"
              display={['none', 'flex', 'flex']}
              style={{
                marginInlineEnd: '1rem',
              }}
            />
            <Icon
              //@ts-ignore
              name={route?.icon}
              color="white"
              display={['flex', 'none', 'none']}
              style={{
                marginInlineEnd: '1rem',
              }}
            />
          </>
        ) : null}
        <Text
          color="white"
          fontSize="xs"
          fontWeight={isSelected ? 'bold' : '400'}
        >
          {isRTL ? route.arbicName : route?.name}
        </Text>
        {route?.isNew && (
          <Icon
            //@ts-ignore
            name="newTag"
            size="15px"
            width={'revert'}
            style={{
              marginInlineStart: '0.5rem',
            }}
          />
        )}
      </Flex>
    </Tooltip>
  )

  const menuItemClickHandler = () => {
    if (menuListDisabled) {
      return
    }
    if (route.path === paths.logout && onOpen) {
      onOpen()
    } else {
      if (isDrawerOpen) {
        dispatch(setIsDrawerOpen(false))
      }
      if (route.path) history.push(route?.path)
    }
  }
  return (
    <Box
      onClick={menuItemClickHandler}
      textDecoration="none"
      cursor={menuListDisabled ? 'not-allowed' : 'pointer'}
      style={{
        textDecoration: 'none',
      }}
    >
      <Flex
        bgImage={
          isSelected
            ? ' linear-gradient(191deg, rgba(50,197,255,1) 0%, rgba(182,32,224,1) 100%)'
            : ''
        }
        p={isSelected ? '1px' : '1px 1px 2px 1px'}
        rounded="8px"
        boxSizing="border-box"
      >
        <Component />
      </Flex>
    </Box>
  )
}

export default React.memo(MenuItem)
