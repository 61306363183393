import { getProfileInfo } from '@api/apiRequest/apiRequest'
import { CSSReset, ThemeProvider } from '@chakra-ui/core'
import { GoogleOAuthProvider } from '@components/GoogleLogin/GoogleLogin'
import { checkKycPrompt } from '@modules/HypervergeKyc/KycSlice'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import useVerloopChat from '../components/VerloopChat'
import { CLEVERTAP_ACCOUNT_ID } from '../constent'
import {
  fetchStreamerPermissions,
  fetchViewerLeaderboardPermissions,
  fetchVipLeaderboardPermissions,
  UserTypeEnum,
} from '../modules/Login/loginSlice'
import Routes from '../Route'
import customTheme from '../theme'
import { getTokenAsync } from './appSlice'
import { RootState } from './RootReducer'

const App = (): JSX.Element => {
  const { i18n } = useTranslation()
  const isRTL = i18n.resolvedLanguage !== 'en'

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getTokenAsync())
  }, [])
  useVerloopChat()

  const {
    app: {
      accessToken: storeAccessToken,
      refreshToken: storeRefreshToken,
      linkedGmail,
      linkedPhoneNumber,
    },
    login: { me, userType },
  } = useSelector((state: RootState) => state)

  useEffect(() => {
    const clevertap = {
      event: [],
      profile: [],
      account: [],
      onUserLogin: [],
      notifications: [],
      privacy: [],
    }
    // replace with the CLEVERTAP_ACCOUNT_ID with the actual ACCOUNT ID value from your Dashboard -> Settings page
    //@ts-ignore
    clevertap.account.push({ id: CLEVERTAP_ACCOUNT_ID })
    //@ts-ignore
    clevertap.privacy.push({ optOut: false }) //set the flag to true, if the user of the device opts out of sharing their data
    //@ts-ignore
    clevertap.privacy.push({ useIP: false }) //set the flag to true, if the user agrees to share their IP data
    ;(function() {
      //@ts-ignore
      window.clevertap = clevertap
      const wzrk = document.createElement('script')
      wzrk.type = 'text/javascript'
      wzrk.async = true
      wzrk.src =
        ('https:' == document.location.protocol
          ? 'https://d2r1yp2w7bby2u.cloudfront.net'
          : 'http://static.clevertap.com') + '/js/a.js'
      const s = document.getElementsByTagName('script')[0]
      //@ts-ignore
      s.parentNode.insertBefore(wzrk, s)
    })()
  }, [])

  useEffect(() => {
    //@ts-ignore
    window?.NativeUIWebInterface?.showNativeHeader(false)
    //@ts-ignore
    window?.NativeUIWebInterface?.shouldDisableNativeBackButton(true)
  }, [])

  useEffect(() => {
    ;(async () => {
      const reqAccountLinked = localStorage.getItem('required_account_linked')
      if (
        (storeAccessToken && storeRefreshToken && reqAccountLinked) ||
        (storeAccessToken &&
          storeRefreshToken &&
          linkedPhoneNumber &&
          linkedGmail) ||
        (storeAccessToken &&
          storeRefreshToken &&
          userType &&
          [
            UserTypeEnum.PARTNER,
            UserTypeEnum.ORGANIC,
            UserTypeEnum.PREMIUM,
          ].includes(userType))
      ) {
        if (me?.username) {
          getProfileInfo()
          dispatch(fetchViewerLeaderboardPermissions())
          dispatch(fetchVipLeaderboardPermissions())
        }
      }
    })()
  }, [
    storeAccessToken,
    storeRefreshToken,
    linkedPhoneNumber,
    linkedGmail,
    userType,
  ])

  useEffect(() => {
    if (me?.user_uid) {
      dispatch(checkKycPrompt())
      dispatch(fetchStreamerPermissions())
    }
  }, [me?.user_uid])

  return (
    <div
      dir={isRTL ? 'rtl' : 'ltr'}
      lang={i18n.language}
      style={{
        height: '100vh',
        overflow: 'auto',
        backgroundColor: '#050506',
      }}
    >
      <GoogleOAuthProvider>
        <ThemeProvider theme={customTheme}>
          <CSSReset />
          <Routes />
        </ThemeProvider>
      </GoogleOAuthProvider>
    </div>
  )
}

export default App

// content use
