import { serverUrl2 } from '../../constent'
import { handleEventCapture } from '../../utils/sentryEventCapture'
import { fetchWithAllErrorHandle, getAccessToken } from '../apiRequest'

interface SentOTPtoLinkPhoneNumberParams {
  phone: string
  country: string
  isSignUp?: boolean
}
export const sentOTPtoLinkPhoneNumber = async ({
  country,
  phone,
  isSignUp,
}: SentOTPtoLinkPhoneNumberParams) => {
  const Authorization = isSignUp
    ? getAccessToken()
    : localStorage.getItem('signup_access_token')
  const url = `${serverUrl2}/user/link_phone_otp/`
  const result = await fetchWithAllErrorHandle
    .url(url)
    .auth(Authorization!)
    .post({
      country,
      phone,
    })
    .json((json) => {
      return json
    })
    .catch((error) => {
      handleEventCapture(
        url,
        error?.message ? error?.message : 'Failed to Fetch Api'
      )
      return error
    })
  return result
}

interface VerifyOTPtoLinkPhoneNumberParams {
  phone: string
  code: string
  isSignUp?: boolean
}

export const verifyOTPtoLinkPhoneNumber = async ({
  code,
  phone,
  isSignUp,
}: VerifyOTPtoLinkPhoneNumberParams) => {
  const Authorization = isSignUp
    ? getAccessToken()
    : localStorage.getItem('signup_access_token')
  const url = `${serverUrl2}/user/link_phone/`
  const result = await fetchWithAllErrorHandle
    .url(url)
    .auth(Authorization!)
    .post({
      code: `${code}`,
      phone: `${phone}`,
    })
    .json((json) => {
      return json
    })
    .catch((error) => {
      handleEventCapture(
        url,
        error?.message ? error?.message : 'Failed to Fetch Api'
      )
      return error
    })
  return result
}
