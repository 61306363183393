import {
  sentOTPtoLinkPhoneNumber,
  verifyOTPtoLinkPhoneNumber,
} from '@api/profile'
import { setLinkedPhoneNumber } from '@app/appSlice'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  Text,
} from '@chakra-ui/core'
import { useCustomToast } from '@components/customToast'
import { paths } from '@routers/constants'
import { eventActions, eventConstants } from '@utils/Amplitude'
import { Form, Formik } from 'formik'
import i18n from 'i18next'
import { ResendOTP } from 'otp-input-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { RootState } from '../../../app/RootReducer'
import {
  setError,
  setOTPSignUpError,
  setOTPSignUpStart,
  setOTPSignUpSucess,
  setPhoneNumberSignupStart,
  setPhoneNumberSignUpSuccess,
  setSignupOtpRetryCount,
  setSignUpScreen,
  SignupScreenENUM,
} from '../SignupSlice'

export const SignUpOtpScreen = () => {
  const dispatch = useDispatch()
  const toast = useCustomToast()
  const history = useHistory()

  const { t, i18n } = useTranslation()
  const isRTL = i18n.resolvedLanguage !== 'en'

  const { linkedGmail, phoneCode } = useSelector(
    (state: RootState) => state.app
  )

  const goBack = () => {
    dispatch(setSignUpScreen(SignupScreenENUM.PhoneNumberScreen))
  }

  const {
    phoneNumber,
    OTPSignUpError,
    OTPSignUpLoading,
    otpRetryCount,
  } = useSelector((state: RootState) => state.signup)

  interface Values {
    OTP: string
  }

  const handleSendOtp = async ({
    country_code,
    phone_number,
  }: {
    phone_number: string
    country_code: string
  }) => {
    dispatch(setPhoneNumberSignupStart())
    const result = await sentOTPtoLinkPhoneNumber({
      country: country_code,
      phone: phone_number.toString(),
      isSignUp: true,
    })
    if (result.message === 'Otp sent') {
      dispatch(setPhoneNumberSignUpSuccess())
    } else if (result.error_code) {
      toast({
        position: 'top',
        title: result.message,
        description: '',
        status: 'error',
        duration: 3000,
      })
    } else if (result.statusCode) {
      toast({
        position: 'top',
        title: result.message,
        description: '',
        status: 'error',
        duration: 3000,
      })
    } else {
      toast({
        position: 'top',
        title: result.message,
        description: 'something went wrong',
        status: 'error',
        duration: 3000,
      })
    }
  }

  const handleVerifyOtp = async (code: string) => {
    if (phoneNumber) {
      dispatch(setOTPSignUpStart())
      const result = await verifyOTPtoLinkPhoneNumber({
        code: code,
        phone: phoneNumber!,
        isSignUp: true,
      })
      if (result?.statusCode) {
        eventActions.sendAmplitudeData(
          eventConstants.dashboard_link_phone_number,
          {
            link_mode: 'otp',
            phone_number: phoneNumber,
            response: 'fail',
            failure_msg: result?.message || 'Api throw error',
            otp_resend_count: otpRetryCount,
            source: 'onboarding',
          }
        )
        setError(result?.message)
        dispatch(setOTPSignUpError(''))
        toast({
          position: 'top',
          title: result.message,
          description: '',
          status: 'error',
          duration: 5000,
        })
      } else if (result?.error_code) {
        eventActions.sendAmplitudeData(
          eventConstants.dashboard_link_phone_number,
          {
            link_mode: 'otp',
            phone_number: phoneNumber,
            response: 'fail',
            failure_msg: result?.message || 'Api throw error',
            otp_resend_count: otpRetryCount,
            source: 'onboarding',
          }
        )
        setError(result?.message)
        dispatch(setOTPSignUpError(''))
        toast({
          position: 'top',
          title: isRTL ? 'رمز التحقق غير صحيح' : result.message,
          description: '',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      } else {
        eventActions.sendAmplitudeData(
          eventConstants.dashboard_link_phone_number,
          {
            link_mode: 'otp',
            phone_number: phoneNumber,
            response: 'success',
            failure_msg: undefined,
            otp_resend_count: otpRetryCount,
            source: 'onboarding',
          }
        )
        dispatch(setOTPSignUpSucess())
        dispatch(setLinkedPhoneNumber(`${phoneNumber}`))
        if (linkedGmail) {
          localStorage.setItem('prev_logged_in', 'yes')
          history.replace(paths.dashboard.default)
        } else {
          dispatch(setSignUpScreen(SignupScreenENUM.SignupOptionsScreen))
        }
      }
    }
  }

  return (
    <Flex
      flex={1}
      direction="column"
      alignItems="center"
      justifyContent={['start', 'center']}
      bg={['black', '#2c18a6']}
      maxH={['100%', '360px']}
      width={['100%', '360px']}
      color="white"
      px={[4, 0]}
      py={[4, 0]}
    >
      <Formik
        initialValues={{
          OTP: '',
        }}
        validationSchema={OTPSchema}
        enableReinitialize={true}
        onSubmit={(values: Values) => {
          handleVerifyOtp(values.OTP)
        }}
      >
        {({ errors, touched, handleBlur, handleChange, values }) => (
          <Form className="login-screen-form ">
            <Box w={['full', '300px']} mb={8}>
              <Icon
                name="arrow-back"
                color="white"
                cursor="pointer"
                size={'5'}
                onClick={goBack}
              />
            </Box>
            <Box w={['full', '300px']} mb={8}>
              <Text fontSize={['18px', '20px']} fontWeight="black">
                {t('login.phoneNumber.enterCode')}
              </Text>
              <Box fontSize={['12px', '14px']} mt={[0, 2]}>
                <Text opacity={0.6}>{t('login.phoneNumber.verifyText')}</Text>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text>
                    {phoneCode} - {phoneNumber}
                  </Text>
                  <Icon
                    opacity={0.6}
                    name="edit"
                    cursor="pointer"
                    onClick={goBack}
                    size="5"
                  ></Icon>
                </Flex>
              </Box>
            </Box>
            <FormControl
              w={['full', '300px']}
              mb={4}
              isInvalid={
                OTPSignUpError || (errors.OTP && touched.OTP) ? true : false
              }
            >
              <Input
                w={['full', '300px']}
                value={values.OTP}
                onChange={(e: any) => {
                  dispatch(setOTPSignUpError(null))
                  handleChange(e)
                }}
                onBlur={handleBlur}
                bg={['#1c1c1c', '#16086c']}
                borderRadius="5px"
                type="tel"
                pattern="[0-9]*"
                name="OTP"
                borderColor={['#1c1c1c', '#09003c']}
                placeholder="OTP"
              />
              {OTPSignUpError || (errors.OTP && touched.OTP) ? (
                <FormErrorMessage mt={0}>
                  {(isRTL && OTPSignUpError
                    ? `رمز التحقق غير صحيح`
                    : OTPSignUpError) || errors.OTP}
                </FormErrorMessage>
              ) : (
                false
              )}
            </FormControl>
            <ResendOTP
              maxTime={30}
              renderTime={() => {
                // do nothing
              }}
              renderButton={(data: any) => (
                <Button
                  variant="ghost"
                  px={0}
                  mb={8}
                  height="auto"
                  isDisabled={data.disabled}
                  onClick={data.onClick}
                  color="white"
                  fontSize="12px"
                  _focus={{
                    outline: 'transparent',
                    backgroundColor: 'transparent',
                  }}
                  _hover={{
                    outline: 'transparent',
                    backgroundColor: 'transparent',
                  }}
                  _disabled={{
                    opacity: 0.6,
                    cursor: 'no-drop',
                  }}
                >
                  <Text>
                    {t('login.phoneNumber.resend')}{' '}
                    {data.remainingTime ? (
                      <Text as="span">({data.remainingTime})</Text>
                    ) : null}
                  </Text>
                </Button>
              )}
              onResendClick={() => {
                dispatch(setSignupOtpRetryCount(1))
                handleSendOtp({
                  country_code: 'IN',
                  phone_number: phoneNumber!,
                })
              }}
            />
            <Button
              w={['full', '300px']}
              bg={['#511fff', 'white']}
              //@ts-ignore
              color={['white', '#511fff']}
              size="md"
              isLoading={OTPSignUpLoading}
              type="submit"
            >
              {t('login.phoneNumber.verifyButton')}
            </Button>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}

const OTPNum = /[0-9]\d{3}$/

const OTPSchema = Yup.object().shape({
  OTP: Yup.string()
    .matches(OTPNum, `${i18n.t('login.phoneNumber.invalidOtpError')}`)
    .max(4, `${i18n.t('login.phoneNumber.maxOtpError')}`)
    .required(`${i18n.t('login.phoneNumber.invalidOtpError')}`),
})
