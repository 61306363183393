import { Flex, Icon, useDisclosure } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { setIsDrawerOpen } from '../../../../app/appSlice'
import { RootState } from '../../../../app/RootReducer'
import { paths } from '../../../../routers/constants'
import TooltipModal from '../../../tooltipModal'
import HeaderCenter from '../HeaderCenter'
import HeaderSupport from '../HeaderSupport'

const HeaderMobile = ({ disableMenu }: { disableMenu: boolean }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isLive } = useSelector((state: RootState) => state.liveStreamManager)

  const handleDrawer = () => {
    if (disableMenu) {
      onOpen()
      return
    }
    dispatch(setIsDrawerOpen(true))
  }
  const isLSM = location.pathname === paths.dashboard.stream
  const isWhatsNew = location.pathname === paths.dashboard.WhatsNew
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <>
      <Flex px={1} align="center" w="full" justify="space-between">
        {!isLSM && (
          <Flex flex={1}>
            <Icon name="menu" size="6" onClick={handleDrawer} />
          </Flex>
        )}
        <Flex flex={2} justify={isLSM ? 'flex-start' : 'center'} align="center">
          <Icon
            //@ts-ignore
            name="loco"
            color="white"
            size="20"
            onClick={() => {
              history.push(paths.dashboard.home)
            }}
          />
          {isLive && isLSM ? (
            <Icon
              //@ts-ignore
              name="liveTrue"
              size="14px"
              width="revert"
              ml={4}
            />
          ) : null}
        </Flex>
        <Flex flex={1} justify="flex-end">
          {isWhatsNew ? <HeaderSupport /> : <HeaderCenter />}
        </Flex>
      </Flex>
      {isOpen && (
        <TooltipModal
          isOpen={isOpen}
          onClose={onClose}
          title={t('onBoarding.streamSetup.disabled')}
          tooltipLabel={t('onBoarding.streamSetup.disabledMsg')}
        />
      )}
    </>
  )
}

export default HeaderMobile
