import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationsAR from './ar/ar.json'
import translationsEN from './en/en.json'
import translationsHE from './he/he.json'

const resources = {
  en: {
    translation: translationsEN,
  },
  he: {
    translation: translationsHE,
  },
  ar: {
    translation: translationsAR,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // debug: true,
    resources,
    ns: ['translation'],
    defaultNS: ['translation'],
    fallbackLng: 'en', // default language
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      caches: ['localStorage', 'cookie'],
    },
  })

export default i18n
