import TermsAndConditionModal from '@components/TermsAndConditionModal'
import CategoryContent from '@modules/HowToStream/CategoryContent'
import KycBannerWrapper from '@modules/HypervergeKyc/Components/KycBannerModal'
import Lookback2022 from '@modules/Lookback2022'
import OtplessWrapper from '@modules/OTPless/whatsapp_login'
import { Signup } from '@modules/Signup'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import { RootState } from './app/RootReducer'
import { useCustomToast } from './components/customToast'
import { FullSpinnerPage } from './components/FullSpinnerPage'
import Header from './components/layout/Header'
import HTSans from './modules/HowToStream/HTSans'
import HowToStream from './modules/HowToStream/index'
import { InternalError } from './modules/InternalError'
import { Login } from './modules/Login/Login'
import { Logout } from './modules/Logout'
import { NotFound } from './modules/NotFound'
import { StorageError } from './modules/StorageError'
import { paths } from './routers/constants'
import DashboardRoutes from './routers/dashboardRoutes'
import {
  PrivacyPolicy,
  StreamerAgreement,
  TermsConditions,
} from './webview/IFrame'

const ProtectedRouteWithHeader = React.memo(() => {
  const { accessToken, loading, linkedGmail, linkedPhoneNumber } = useSelector(
    (state: RootState) => state.app
  )
  const { userType } = useSelector((state: RootState) => state.login)
  const access_token = accessToken || localStorage.getItem('access_token')
  return loading ? (
    <FullSpinnerPage />
  ) : (
    <>
      <Header />
      <KycBannerWrapper />
      <TermsAndConditionModal />
      <Switch>
        <Route
          path={paths.dashboard.default}
          render={(props) => {
            if (
              (access_token && linkedGmail && linkedPhoneNumber) ||
              (accessToken && userType)
            ) {
              return <DashboardRoutes />
            } else {
              return (
                <Redirect
                  to={{
                    pathname: paths.login,
                    state: { from: props.location },
                  }}
                />
              )
            }
          }}
        />
      </Switch>
    </>
  )
})
ProtectedRouteWithHeader.displayName = 'ProtectedRouteWithHeader'

const routes = [
  {
    path: paths.login,
    component: Login,
    exact: true,
  },
  {
    path: paths.logout,
    component: Logout,
    exact: true,
  },
  {
    path: paths.signup,
    component: Signup,
    exact: true,
  },
  {
    path: paths['500'],
    component: InternalError,
    exact: true,
  },
  {
    path: paths['403'],
    component: NotFound,
    exact: true,
  },
  {
    path: paths.storageError,
    component: StorageError,
    exact: true,
  },
  {
    path: paths.privacy,
    component: PrivacyPolicy,
    exact: true,
  },
  {
    path: paths.agreement,
    component: StreamerAgreement,
    exact: true,
  },
  {
    path: paths.terms,
    component: TermsConditions,
    exact: true,
  },
  {
    path: paths.streamerAcademy,
    component: HowToStream,
    exact: true,
  },
  {
    path: paths.academyHowToStream,
    component: CategoryContent,
    exact: true,
  },
  {
    path: paths.academyContent,
    component: CategoryContent,
    exact: true,
  },
  {
    path: paths.academyMonetisation,
    component: CategoryContent,
    exact: true,
  },
  {
    path: paths.howToStreamId,
    component: HTSans,
    exact: true,
  },
  {
    path: paths.lookback,
    component: Lookback2022,
    exact: true,
  },
  {
    path: paths.dashboard.default,
    component: ProtectedRouteWithHeader,
    exact: false,
  },
]

// #without switch
const open_routes = [
  {
    path: paths.whatsappLogin,
    component: OtplessWrapper,
    exact: false,
  },
  {
    path: paths.whatsappLink,
    component: OtplessWrapper,
    exact: false,
  },
]
const Routes = React.memo(() => {
  const toast = useCustomToast()
  const { t } = useTranslation()

  window.addEventListener('offline', () => {
    toast({
      position: 'top',
      title: t('error.internet'),
      description: t('error.internetSubtitle'),
      status: 'warning',
      duration: 5000,
      isClosable: true,
    })
  })

  return (
    <Router>
      {open_routes.map((route) => (
        <Route
          key={route.path}
          exact={route.exact}
          path={route.path}
          // @ts-ignore
          render={(props) => <route.component {...props} />}
        />
      ))}
      {/* On Redirection, somehow dashboard.getloconow.com is redirect to index.html thus add redirect */}
      <Route exact path="/index.html" render={() => <Redirect to={'/'} />} />
      <Switch>
        <Route exact path="/" render={() => <Redirect to={paths.login} />} />
        {routes.map((route) => (
          <Route
            key={route.path}
            exact={route.exact}
            path={route.path}
            render={(props) => <route.component {...props} />}
          />
        ))}
      </Switch>
    </Router>
  )
})

Routes.displayName = 'Routes'

export default Routes
