import { Box, Button, Grid, Icon, Text } from '@chakra-ui/core'
import isMobile from 'is-mobile'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/RootReducer'
import { isWebViewBuild } from '../../constent'
import { fetchLanguages } from '../../modules/Login/loginSlice'
import { CategoryPickerSkeleton } from '../CategoryPicker/CategorPickerSkeleton'

interface LanguagePickerProps {
  onChange: (args: any) => void
  isReadOnly?: boolean
}

export const LanguagePicker = (
  props: LanguagePickerProps | any
): JSX.Element => {
  const { onChange, value, isReadOnly } = props
  const dispatch = useDispatch()
  const { languages, languagesLoading } = useSelector(
    (state: RootState) => state.login
  )
  const [noOfRows, setNoOfRows] = useState(1)
  const [languagesToShow, setLanguagesToShow] = useState<any>([])
  const [selectedOption, setSelecedOption] = useState<any>({})
  const [fakeLoading, setFakeLoading] = useState(false)
  const timerId = useRef<any>(null)
  const numberOfColumns = useRef<number>(isMobile() || isWebViewBuild ? 3 : 4)

  const { t } = useTranslation()

  const isLanguageFullyLoaded = useMemo(
    () =>
      languages.length !== 0 &&
      languagesToShow.length !== 0 &&
      noOfRows === Math.round(languages.length / numberOfColumns.current),
    [languages, languagesToShow, noOfRows]
  )

  useEffect(() => {
    if (languages.length === 0) {
      dispatch(fetchLanguages())
    }
  }, [dispatch, languages, languages.length])

  useEffect(() => {
    if (languages.length > 0) {
      setLanguagesToShow(() => [...languages])
    }
  }, [languages, languages.length])

  const handleChange = (item: any) => {
    onChange(item)
    setSelecedOption(() => item)
  }

  const handleSetTimeout = () => {
    clearTimeout(timerId.current)
    setFakeLoading(() => true)
    timerId.current = setTimeout(() => {
      setFakeLoading(() => false)
      setNoOfRows((prev) => (isLanguageFullyLoaded ? 1 : prev + 1))
    }, 500)
  }

  const handleLoadMore = () => {
    if (isLanguageFullyLoaded) {
      setNoOfRows(() => 1)
      if (selectedOption?.value) {
        setLanguagesToShow(() => [
          selectedOption,
          ...languages
            .slice(0, numberOfColumns.current * noOfRows)
            .filter((item) => item.value !== selectedOption.value),
        ])
      }
    } else {
      handleSetTimeout()
    }
  }

  const LanguageToShow = useCallback(() => {
    if (languages.length > 0) {
      const selectedLanguage = languages.find((lang) => lang.value === value)
      return (
        <Button
          key={selectedLanguage?.uid}
          backgroundColor={'brand.primary-blue'}
          flexShrink={1}
          outline={'none'}
          border={'1px solid #3F4C6B'}
          borderRadius={'99px'}
          display={'inline-block'}
          fontSize={'0.65rem'}
          fontWeight={'light'}
          textTransform={'uppercase'}
          width={'max-content'}
          height={'1.625rem'}
          textAlign={'center'}
          _hover={{
            background: 'brand.primary-blue',
          }}
          _active={{
            background: 'brand.primary-blue',
            outline: 'none',
          }}
          _focus={{
            background: 'brand.primary-blue',
            outline: 'none',
          }}
          opacity={0.6}
          isDisabled={true}
          cursor={'not-allowed'}
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '100%',
            padding: '0.25rem 0.75rem',
          }}
          userSelect={'none'}
        >
          {selectedLanguage?.label}
        </Button>
      )
    }
    return <></>
  }, [value, languages])

  return (
    <Box {...props} transition={'all 0.3s'}>
      <Grid
        templateColumns={`repeat(${numberOfColumns.current}, 1fr)`}
        templateRows={`repeat(${noOfRows}, 1fr)`}
        autoRows={0}
        overflow={'hidden'}
        transition={'all 0.3s'}
      >
        {languagesLoading || languagesToShow.length === 0 ? (
          <CategoryPickerSkeleton
            numberOfSkeleton={numberOfColumns.current}
            styles={{
              height: '1.625rem',
              width: '4.25rem',
              borderRadius: '99px',
              backgroundColor: '#1c1c1c',
            }}
          />
        ) : isReadOnly ? (
          <LanguageToShow />
        ) : (
          <>
            {languagesToShow?.map((item: any) => (
              <Button
                key={item.value}
                backgroundColor={
                  selectedOption?.value === item.value
                    ? 'brand.primary-blue'
                    : 'none'
                }
                userSelect={'none'}
                margin={'0.25rem 0'}
                outline={'none'}
                border={'1px solid #3F4C6B'}
                borderRadius={'99px'}
                display={'inline-block'}
                fontSize={'0.75rem'}
                fontWeight={'light'}
                textTransform={'uppercase'}
                width={'6rem'}
                height={'1.625rem'}
                textAlign={'center'}
                _hover={{
                  background:
                    selectedOption?.value === item.value
                      ? 'brand.primary-blue'
                      : 'none',
                }}
                _active={{
                  background:
                    selectedOption?.value === item.value
                      ? 'brand.primary-blue'
                      : 'none',
                  outline: 'none',
                }}
                _focus={{
                  background:
                    selectedOption?.value === item.value
                      ? 'brand.primary-blue'
                      : 'none',
                  outline: 'none',
                }}
                opacity={
                  selectedOption?.label && selectedOption?.value !== item.value
                    ? 0.6
                    : 1
                }
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  maxWidth: '100%',
                  padding: '0.25rem 0.75rem',
                }}
                onClick={() => {
                  handleChange(item)
                }}
              >
                {item.label}
              </Button>
            ))}
          </>
        )}
      </Grid>
      {fakeLoading && (
        <Grid
          templateColumns={`repeat(${numberOfColumns.current}, 1fr)`}
          templateRows={'1fr'}
          gridGap={'0.5rem'}
          marginTop={'0.25rem'}
        >
          <CategoryPickerSkeleton
            numberOfSkeleton={numberOfColumns.current}
            styles={{
              height: '1.625rem',
              width: '6rem',
              borderRadius: '99px',
              backgroundColor: '#1c1c1c',
              zIndex: '50',
            }}
          />
        </Grid>
      )}
      {!isReadOnly && (
        <Text
          background={'none'}
          fontSize={'0.75rem'}
          outline={'none'}
          margin={'0 auto'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          mt={'0.5rem'}
          cursor={'pointer'}
          onClick={handleLoadMore}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            padding={'0.3rem'}
            backgroundColor={'brand.primary-blue'}
            borderRadius={'50%'}
            mr={'5px'}
          >
            {isLanguageFullyLoaded ? (
              <Icon
                name="upArrow"
                style={{
                  width: '10px',
                  height: '10px',
                }}
              />
            ) : (
              <Icon
                name="plus"
                style={{
                  width: '10px',
                  height: '10px',
                }}
              />
            )}
          </Box>
          {isLanguageFullyLoaded
            ? t('clips.uploadForm.showLess')
            : t('clips.uploadForm.loadMore')}{' '}
        </Text>
      )}
    </Box>
  )
}
