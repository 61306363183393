import {
  Button,
  ButtonProps,
  Flex,
  Icon,
  Image as ChakraImage,
  useDisclosure,
} from '@chakra-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Crop } from 'react-image-crop'

import {
  ApiContext,
  streamDetailsInterface,
} from '../../context/ApiConnector/types'
import { dataURItoBlob } from '../../utils/dataURIoBlob'
import ModalCropComponent from './ModalCropComponent'

export interface CropComponentProps {
  title: string
  accept: string | undefined
  buttonStyle?: React.CSSProperties | undefined
  name: string
  onChange: (e: any) => any
  values: any
  defaultStreamDetails?:
    | ApiContext['defaultStreamDetails']
    | streamDetailsInterface
    | null
  errors: any
  touched: any
  isReadOnly?: boolean
  hidePreview?: boolean
  aspectRatio?: number
  buttonProps?: ButtonProps
  cropObj?: Crop
  convertImgType?: 'image/png' | 'image/jpeg'
  canvasConfig?: {
    width: number
    height: number
  }
}

function CropComponent({
  title,
  accept,
  buttonStyle,
  name,
  onChange,
  values,
  defaultStreamDetails,
  errors,
  touched,
  isReadOnly,
  hidePreview,
  aspectRatio,
  buttonProps,
  cropObj,
  convertImgType,
  canvasConfig,
}: CropComponentProps) {
  const { t } = useTranslation()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const inputRef = useRef()
  const [upImg, setUpImg] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [croppedImage, setCroppedImage] = useState()
  const [fileType, setFileType] = useState()

  const onSelectFile = (e: any) => {
    setFileType(e.target.files[0].type)

    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.onload = () => {
        const image: HTMLImageElement = new Image()
        //@ts-ignore
        image.src = reader.result
        setUpImg(reader.result)
      }
      reader.readAsDataURL(e.target.files[0])
      //@ts-ignore
      inputRef.current.value = null
    }
  }
  useEffect(() => {
    if (upImg) {
      onOpen()
      setLoading(true)
    }
  }, [upImg])
  const closeModal = () => {
    setUpImg(null)
    onClose()
  }
  const handleCroppedImage = (croppedImage: any) => {
    if (croppedImage) {
      setCroppedImage(croppedImage)
      const blob = dataURItoBlob(croppedImage)
      onChange(blob)
    } else {
      setCroppedImage(undefined)
      onChange(null)
    }
  }
  return (
    <>
      <input
        //@ts-ignore
        ref={inputRef}
        hidden
        type="file"
        accept={accept}
        onChange={onSelectFile}
      />
      {((!values?.thumbnail &&
        //@ts-ignore
        (defaultStreamDetails?.thumbnail ||
          //@ts-ignore
          defaultStreamDetails?.thumbnail_url_small)) ||
        croppedImage) &&
      !hidePreview ? (
        <Flex
          display="flex"
          flexWrap="wrap"
          w="full"
          borderRadius={'4px'}
          justifyContent="space-between"
          direction="row"
          align="center"
          flex={2}
          style={buttonStyle}
        >
          <ChakraImage
            flex={1}
            src={
              croppedImage ||
              //@ts-ignorẻ
              defaultStreamDetails?.thumbnail ||
              //@ts-ignorẻ
              defaultStreamDetails?.thumbnail_url_small
            }
            objectFit="contain"
            w="50%"
            rounded={'4px'}
          />

          <Flex flex={1} justify="center">
            {!isReadOnly && (
              <Flex
                color="white"
                fontSize="xs"
                fontWeight="extrabold"
                cursor="pointer"
                justifyContent="center"
                align="center"
                //@ts-ignore
                onClick={() => inputRef.current.click()}
              >
                {title || 'CHANGE THUMBNAIL'}{' '}
                <Icon
                  style={{
                    marginInlineStart: '10px',
                  }}
                  name="attachment"
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      ) : (
        <Button
          w="full"
          variant="outline"
          _focus={{
            borderColor: 'none',
          }}
          bg="brand.primary-text-field"
          border={
            errors && touched && errors[name] && touched[name] ? '1px' : 'none'
          }
          borderColor={
            errors && touched && errors[name] && touched[name]
              ? 'brand.primary-red'
              : 'none'
          }
          borderRadius={'4px'}
          variantColor="brand.primary-gray"
          loadingText={t('profile.updateProfile.uploading')}
          //@ts-ignore
          onClick={() => inputRef.current.click()}
          {...buttonProps}
          style={buttonStyle}
        >
          <Flex
            color="white"
            fontSize="xs"
            fontWeight="extrabold"
            w="full"
            justifyContent="center"
          >
            {title}{' '}
            <Icon
              style={{
                marginInlineStart: '10px',
              }}
              name="attachment"
            ></Icon>
          </Flex>
        </Button>
      )}
      <ModalCropComponent
        isOpen={isOpen}
        onClose={closeModal}
        upImg={upImg}
        loading={loading}
        setLoading={setLoading}
        handleCroppedImage={handleCroppedImage}
        aspectRatio={aspectRatio}
        cropObj={cropObj}
        fileType={fileType}
        convertImgType={convertImgType}
        canvasConfig={canvasConfig}
      />
    </>
  )
}

export default CropComponent
//
