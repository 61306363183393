import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/core'
import { UseGoogleLogin } from '@components/GoogleLogin/GoogleLogin'
import { checkIndianBrowser } from '@modules/OTPless/constant'
import { paths } from '@routers/constants'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { LoginScreenENUM, setError, setLoginScreen } from '../../app/appSlice'
import { RootState } from '../../app/RootReducer'
import NewModal from '../../components/NewModal'
import google from '../../images/google@2x.png'
import { LoginMobileHeader } from './LoginMobileHeader'
import { NotPremiumStreamerOnLocoModal } from './NotPremiumStreamerOnLocoModal'

interface GoogleAndMobileOptionLoginScreenProps {
  responseGoogle: (response: any) => void
}
export const GoogleAndMobileOptionLoginScreen = ({
  responseGoogle,
}: GoogleAndMobileOptionLoginScreenProps): JSX.Element => {
  const routes = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { isOpen, onClose, onOpen } = useDisclosure()
  const { error } = useSelector((state: RootState) => state.app)

  useEffect(() => {
    if (error) {
      onOpen()
    }
  }, [error])

  const handleClose = () => {
    dispatch(setError(null))
    onClose()
  }

  return (
    <Flex
      flex={1}
      height="100%"
      width="100%"
      direction="column"
      justifyContent="center"
      px={[4, 0]}
    >
      <Flex
        flex={1}
        bg={['#2c18a6', '#2c18a6']}
        maxH={['400px']}
        // maxH={['286px', '400px']}
        justifyContent="center"
        direction="column"
        borderRadius={['12px', 0]}
        overflow="hidden"
        px={[6, 0, 0]}
        alignItems="center"
      >
        <Box mb={8}>
          <LoginMobileHeader />
        </Box>
        <Box
          mb={4}
          w="300px"
          display={['none', 'block', 'block']}
          color="white"
          fontWeight="black"
        >
          <Text> {t('login.loginText')}</Text>
          <Text> {t('login.streamingText')}</Text>
        </Box>

        <UseGoogleLogin
          render={(renderProps) => {
            return (
              <Button
                w="full"
                width={['100%', '300px', '300px']}
                height="48px"
                borderColor="white"
                borderWidth="2px"
                fontSize="lg"
                fontWeight="bold"
                color="#231f20"
                outline="none"
                bg="white"
                _focus={{
                  outline: 'none',
                }}
                _hover={{
                  outline: 'none',
                  background: 'white',
                }}
                _active={{
                  outline: 'none',
                  background: 'white',
                }}
                rounded="8px"
                onClick={renderProps.onClick}
              >
                <Image
                  src={google}
                  w={6}
                  style={{
                    marginInlineEnd: '1rem',
                  }}
                />
                {t('login.email.title')}
              </Button>
            )
          }}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
        />
        <Button
          w="full"
          mt={8}
          width={['100%', '300px', '300px']}
          height="48px"
          borderColor="white"
          borderWidth="2px"
          fontSize="lg"
          fontWeight="bold"
          color="#231f20"
          outline="none"
          bg="white"
          _focus={{
            outline: 'none',
          }}
          _hover={{
            outline: 'none',
            background: 'white',
          }}
          _active={{
            outline: 'none',
            background: 'white',
          }}
          rounded="8px"
          onClick={() => {
            routes.push(paths.whatsappLogin)
          }}
        >
          <Icon
            name="whatsapp"
            w="6"
            h={12}
            style={{
              marginInlineEnd: '1rem',
            }}
          />
          {t('login.whatsapp.title')}
        </Button>
        {checkIndianBrowser() && (
          <Button
            w="full"
            mt={8}
            width={['100%', '300px', '300px']}
            height="48px"
            borderColor="white"
            borderWidth="2px"
            fontSize="lg"
            fontWeight="bold"
            color="#231f20"
            outline="none"
            bg="white"
            _focus={{
              outline: 'none',
            }}
            _hover={{
              outline: 'none',
              background: 'white',
            }}
            _active={{
              outline: 'none',
              background: 'white',
            }}
            rounded="8px"
            onClick={() => {
              dispatch(setLoginScreen(LoginScreenENUM.PhoneNumberScreen))
            }}
          >
            <Icon
              name="phone"
              w="6"
              style={{
                marginInlineEnd: '1rem',
              }}
            ></Icon>
            {t('login.phoneNumber.title')}
          </Button>
        )}
      </Flex>
      <NewModal
        isOpen={isOpen}
        onClose={handleClose}
        modalSize="md"
        isCentered={true}
        scrollBehaviour="outside"
        modalOverlayStyle={{
          zIndex: 1400,
        }}
        modalContentStyle={{
          bg: 'brand.primary-light-black-v4',
        }}
        modalHeaderStyle={{
          px: 0,
          pb: 0,
        }}
        modalBodyStyle={{
          p: 4,
        }}
        modalBodyComponent={
          <NotPremiumStreamerOnLocoModal onClose={handleClose} />
        }
      />
    </Flex>
  )
}
