import { Flex, Text } from '@chakra-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'

import {
  updateNotificationInfoParams,
  whatsppNotificationInfoStatus,
} from '../../../api/notification'
import { RootState } from '../../../app/RootReducer'

interface props {
  handleWhatsappNotif: ({ channel, flag }: updateNotificationInfoParams) => void
}

const WhatsappModalBody = ({ handleWhatsappNotif }: props) => {
  const { notifPhoneNumber } = useSelector((state: RootState) => state.login)

  return (
    <Flex w="full" height="full" flexDirection="column">
      <Flex maxW="xs" direction="column" flex={1}>
        <Text fontSize="10px" opacity={0.6}>
          Do you want to receive alerts and updates for your streamer account on
          your whatsapp no.{' '}
          <Text as="span" color="green.500">
            {notifPhoneNumber ? '******' + notifPhoneNumber + ' .' : ''}
          </Text>
        </Text>
      </Flex>
      <Flex fontSize="12px" justifyContent="flex-end">
        <Text
          cursor="pointer"
          onClick={() => {
            handleWhatsappNotif({
              channel: 'whatsapp',
              flag: whatsppNotificationInfoStatus.NOTIFOFF,
            })
          }}
          opacity={0.8}
          mr={4}
        >
          May be later
        </Text>
        <Text
          as="button"
          onClick={() => {
            handleWhatsappNotif({
              channel: 'whatsapp',
              flag: whatsppNotificationInfoStatus.NOTIFON,
            })
          }}
        >
          Yes
        </Text>
      </Flex>
    </Flex>
  )
}

export default WhatsappModalBody
