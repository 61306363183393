import React from 'react'

import {
  DASHBOARD_URL,
  DASHBOARD_WEBVIEW_URL,
  isWebViewBuild,
} from '../../../constent'
export const monetisationData = {
  title: 'Monetisation and Rewards',
  text:
    'Learn how to grown on Loco, monetisation currencies and get an understanding about becoming a partner streamer.',
  data: [
    // {
    //   option: 1,
    //   academyHeadline: 'Growth',
    //   points: [
    //     {
    //       text:
    //         'LOCO is India’s leading game streaming platform so there is plenty of scope for growth!  ',
    //       video: {
    //         url: 'https://static.getloconow.com/dashboard/how_to_stream.mp4',
    //         poster: 'https://static.getloconow.com/dashboard/tds.jpg',
    //       },
    //     },
    //   ],
    // },
    {
      option: 2,
      academyHeadline: 'Loco Economics',
      points: [
        {
          heading: 'Beans',
          text: `As mentioned earlier, the number of ‘Beans’ you have in your ‘Streamer Wallet’ determines your monthly incentive. There is a fixed conversion rate for beans which can be found after you enter the ‘Check Your Progress’ section.`,
        },
        {
          text: (
            <span>
              In the same section, you can find the ‘Beans Eligible’ and also
              the plantable for Beans conversion rate depending on the Live
              Watch Hour (LWH) rate. To better understand the bean conversion
              rate, check out{' '}
              <a
                style={{
                  color: '#FF5155',
                }}
                href={`${
                  isWebViewBuild ? DASHBOARD_WEBVIEW_URL : DASHBOARD_URL
                }/dashboard/incentives/scenarios`}
                target="_blank"
                rel="noreferrer"
              >
                ‘More Details’
              </a>{' '}
              on the Plan Table!
            </span>
          ),
        },
        {
          heading: 'Diamonds  ',
          text:
            'Just like Gold, ‘Diamonds’ are also Loco’s in-app currency. However, users have to purchase them with real money. At the moment, Diamonds can only be used to give ‘Stickers’ to your favourite streamers on their stream chat. The Diamonds obtained by the streamer are converted to ‘Beans’ and added to their streamer wallet within 24 - 48 hours!',
        },
        {
          heading: 'What do we look for in a partner streamer?',
          text: (
            <span>
              <ul style={{ marginLeft: '20px' }}>
                <li>
                  Streams often and consistently tags the correct game, stream
                  title, and thumbnail for their live streams
                </li>
                <li>Promotes positivity and an inclusive community </li>
                <li>Plays fair and adheres to Community Guidelines </li>
                <li>
                  Communicates with the viewers and expresses their gameplay
                  passionately
                </li>
              </ul>
            </span>
          ),
        },
      ],
    },
    {
      option: 3,
      academyHeadline: 'Partner Streamer Benefits',
      points: [
        {
          text:
            'Streamers with exceptional performance qualify for Loco’s streamer program and receive additional perks and benefits. Those who qualify receive early access to new features, get a chance to test them and provide feedback as well as participate in Loco exclusive events. These streamers will be contacted by managers from Loco, to boost their growth on the platform.',
        },
      ],
    },
    {
      option: 4,
      academyHeadline: 'Get Discovered',
      points: [
        {
          heading: 'Discover',
          text: `“Your Discovery is just a stream away!” 
                To ensure that every budding streamer gets visibility on the app, we’ve introduced the Discover tab. Streaming regularly will lead to a consistent viewership, eventually leading to your stream popping up on the Discover tab! `,
        },
        {
          heading: 'Trending',
          text:
            'This section is dedicated to the streams with highest concurrent watching. This feed refreshes every 5 minutes, giving every streamer a chance to showcase their exciting content and get more visibility! ',
        },
        {
          heading: 'Sharing ',
          text: `Sharing your streams with other people who might be interested is a must when you’re in the early stages of your streaming journey. It helps you get more views on your content, and if your content is good enough, you will surely see a steady yet significant rise in your viewership and following!`,
        },
        {
          text: `Sharing your content on the Discord server, WhatsApp, Instagram and Facebook with people who consume similar content is an excellent way to start!`,
        },
      ],
    },
  ],
}
