import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from '../../components/Container/Container'
import { useSelector, useDispatch } from 'react-redux'
import { Text, Stack, Box, Flex, Icon } from '@chakra-ui/core'
import FlexCard from './FlexCard'
import { paths } from '../../routers/constants'
import rewards from './assets/rewards.svg'
import stream from './assets/stream.svg'
import visuals from './assets/visuals.svg'
import academybg from './assets/academybg.png'
import academyBgMobile from './assets/academyBgMobile.svg'
import { eventActions, eventConstants } from '../../utils/Amplitude'
import { setPreviousVisit } from '../../app/appSlice'
import { Helmet } from 'react-helmet'
import { isWebViewBuild } from '../../constent'

const HowToStream = () => {
  const history = useHistory()
  const { previousVisit: previousVisitStore } = useSelector(
    (state) => state.app
  )
  const dispatch = useDispatch()
  const { me } = useSelector((state) => state.login)

  useEffect(() => {
    handleEventVisitStreamerAcademyDashboard()
  }, [])
  const handleEventVisitStreamerAcademyDashboard = () => {
    const eventProperties = {
      source_name: previousVisitStore,
      platform: eventConstants.platform,
      streamer_id: me?.user_uid,
    }
    const previousVisit = eventConstants.visit_streamer_academy.split(
      'visit_'
    )[1]
    dispatch(setPreviousVisit(previousVisit))
    eventActions.sendAmplitudeData(
      eventConstants.visit_streamer_academy,
      eventProperties
    )
  }

  return (
    <Container
      height="93vh"
      innerContainerStyle={{
        maxW: '700px',
        bgImage: [`url(${academyBgMobile})`, `url(${academybg})`],
        w: 'full',
        justify: 'center',
        direction: 'column',
        alignSelf: 'center',
        rounded: '8px',
        p: [0, 4],
      }}
      backgroundImage={[`url(${academyBgMobile})`, `url(${academybg})`]}
    >
      <Flex
        alignItems="left"
        direction="column"
        maxWidth="700px"
        marginTop="36px"
      >
        <Helmet>
          <title>Streamer Academy | Loco Streaming Center</title>
        </Helmet>
        <Stack spacing={2} mb={4}>
          <Flex>
            <Box
              display={['grid', 'none']}
              style={{
                marginInlineEnd: '0.5rem',
              }}
            >
              {!isWebViewBuild && (
                <Icon name="leftArrow" size="24px" onClick={history.goBack} />
              )}
            </Box>
            <Box
              w="full"
              fontSize={'md'}
              fontWeight={700}
              letterSpacing={'1px'}
            >
              Streamer Academy
            </Box>
          </Flex>
          <Box w="full" fontSize={'sm'} color="#cccccc">
            {`Ready to try live streaming but aren't sure where to start? Learn
            everything you need to know in this ultimate guide to live streaming`}
          </Box>
        </Stack>
        <Flex
          direction={['column', 'column', 'row']}
          spacing={2}
          alignItems={['inherit', 'center', 'inherit']}
        >
          <FlexCard
            buttonText="How to Stream"
            category="howToStream"
            image={stream}
          />
          <FlexCard
            buttonText="Content and Community"
            category="contentAndCommunity"
            image={visuals}
            imageStyle={{ bottom: '16px' }}
          />
          <FlexCard
            buttonText="Monetisation and Rewards"
            category="MonetisationAndRewards"
            image={rewards}
            imageStyle={{ bottom: '16px', opacity: 0.4 }}
          />
        </Flex>
      </Flex>

      <Box
        w={['full', 'full', '558px']}
        position={['relative', 'relative', 'absolute']}
        bottom={['0px', '20px']}
        fontSize="sm"
        fontWeight="400"
        textAlign="center"
        color="#666666"
        margin="40px 0px 20px 0px"
      >
        If you want to know more about setting up your stream and earning
        rewards you can visit the{' '}
        <Text
          as="span"
          cursor="pointer"
          color="#FF5155"
          onClick={() => {
            history.push(paths.dashboard.help)
          }}
        >
          help & feedback
        </Text>{' '}
        section on our app.
      </Box>
    </Container>
  )
}

export default HowToStream
