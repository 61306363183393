import { Flex, Icon, IconProps, Text, useToastOptions } from '@chakra-ui/core'
import React from 'react'

export interface CustomToastBoxProps {
  status: useToastOptions['status']
  onClose: useToastOptions['onClose']
  title: useToastOptions['title']
  description: useToastOptions['description']
  note?: string
  isClosable: useToastOptions['isClosable']
}

const CustomToastBox: React.SFC<CustomToastBoxProps> = ({
  status,
  onClose,
  title,
  description,
  note,
  isClosable,
}) => {
  let icon: {
    name: IconProps['name']
    color: IconProps['color']
  } = {
    name: undefined,
    color: undefined,
  }
  if (status) {
    //@ts-ignore
    icon =
      status === 'success'
        ? { name: 'check-circle', color: 'brand.primary-blue' }
        : status === 'error'
        ? { name: 'error', color: 'brand.primary-red' }
        : status === 'info'
        ? { name: 'info', color: 'brand.secondary-black' }
        : status === 'warning'
        ? { name: 'warning', color: 'yellow.400' }
        : undefined
  }
  return (
    <Flex
      w={['95vw', '45vw']}
      bg={'white'}
      direction="column"
      rounded={8}
      boxShadow={'md'}
      p={4}
      my={4}
    >
      <Flex justify="space-between" align="flex-start" w="full">
        <Icon
          name={icon.name}
          color={icon.color}
          size="32px"
          flex={1}
          alignSelf="center"
        />
        <Flex direction="column" flex={8} align="flex-start" ml={4}>
          <Text
            color="primary-light-black-v2"
            lineHeight={1}
            fontWeight="semibold"
          >
            {title}
          </Text>
          <Text>{description}</Text>
          {note && <Text>{note}</Text>}
        </Flex>
        <Flex flex={1} justify="flex-end" mt={-2} mr={-2}>
          {isClosable ? (
            <Icon
              //@ts-ignore
              name="closev2"
              cursor="pointer"
              onClick={onClose}
              size="5"
            />
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CustomToastBox
