import styled from '@emotion/styled'

export const PoppinsFont = styled.p`
  font-family: 'Poppins', sans-serif !important;
`

export const VT323Font = styled.p`
  font-family: 'VT323', sans-serif !important;
`

export const LatoFont = styled.p`
  font-family: 'Lato', sans-serif !important;
`
