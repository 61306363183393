import { RootState } from '@app/RootReducer'
import { Avatar, Button, Flex, Icon, Text } from '@chakra-ui/core'
import { paths } from '@routers/constants'
import { RoutesProps } from '@routers/dashboardRoutes'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { isWebViewBuild } from '../../../../constent'

const MenuItem = ({
  route,
  onOpen,
}: {
  route: RoutesProps
  onOpen?: () => any
}): JSX.Element => {
  const { me } = useSelector((state: RootState) => state.login)
  const history = useHistory()
  const onClickHandler = () => {
    if (paths.dashboard.streamConfig === route.key && onOpen) {
      onOpen()
    }
    if (route.path) history.push(route.path)
  }
  return (
    <Flex py={3} onClick={onClickHandler} justify="space-between">
      <Flex>
        {paths.dashboard.profile === route.key ? (
          <>
            <Avatar
              src={me?.avatar_url}
              h="20px"
              w="20px"
              objectFit="contain"
              showBorder={true}
              borderColor="white"
              boxSizing="border-box"
              mr={3}
            />
            <Text color="white" fontSize="xs" fontWeight={'bold'}>
              My Streamer Profile
            </Text>
          </>
        ) : (
          <>
            {route.icon ? (
              <Icon
                //@ts-ignore
                name={route.icon}
                size="4"
                mr={4}
                color="white"
              />
            ) : null}
            <Text
              color="white"
              fontSize="xs"
              fontWeight={'bold'}
              marginLeft={isWebViewBuild && !route.icon ? '16px' : 0}
            >
              {route.name}
            </Text>
          </>
        )}
        {route.isNew && (
          <Icon
            //@ts-ignore
            name="newTag"
            size="15px"
            mt={'1px'}
            ml={2}
            width={'revert'}
          />
        )}
      </Flex>
      {paths.dashboard.streamConfig === route.key && (
        <Button
          h="fit-content"
          py={1}
          bg="brand.primary-blue"
          _focus={{
            background: 'brand.primary-blue',
            outline: 'none',
          }}
          _active={{
            background: 'brand.primary-blue',
            outline: 'none',
          }}
          _hover={{
            background: 'brand.primary-blue',
            outline: 'none',
          }}
          fontSize="10px"
          fontWeight="black"
          minW="55px"
          color="white"
        >
          VIEW
        </Button>
      )}
      {paths.dashboard.home === route.key && (
        <Button
          h="fit-content"
          py={1}
          minW="55px"
          bg="brand.primary-blue"
          _focus={{
            background: 'brand.primary-blue',
            outline: 'none',
          }}
          _active={{
            background: 'brand.primary-blue',
            outline: 'none',
          }}
          _hover={{
            background: 'brand.primary-blue',
            outline: 'none',
          }}
          fontSize="10px"
          fontWeight="black"
          color="white"
        >
          EDIT
        </Button>
      )}
    </Flex>
  )
}

export default MenuItem
