import { Box, BoxProps } from '@chakra-ui/core'
import React from 'react'

export const FormLayout = ({
  children,
  formLayoutProps,
}: {
  children: React.ReactNode
  formLayoutProps?: BoxProps
}) => {
  return (
    <Box
      w={'full'}
      px={[0, 0, 6]}
      py={[0, 0, 4]}
      h={'auto'}
      {...formLayoutProps}
    >
      {children}
    </Box>
  )
}
