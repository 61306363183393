import { Checkbox, Flex, Link, Text } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { paths } from '../../routers/constants'

interface FormAgreementProps {
  name: string
  values: any
  handleChange: (val: any) => void
  isReadOnly?: boolean
}

const FormAgreement = (props: FormAgreementProps) => {
  const history = useHistory()
  const { t } = useTranslation()

  const { name, values, handleChange, isReadOnly } = props
  return !isReadOnly ? (
    <Flex alignItems="flex-start" fontSize="sm">
      <Checkbox
        name={name}
        variantColor="green"
        py="1"
        isChecked={values[name]}
        onChange={handleChange}
        size="sm"
        style={{
          marginInlineEnd: '0.5rem',
        }}
      />
      <Text as="span" fontWeight="lighter" color="brand.primary-white-v2">
        {t('home.todayStream.terms.part1')}{' '}
        <Link
          as="span"
          color="white"
          fontWeight="extrabold"
          onClick={() => {
            history.push(paths.terms)
          }}
        >
          {t('home.todayStream.terms.part2')}
        </Link>
        ,{' '}
        <Link
          as="span"
          color="white"
          fontWeight="extrabold"
          onClick={() => {
            history.push(paths.agreement)
          }}
        >
          {t('home.todayStream.terms.part3')}{' '}
        </Link>
        {t('home.todayStream.terms.part4')}{' '}
        <Link
          as="span"
          color="white"
          fontWeight="extrabold"
          onClick={() => {
            history.push(paths.privacy)
          }}
        >
          {t('home.todayStream.terms.part5')}
        </Link>
        .
      </Text>
    </Flex>
  ) : (
    <></>
  )
}

export default FormAgreement
