import { Flex, Text } from '@chakra-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'

import {
  emailNotificationInfoStatus,
  updateNotificationInfoParams,
} from '../../../api/notification'
import { RootState } from '../../../app/RootReducer'

interface props {
  handleEmailNotification: ({
    channel,
    flag,
  }: updateNotificationInfoParams) => void
}

const EmailModalBody = ({ handleEmailNotification }: props) => {
  const { notifEmail } = useSelector((state: RootState) => state.login)

  return (
    <Flex w="full" height="full" flexDirection="column">
      <Flex maxW="xs" direction="column" flex={1}>
        <Text fontSize="sm" opacity={0.6}>
          Do you want to receive alerts and updates for your streamer account on
          your Mail ID{' '}
          <Text as="span" color="#FF5155">
            {notifEmail}
          </Text>
        </Text>
      </Flex>
      <Flex fontSize="sm" justifyContent="flex-end">
        <Text
          cursor="pointer"
          onClick={() => {
            handleEmailNotification({
              channel: 'email',
              flag: emailNotificationInfoStatus.NOTIFOFF,
            })
          }}
          opacity={0.8}
          mr={4}
        >
          May be later
        </Text>
        <Text
          as="button"
          onClick={() => {
            handleEmailNotification({
              channel: 'email',
              flag: emailNotificationInfoStatus.NOTIFON,
            })
          }}
        >
          Yes
        </Text>
      </Flex>
    </Flex>
  )
}

export default EmailModalBody
