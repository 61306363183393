import {
  otplessLinkPhone,
  otplessSignIn,
  registerDeviceProfile,
} from '@api/apiRequest'
import { setAccessToken, setRefreshToken } from '@app/appSlice'
import { RootState } from '@app/RootReducer'
import store from '@app/Store'
import { Box, Button, Flex, Icon, Spinner, Stack, Text } from '@chakra-ui/core'
import { useCustomToast } from '@components/customToast'
import NewModal from '@components/NewModal'
import { paths } from '@routers/constants'
import { eventActions, eventConstants } from '@utils/Amplitude'
import isMobile from 'is-mobile'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { isWebViewBuild } from '../../constent'
import loginBG from '../../images/login/bg.png'
import { OTPLESS_SCRIPT, OTPLESS_URL, OTPLESS_WRAPPER } from './constant'

interface OtplessInterface {
  token?: string
  timestamp?: string
  timezone?: string
  mobile?: { name?: string; number?: string }
  waNumber?: string
  waName?: string
}

declare global {
  interface Window {
    otpless: null | ((resp: OtplessInterface) => void)
  }
}

const InjectScript = () => {
  const isSDKPresent = !!document.getElementById(OTPLESS_SCRIPT)
  if (isSDKPresent) return
  const script = document.createElement('script')
  script.id = OTPLESS_SCRIPT
  script.src = OTPLESS_URL
  script.type = 'text/javascript'
  document.body.appendChild(script)
}

const OtplessWrapper = () => {
  const [isOtplessWrapperPresent, setOtplessWrapperPresent] = useState(false)
  const [OtplessInfo, setOtplessInfo] = useState<null | OtplessInterface>(null)

  const { t } = useTranslation()
  const toast = useCustomToast()
  const is_device_profile_registered = useSelector(
    (state: RootState) => state.app.is_device_profile_registered
  )

  const router = useHistory()
  const location = useLocation<{ isSignup: boolean }>()
  const isForceExitRef = useRef<NodeJS.Timeout | null>(null) // will show error & move to signin after 1 mint
  const isDeviceRegisterRef = useRef(is_device_profile_registered)
  isDeviceRegisterRef.current = is_device_profile_registered
  const isRedirectFromSignup = location?.state?.isSignup === true
  const isLocoGGDomain = window.location.origin.search('.loco.gg') >= 0
  const isLinkAccountFlow = location.pathname === paths.whatsappLink

  useEffect(() => {
    // Post receiving info, redirect to login / make api call to backend here
    if (!OtplessInfo) return
    isForceExitRef.current && clearTimeout(isForceExitRef.current)
    isForceExitRef.current = null
    const wa_id_token = OtplessInfo?.token
    if (!wa_id_token) {
      toast({
        position: 'top',
        title: t('error.title'),
        description: t('error.somethingWrong'),
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      router.replace(paths.login)
      return
    }
    const ifAlreadySignedIn = !!store.getState()?.login?.me

    // --- link mobile must have isRedirectFromSignup:true
    if (isLinkAccountFlow && !isRedirectFromSignup) {
      // Link Mobile number on Mobile Browser
      // 1. Redirect from QR, no need to handle the case
      // 2. If opened from mobile itself, Need to trigger api call
      toast({
        position: 'top',
        title: t('login.phoneNumber.success'),
        description: isMobile()
          ? 'You can close this tab and continue on existing one'
          : 'Please continue on browser for more information',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      router.replace(paths.login)
      return
    }

    const timer = setTimeout(async () => {
      let isApiFailed = true
      if (!isDeviceRegisterRef.current) {
        // Sometimes, flow is too fast that we are making sign-in call before device registration.
        // Adding check to register device first always
        await registerDeviceProfile()
      }
      if (isRedirectFromSignup) {
        // Need to make signup request :: Link Mobile Number
        const resp = await otplessLinkPhone(wa_id_token)
        if (resp?.statusCode || resp?.error_code) {
          eventActions.sendAmplitudeData(
            eventConstants.dashboard_link_phone_number,
            {
              link_mode: 'whatsapp',
              phone_number: OtplessInfo?.waNumber,
              response: 'fail',
              failure_msg: resp?.message || 'Login Api Throws Error',
              otp_resend_count: undefined,
              source: 'onboarding',
            }
          )
          // Error
          toast({
            position: 'top',
            title: t('error.somethingWrong'),
            description:
              !resp?.message || resp?.statusCode === 500
                ? 'Please try again after some time'
                : resp.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        } else {
          isApiFailed = false
          eventActions.sendAmplitudeData(
            eventConstants.dashboard_link_phone_number,
            {
              link_mode: 'whatsapp',
              phone_number: OtplessInfo?.waNumber,
              response: 'success',
              failure_msg: undefined,
              otp_resend_count: undefined,
              source: 'onboarding',
            }
          )
        }
      } else if (!ifAlreadySignedIn && !isLinkAccountFlow) {
        // Signin Request
        const resp = await otplessSignIn(wa_id_token)
        if (resp?.access_token && resp?.refresh_token) {
          isApiFailed = false
          eventActions.sendAmplitudeData(
            eventConstants.dashboard_login_whatsapp,
            {
              trigger: 'login_prompt_open',
              response: 'success',
              failure_msg: undefined,
              phone_number: OtplessInfo?.waNumber,
            }
          )
          setRefreshToken(resp.refresh_token)
          setAccessToken(resp.access_token)
        } else if (resp?.statusCode) {
          // Error
          toast({
            position: 'top',
            title: t('error.somethingWrong'),
            description:
              !resp?.message || resp?.statusCode === 500
                ? 'Please try again after some time'
                : resp.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
          eventActions.sendAmplitudeData(
            eventConstants.dashboard_login_whatsapp,
            {
              trigger: 'login_prompt_open',
              response: 'fail',
              failure_msg: resp?.message || 'Login Api Throws Error',
              phone_number: OtplessInfo?.waNumber,
            }
          )
        } else {
          eventActions.sendAmplitudeData(
            eventConstants.dashboard_login_whatsapp,
            {
              trigger: 'login_prompt_open',
              response: 'fail',
              failure_msg: resp?.message || 'Login Api Failed',
              phone_number: OtplessInfo?.waNumber,
            }
          )
          toast({
            position: 'top',
            title: t('error.somethingWrong'),
            description: 'Please try again after some time',
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      }
      isForceExitRef.current && clearTimeout(isForceExitRef.current)
      isForceExitRef.current = null
      if (isApiFailed) {
        router.replace(paths.login)
      } else {
        router.replace(paths.dashboard.default)
      }
    }, 10)

    isForceExitRef.current = setTimeout(() => {
      isForceExitRef.current = null
      if (window.otpless) {
        window.otpless({
          waName: 'Custom-Error',
        })
        eventActions.sendAmplitudeData(
          eventConstants.dashboard_login_whatsapp,
          {
            trigger: 'login_prompt_open',
            response: 'fail',
            failure_msg: 'Failed after 2 mint from loco',
            phone_number: OtplessInfo?.waNumber,
          }
        )
      }
    }, 2000 * 60)

    return () => {
      isForceExitRef.current && clearTimeout(isForceExitRef.current)
      isForceExitRef.current = null
      clearTimeout(timer)
    }
  }, [OtplessInfo, isLinkAccountFlow, isRedirectFromSignup])

  useEffect(() => {
    // Only To change css / position of Otpless modal (Hide / Show)
    const otpless = document.getElementById(OTPLESS_WRAPPER)
    if (!otpless || !isOtplessWrapperPresent || OtplessInfo) return

    const showOtplessModal = () => {
      // Set styles
      otpless.style.setProperty('right', '50%', 'important')
      otpless.style.setProperty('top', '50%', 'important')
      otpless.style.setProperty('opacity', '1', 'important')
      otpless.style.setProperty('z-index', '9999999999', 'important')
      otpless.style.setProperty(
        'transition',
        'all 400ms ease-in-out 0s',
        'important'
      )
      otpless.style.setProperty(
        'transform',
        'translate(50%, -50%)',
        'important'
      )
    }
    showOtplessModal()

    isForceExitRef.current && clearTimeout(isForceExitRef.current)
    isForceExitRef.current = setTimeout(() => {
      isForceExitRef.current = null
      if (window.otpless) {
        window.otpless({
          waName: 'Custom-Error',
        })
        eventActions.sendAmplitudeData(
          eventConstants.dashboard_login_whatsapp,
          {
            trigger: 'login_prompt_open',
            response: 'fail',
            failure_msg: 'Failed after 2 mint from otpless',
            phone_number: undefined,
          }
        )
      }
    }, 2000 * 60)

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: false, subtree: false }

    // Callback function to execute when mutations are observed
    const callback: MutationCallback = (mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes') {
          // Again Adding otpless styles
          showOtplessModal()
        }
      }
    }

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback)

    // Start observing the target node for configured mutations
    observer.observe(otpless, config)

    return () => {
      // Later, you can stop observing
      observer.disconnect()
      isForceExitRef.current && clearTimeout(isForceExitRef.current)
      isForceExitRef.current = null
      // Unset styles
      otpless.style.setProperty('opacity', '0', 'important')
      otpless.style.setProperty('z-index', '-9999999999', 'important')
      otpless.style.setProperty(
        'transition',
        'all 250ms ease-in-out 0s',
        'important'
      )
      otpless.style.setProperty(
        'transform',
        'translate(50%, -100%)',
        'important'
      )
    }
  }, [isOtplessWrapperPresent, OtplessInfo])

  useEffect(() => {
    // To add callback function & initialize otpless script
    setOtplessInfo(null)

    window.otpless = (response) => {
      response = response || {}
      setOtplessInfo(response)
    }
    if (!isLocoGGDomain || isWebViewBuild) return
    // Inject Script
    InjectScript()
    let timerRef: NodeJS.Timeout | null = null

    if (document.getElementById(OTPLESS_WRAPPER)) {
      setOtplessWrapperPresent(true)
    } else {
      timerRef = setInterval(() => {
        // TO check once otpless script load the element
        if (document.getElementById(OTPLESS_WRAPPER)) {
          setOtplessWrapperPresent(true)
          timerRef && clearTimeout(timerRef)
          timerRef = null
        }
      }, 250)
    }
    return () => {
      timerRef && clearTimeout(timerRef)
      timerRef = null
      window.otpless = null
    }
  }, [isLocoGGDomain])

  return isWebViewBuild ? null : (
    <Flex
      direction="column"
      alignItems="center"
      w="full"
      height="full"
      bgImage={`radial-gradient(circle at 0 0, rgba(5, 5, 6, 0.5), rgba(5, 5, 6, 0.5), #050506), url(${loginBG})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      bg="#2c18a6"
      justifyContent={['start', 'center']}
      color={'white'}
      position={'relative'}
    >
      <Box
        top={['12px', '12px']}
        left={['12px', '12px']}
        w={'full'}
        p={'8px'}
        zIndex={1402}
        position={'absolute'}
      >
        <Icon
          name="arrow-back"
          color="white"
          cursor="pointer"
          size={'5'}
          onClick={() => {
            router.goBack()
          }}
        />
      </Box>
      {OtplessInfo ? (
        <Box
          position={'absolute'}
          top="50%"
          left="50%"
          transform={'translate(-50%,-50%)'}
        >
          <Spinner h={'40px'} w={'40px'} />
        </Box>
      ) : null}
      <NewModal
        isOpen={!OtplessInfo}
        onClose={() => {
          setOtplessInfo(null)
          router.replace(paths.login)
        }}
        modalSize="md"
        isCentered={true}
        modalOverlayStyle={{
          opacity: 0.5,
        }}
        modalContentStyle={{
          bg: 'brand.primary-light-black-v4',
          visibility: isLocoGGDomain ? 'hidden' : 'visible',
        }}
        modalHeaderStyle={{
          px: 0,
          pb: 0,
        }}
        modalBodyStyle={{
          p: 4,
        }}
        modalBodyComponent={
          <>
            {!isLocoGGDomain ? (
              <Stack spacing={4} align={'center'} w="full">
                <Icon
                  name="warning"
                  width="60px"
                  height="50px"
                  color="#ffe351"
                />
                <Text fontWeight="bold" fontSize={['16px', '18px']}>
                  {t('error.somethingWrong')}
                </Text>
                <Box fontSize={['16px', '18px']} textAlign="center">
                  <Box opacity={0.6}>
                    <Text>
                      {t('login.loginText') + ' ' + t('login.streamingText')}
                    </Text>
                    <Text>
                      <a href="https://dashboard.loco.gg/">
                        https://dashboard.loco.gg/
                      </a>
                    </Text>
                  </Box>
                </Box>
                <Button
                  width="150px"
                  height="44px"
                  fontSize={['12px', '14px']}
                  borderRadius="8px"
                  box-shadow="0 2px 9px 0 rgba(81, 31, 255, 0.08)"
                  variantColor="progress"
                  onClick={() => {
                    router.replace(paths.login)
                  }}
                >
                  Okay
                </Button>
              </Stack>
            ) : null}
          </>
        }
      />
    </Flex>
  )
}

export default OtplessWrapper
