import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/core'
import { CategoryPicker } from '@components/CategoryPicker/CategoryPicker'
import CountrySelector from '@components/CountrySelector'
import { LanguagePicker } from '@components/LanguagePicker/LanguagePicker'
import { TagsPicker } from '@components/TagsPicker/TagsPicker'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/RootReducer'
import {
  fetchCountries,
  fetchGames,
  // fetchGames,
  fetchLanguages,
  fetchSpecificGames,
  fetchSpecificTags,
  fetchTags,
  // fetchTags,
} from '../../modules/Login/loginSlice'
import CropComponent from '../CropComponent'
import CustomCalendar from '../datepicker/CustomCalendar'
import { Input } from '../input/Input'
// import { Upload } from '../upload/Upload';
import Selector from '../selector'
import TooltipModal from '../tooltipModal'
import { UploadClipComponent } from '../UploadClipComponent'
import { UploadComponent } from '../UploadComponent'
import FormAgreement from './FormAgreement'
import FormRadio from './FormRadio'
import Textarea from './FormTextarea'

const FormInput = (props: any) => {
  const dispatch = useDispatch()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    name,
    label,
    placeholder,
    type,
    inputType,
    wordCount,
    wordCountLimit,
    isWebViewBuild,
    errors,
    matches,
    touched,
    values,
    handleBlur,
    handleChange,
    renderComponent,
    errorComponent,
    extraProps,
    tooltipLabel,
    formWidth,
    videoInfo,
    isUploadClips,
    tagCountLimit,
    tagCount,
    required,
  } = props
  const wordLimit = wordCountLimit || 140
  const tagLimit = tagCountLimit || 5

  const {
    games,
    gamesLoading,
    tags,
    tagsLoading,
    // tagsNext,
    me,
    countries,
    languages,
    specificGames,
    specificTags,
  } = useSelector((state: RootState) => state.login)
  const { t } = useTranslation()
  useEffect(() => {
    if (inputType === 'gamesPicker') {
      if (games.length > 0) return
      if (me?.user_uid) {
        dispatch(fetchGames())
      }
    }
    if (inputType === 'categoryPicker') {
      if (specificGames.length > 0) return
      if (me?.user_uid) dispatch(fetchSpecificGames())
    }
    if (inputType === 'countryPicker') {
      if (countries.length > 0) return
      dispatch(fetchCountries())
    }

    if (inputType === 'tagsPicker') {
      if (tags.length > 0) return
      dispatch(fetchTags())
    }
    if (inputType === 'newtagsPicker') {
      if (specificTags.length > 0) return
      dispatch(fetchSpecificTags())
    }
    if (inputType === 'languagePicker' || inputType === 'pillLanguagePicker') {
      if (languages.length > 0) return
      dispatch(fetchLanguages())
    }
  }, [me?.user_uid])

  let InputComponent
  switch (inputType) {
    case 'date':
      InputComponent = (
        <CustomCalendar
          name={name}
          id={name}
          title={extraProps.title}
          placeholder={placeholder}
          value={values[name]}
          onChange={props.onChange}
          calendarProps={{ ...extraProps }}
        />
      )
      break
    case 'textarea':
      InputComponent = (
        <Textarea
          name={name}
          id={name}
          placeholder={placeholder}
          fontSize={'sm'}
          color="white"
          fontWeight={['bold', 'normal']}
          bg={[
            extraProps?.isReadOnly ? 'transparent' : 'brand.primary-text-field',
            'brand.primary-text-field',
          ]}
          value={values[name]}
          onBlur={handleBlur}
          onChange={handleChange}
          maxLength={wordCountLimit}
          style={{
            paddingInlineStart: '1rem',
          }}
          {...extraProps}
        />
      )
      break
    case 'radio':
      InputComponent = (
        <FormRadio
          {...props}
          value={values[name]}
          id={name}
          onBlur={handleBlur}
          onChange={handleChange}
          {...extraProps}
        />
      )
      break
    case 'gamesPicker':
      InputComponent = (
        <Selector
          isMulti={false}
          id={name}
          isLoading={gamesLoading}
          value={values[name]}
          onBlur={handleBlur}
          {...props}
          {...extraProps}
          classNamePrefix={extraProps?.isReadOnly ? 'react-select' : null}
          options={
            games?.length > 0
              ? games.map((game) => ({ value: game.uid, label: game.name }))
              : null
          }
        />
      )
      break
    case 'picker':
      InputComponent = (
        <Selector
          isMulti={false}
          id={name}
          isLoading={extraProps?.loading}
          value={values[name]}
          onBlur={handleBlur}
          {...props}
          {...extraProps}
          classNamePrefix={extraProps?.isReadOnly ? 'react-select' : null}
          options={extraProps?.options?.length > 0 ? extraProps?.options : null}
        />
      )
      break
    case 'tagsPicker':
      InputComponent = (
        <Selector
          isMulti={true}
          id={name}
          isLoading={tagsLoading}
          value={values[name]}
          onBlur={handleBlur}
          classNamePrefix={extraProps?.isReadOnly ? 'react-select' : null}
          {...props}
          {...extraProps}
          options={tags}
          isUploadClips={isUploadClips}
        />
      )
      break
    case 'uploadThumbnail':
      InputComponent = (
        <CropComponent
          {...props}
          id={name}
          defaultStreamDetails={extraProps?.defaultStreamDetails}
          accept=".png,.jpg,.jpeg"
          {...extraProps}
        />
      )
      break
    case 'agreement':
      InputComponent = <FormAgreement {...props} {...extraProps} />
      break
    case 'uploadVideo':
      InputComponent = (
        <UploadComponent
          {...props}
          id={name}
          defaultStreamDetails={extraProps?.defaultStreamDetails}
          accept=".mp4,.avi"
          {...extraProps}
        />
      )
      break
    case 'countryPicker':
      InputComponent = (
        <CountrySelector
          isMulti={false}
          id={name}
          // isLoading={gamesLoading}
          value={values[name]}
          onBlur={handleBlur}
          {...props}
          {...extraProps}
          classNamePrefix={extraProps?.isReadOnly ? 'react-select' : null}
          options={countries}
        />
      )
      break
    case 'languagePicker':
      InputComponent = (
        <Selector
          isMulti={false}
          id={name}
          isLoading={gamesLoading}
          value={values[name]}
          onBlur={handleBlur}
          {...props}
          {...extraProps}
          classNamePrefix={extraProps?.isReadOnly ? 'react-select' : null}
          options={languages}
        />
      )
      break
    case 'pillLanguagePicker':
      InputComponent = (
        <LanguagePicker
          id={name}
          value={values['language']}
          {...props}
          {...extraProps}
        />
      )
      break
    case 'uploadClip':
      InputComponent = (
        <UploadClipComponent
          {...props}
          id={name}
          defaultStreamDetails={extraProps?.defaultStreamDetails}
          accept=".mp4,"
          {...extraProps}
        />
      )
      break

    case 'categoryPicker':
      InputComponent = (
        <CategoryPicker
          {...props}
          id={name}
          value={values['game']}
          {...extraProps}
        />
      )
      break

    case 'newtagsPicker':
      InputComponent = (
        <TagsPicker
          id={name}
          values={values}
          onBlur={handleBlur}
          tagLimit={tagCountLimit}
          {...props}
          {...extraProps}
        />
      )
      break

    default:
      InputComponent = (
        <Input
          id={name}
          name={name}
          placeholder={placeholder}
          fontSize={'sm'}
          fontWeight={['bold', 'normal']}
          bg={[
            extraProps?.isReadOnly ? 'transparent' : 'brand.primary-text-field',
            'brand.primary-text-field',
          ]}
          pl={[extraProps?.isReadOnly ? 0 : 4, 4]}
          value={values[name]}
          onBlur={handleBlur}
          onChange={handleChange}
          type={type}
          error={touched[name] && errors[name] && !extraProps?.isReadOnly}
          matches={matches}
          maxLength={wordCountLimit}
          {...props}
          {...extraProps}
        />
      )
  }
  if (!isWebViewBuild) {
    return (
      <>
        <FormControl
          isInvalid={
            errors[name] && touched[name] && !extraProps?.isReadOnly
              ? true
              : false
          }
          w={formWidth || ['100%', '80%']}
        >
          {label && (
            <FormLabel htmlFor={name} pb={2} pr={0} fontWeight={600} w="full">
              <Flex justify="space-between" w="full" align="center">
                <Flex
                  justify={'flex-start'}
                  align="center"
                  fontSize={[extraProps?.isReadOnly ? 'xs' : 'sm', 'sm']}
                  fontWeight={[extraProps?.isReadOnly ? 'light' : 600, 600]}
                  w="full"
                  opacity={extraProps?.isReadOnly ? [0.8, 1] : 1}
                  color="white"
                >
                  {label}
                  <Text as="span">{required && '*'}</Text>
                  {wordCount && !extraProps?.isReadOnly ? (
                    <Text
                      color={
                        values[name].length
                          ? (errors[name] && touched[name]) ||
                            values[name].length > wordLimit
                            ? 'brand.primary-red'
                            : 'brand.primary-white-v2'
                          : 'brand.primary-white-v2'
                      }
                      letterSpacing={1}
                      fontSize={'xs'}
                      fontWeight="lighter"
                      // ml={4}
                      style={{
                        marginInlineStart: '1rem',
                      }}
                    >
                      ({values[name].length}/{wordLimit})
                    </Text>
                  ) : null}

                  {tagCount && !extraProps?.isReadOnly ? (
                    <Text
                      color={
                        values[name].length
                          ? (errors[name] && touched[name]) ||
                            values[name].length > wordLimit
                            ? 'brand.primary-red'
                            : 'brand.primary-white-v2'
                          : 'brand.primary-white-v2'
                      }
                      letterSpacing={1}
                      fontSize={'xs'}
                      fontWeight="lighter"
                      // ml={4}
                      style={{
                        marginInlineStart: '1rem',
                      }}
                    >
                      ({values[name].length}/{tagLimit} tags selected)
                    </Text>
                  ) : null}
                  {videoInfo ? (
                    <Text
                      color={'brand.primary-white-v2'}
                      letterSpacing={1}
                      fontSize={'10px'}
                      fontWeight="lighter"
                      ml={4}
                    >
                      ( {t('clips.uploadForm.formatLabel')} {':'}{' '}
                      <Text as={'span'} color={'#fff'}>
                        {' '}
                        {t('clips.uploadForm.formatValue')}
                      </Text>{' '}
                      | {t('clips.uploadForm.limitLabel')} {':'}{' '}
                      <Text as={'span'} color={'#fff'}>
                        {' '}
                        {t('clips.uploadForm.limitValue')}
                      </Text>{' '}
                      )
                    </Text>
                  ) : null}
                </Flex>
                {tooltipLabel && !extraProps?.isReadOnly ? (
                  <>
                    <Tooltip
                      label={tooltipLabel}
                      display={['none', 'flex', 'flex']}
                      hasArrow
                      bg="white"
                      color="black"
                      w="150px"
                      fontSize="xs"
                      placement="right"
                      zIndex={10000}
                      aria-label={tooltipLabel}
                    >
                      <Icon
                        //@ts-ignore
                        name="info"
                        size={'15px'}
                        display={['none', 'flex', 'flex']}
                      />
                    </Tooltip>
                    <Icon
                      //@ts-ignore
                      name="info"
                      size={'15px'}
                      display={['flex', 'none', 'none']}
                      onClick={onOpen}
                    />
                  </>
                ) : null}
              </Flex>
            </FormLabel>
          )}
          {renderComponent ? renderComponent : InputComponent}
          {errorComponent ? (
            errorComponent
          ) : touched[name] && errors[name] ? (
            <FormErrorMessage
              fontSize="xs"
              fontWeight="lighter"
              color="brand.primary-red"
            >
              <Box
                style={{
                  paddingInlineStart: '0.25rem',
                }}
              >
                {t(errors[name]) || errors[name]}
              </Box>
            </FormErrorMessage>
          ) : null}
        </FormControl>
        {isOpen && (
          <TooltipModal
            isOpen={isOpen}
            onClose={onClose}
            title={label}
            tooltipLabel={tooltipLabel}
          />
        )}
      </>
    )
  }
  return null
}

export default FormInput
