import { RootState } from '@app/RootReducer'
import { Flex } from '@chakra-ui/core'
import { paths } from '@routers/constants'
import { eventActions, eventConstants } from '@utils/Amplitude'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import withToasts from '../../hoc/WithToasts'
import loginBG from '../../images/login/bg.png'
import SignUpOptions from './components/SignupOptions'
import { SignUpOtpScreen } from './components/SignupOtpScreen'
import { SignUpPhoneScreen } from './components/SignupPhoneNumber'
import { SignupScreenENUM } from './SignupSlice'

const SignUpComponent = (): JSX.Element => {
  const history = useHistory()

  const { signUpScreen } = useSelector((state: RootState) => state.signup)
  const { linkedGmail, linkedPhoneNumber } = useSelector(
    (state: RootState) => state.app
  )

  useEffect(() => {
    if (!linkedGmail && !linkedPhoneNumber) {
      const tnc = localStorage.getItem('terms_condition_modal_2023')
      localStorage.clear()
      tnc && localStorage.setItem('terms_condition_modal_2023', tnc)
      history.replace(paths.login)
    }
  }, [])

  const isEnterNumberScreen =
    signUpScreen === SignupScreenENUM.PhoneNumberScreen
  useEffect(() => {
    if (isEnterNumberScreen) {
      eventActions.sendAmplitudeData(
        eventConstants.dashboard_login_enter_phone,
        { trigger: 'onboarding' }
      )
    }
  }, [isEnterNumberScreen])

  return (
    <Flex
      direction="column"
      alignItems="center"
      w="full"
      height="full"
      bgImage={`radial-gradient(circle at 0 0, rgba(5, 5, 6, 0.7), rgba(5, 5, 6, 0.9), #050506), url(${loginBG})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      bg="#2c18a6"
      justifyContent={['start', 'center']}
    >
      {signUpScreen === SignupScreenENUM.SignupOptionsScreen ? (
        <SignUpOptions />
      ) : signUpScreen === SignupScreenENUM.PhoneNumberScreen ? (
        <SignUpPhoneScreen />
      ) : signUpScreen === SignupScreenENUM.OTPNumberScreen ? (
        <SignUpOtpScreen />
      ) : (
        false
      )}
    </Flex>
  )
}
const Signup = withToasts(SignUpComponent)
export { Signup }
