import queryString from 'query-string'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { setUserSettingsParams } from '../../api/apiRequest'
import Container from '../../components/NewContainer'
import { ApiContext } from '../../context/ApiConnector/types'
import {
  handleSetUserSettings,
  handleUpdateUserSettings,
} from '../DefaultStream/streamDetailsSlice'
import Main from './Main'
import SetupStreamForm from './SetupStreamForm'

const SetupStream = ({
  defaultStreamDetails,
}: {
  defaultStreamDetails: ApiContext['defaultStreamDetails']
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { to } = queryString.parse(location.search)

  const onSubmit = (params: setUserSettingsParams) => {
    if (defaultStreamDetails?.title) {
      dispatch(handleUpdateUserSettings(params))
    } else {
      dispatch(handleSetUserSettings(params))
    }
  }
  const { t } = useTranslation()

  const setupStreamTextTitle = t('home.streamSetup.title')
  const setupStreamTextSubtitle = t('home.streamSetup.subtitle')

  return (
    <Container
      title={to && setupStreamTextTitle}
      titleTextAlign={to ? 'left' : 'center'}
      subtitle={to && setupStreamTextSubtitle}
      outerContainerStyle={{
        minH: '100%',
        height: 'fit-content',
      }}
      innerContainerStyle={{
        bg: 'brand.primary-light-black-v2',
        rounded: 'lg',
        p: [2, 0],
      }}
    >
      {to && to === 'form' ? (
        <SetupStreamForm
          defaultStreamDetails={defaultStreamDetails}
          onSubmit={onSubmit}
        />
      ) : (
        <Main />
      )}
    </Container>
  )
}

export default SetupStream
