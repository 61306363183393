import { Box, Flex, Icon, Image } from '@chakra-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { isWebViewBuild } from '../constent'
import locowithicon from '../images/locowithicon.png'
import { paths } from '../routers/constants'

interface WebViewHeaderProps {
  children: React.ReactNode
}
export const WebViewHeader = ({ children }: WebViewHeaderProps) => {
  const history = useHistory()

  return (
    <Flex
      display={['flex', 'flex', 'flex']}
      w="full"
      bg={'brand.primary-dark-black-v2'}
      justifyContent="center"
      alignContent="center"
      position="relative"
      border={'pink.500'}
      py={4}
      px={2}
    >
      <Flex
        position="absolute"
        left={0}
        top={0}
        px={2}
        py={4}
        alignContent="center"
      >
        <Icon
          name="arrow-back"
          size="8"
          color="white"
          alignContent="center"
          onClick={() => {
            if (isWebViewBuild) {
              history.push(paths.dashboard.menu)
            } else {
              history.push(paths.dashboard.home)
            }
          }}
        />
      </Flex>
      <Box>{children}</Box>
    </Flex>
  )
}

export const PrivacyPolicy = () => {
  return (
    <>
      <WebViewHeader>
        <Image src={locowithicon} alt="loco icon" height={5} mt={2} />
      </WebViewHeader>
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<iframe src="https://loco.gg/legal/privacy-policy" style="overflow:hidden;height:100vh;width:100%" height="100vh" width="100%" ></iframe>',
        }}
      />
    </>
  )
}

export const TermsConditions = () => {
  return (
    <>
      <WebViewHeader>
        <Image src={locowithicon} alt="loco icon" height={5} mt={2} />
      </WebViewHeader>
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<iframe src="https://loco.gg/legal/terms-of-use" style="overflow:hidden;height:100vh;width:100%" height="100vh" width="100%" ></iframe>',
        }}
      />
    </>
  )
}

export const StreamerAgreement = () => {
  return (
    <>
      <WebViewHeader>
        <Image src={locowithicon} alt="loco icon" height={5} mt={2} />
      </WebViewHeader>
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<iframe src="https://loco.gg/legal/agreement" style="overflow:hidden;height:100vh;width:100%" height="100vh" width="100%" ></iframe>',
        }}
      />
    </>
  )
}
