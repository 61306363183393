import React from 'react'

export const howToStreamData = {
  title: 'How to stream',
  text:
    'Are you ready to start your streaming journey with us ? Let’s start with understanding  the basics of streaming, and how you can showcase your gameplay and skills to the world - LIVE on Loco! Here is everything you need to know about. ',
  data: [
    {
      option: 1,
      id: 'locoBasics',
      academyHeadline: 'Streaming basics',
      containsDropDown: false,
      containsTable: false,
      points: [
        {
          video: {
            url:
              'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Videos/Loco+Basics-+Alpha+clasher_revised.mp4',
            poster:
              'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Images/Loco+basics.jpg',
          },
        },
        {
          text:
            'There are plenty of reasons as to why you should be streaming on LOCO - India’s leading game streaming platform! Interestingly, getting your channel monetized isn’t a challenging task on LOCO because, as a streamer, you can start earning from the first month itself.',
        },
        {
          text:
            'To begin with, you don’t need to have a certain number of followers or streaming hours on the platform to get your channel monetized. All you need to do is stream with dedication and consistency',
        },
      ],
    },
    {
      option: 2,
      id: 'setup',
      academyHeadline: 'Setup',
      containsDropDown: true,
      containsTable: false,
      points: [
        {
          heading: 'How do I start Streaming?',
          video: {
            url:
              'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Videos/How+do+i+start+streamimg_Ankkita_revised.mp4',
            poster:
              'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Images/How+do+I+start+streaming.jpg',
          },
        },
        {
          heading: 'Streamlabs/OBS/Loco studio etc  set ups',
        },
        {
          heading: 'OBS:',
          text: (
            <span>
              1. Downloads OBS Studio from{' '}
              <a
                style={{
                  color: '#FF5155',
                }}
                href="https://obsproject.com/download"
                target="_blank"
                rel="noreferrer"
              >
                https://obsproject.com/download
              </a>{' '}
              and after installation, look for the ‘Settings’ option at the
              bottom.
              <br />
              2. In Settings, go to ‘Stream’ and fill in your ‘Streaming Link’
              and ‘Key.’ Then, click on ‘OK’
              <br />
              3. You can always set up your overlays, background, audio
              input/output, scenes, etc. on OBS Studio before you start
              streaming <br />
              4. Once you have finalized everything, click on ‘Start Streaming’{' '}
              <br />
            </span>
          ),
        },
        {
          heading: <span>Connect to loco- streamer profile</span>,
          text:
            'You can always set up your stream profile by tapping on the ‘My Stream Profile’ tab in ‘Streaming Center.’ Here, you can change your profile picture, name, username, channel bio, DOB, gender, and primary game. ',
        },
        {
          heading: 'Generate and use stream key & URL',
          text:
            'To generate your stream key & URL, go to ‘Streaming Center’ and tap on ‘View’ that’s on the right of ‘Stream Key & URL.’ You can then copy & paste the Host URL and Stream Key from there.',
        },
      ],
    },
    {
      option: 3,
      id: 'technical',
      academyHeadline: 'Lets get Technical',
      containsDropDown: true,
      containsTable: true,
      points: [
        {
          heading: 'Thumbnail, stream title, and description ',
          text:
            'It’s a good practice to update your stream thumbnail, title, and description to let your audience know what kind of content you will be streaming today. Moreover, updating these three things sends a note to your community that you’re willing to experiment with your content by trying out new things!',
        },
      ],
    },
    {
      option: 4,
      id: 'streamerDashboard',
      academyHeadline: 'Streamer Dashboard',
      containsDropDown: false,
      containsTable: false,
      points: [
        {
          video: {
            url:
              'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Videos/Streamer+Dashboard_Basic+features+by+Xyaa.mp4',
            poster:
              'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Images/Streamer+Dashboard.jpg',
          },
        },
      ],
    },
    {
      option: 5,
      id: 'communityGuidelines',
      academyHeadline: 'Community Guidelines',
      containsDropDown: false,
      containsTable: false,
      points: [
        {
          text:
            'We at LOCO encourage all streamers to abide by the streamer guidelines to avoid any platform strikes or deduction of beans received. Click on the given link to learn more about our streamer guidelines.',
        },
        {
          text: (
            <span>
              <a
                style={{
                  color: '#FF5155',
                }}
                href="/dashboard/guidelines"
                target="_blank"
                rel="noreferrer"
              >
                Streamer Guidelines Link
              </a>
            </span>
          ),
        },
      ],
    },
  ],
}

export const setupStreamingDeviceData = [
  {
    id: 'mobile',
    points: [
      {
        video: {
          url:
            'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Videos/Setup_Amol.mp4',
          poster:
            'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Images/Setup.jpg',
        },
      },

      {
        heading: 'Mobile',
        text: `Thanks to the LOCO Studio app, streaming has been made easier. All it takes is a few taps! Select the game you want to stream and fill in the stream details such as Stream Title, Stream Description, Primary Game, Thumbnail, and Tags, and you’re good to go! `,
      },
      {
        text: (
          <span>
            <a
              style={{
                color: '#FF5155',
              }}
              href="https://play.google.com/store/apps/details?id=com.pocketaces.locostudio&shortlink=StreamerAcademy&pid=Streamer%20Academy&c=Streamer%20Academy"
              target="_blank"
              rel="noreferrer"
            >
              Link to download Loco studio
            </a>
          </span>
        ),
      },
    ],
  },
  {
    id: 'pc',
    points: [
      {
        video: {
          url:
            'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Videos/Setup_Amol.mp4',
          poster:
            'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Images/Setup.jpg',
        },
      },

      {
        heading: 'PC',
        text: `To stream from your PC, you can follow the instructions mentioned below:`,
      },
      {
        text: (
          <span>
            1. Downloads OBS Studio from
            <a
              style={{
                color: '#FF5155',
              }}
              href="https://obsproject.com/download"
              target="_blank"
              rel="noreferrer"
            >
              https://obsproject.com/download
            </a>{' '}
            and after installation, look for the ‘Settings’ option at the bottom
            image
          </span>
        ),
      },
      {
        image: require('../assets/img3-1@3x.png'),
        text: `2. In Settings, go to ‘Stream’ and fill in your ‘Streaming Link’ and ‘Key.’ Then, click on ‘OK’ `,
      },
      {
        image: require('../assets/img3-2@3x.png'),
        text: `3. You can always set up your overlays, background, audio input/output, scenes, etc. on OBS Studio before you start streaming`,
      },
      {
        image: require('../assets/img3-3@3x.png'),
        text: `4. Once you have finalized everything, click on ‘Start Streaming’`,
      },
    ],
  },
  {
    id: 'androidToPC',
    points: [
      {
        video: {
          url:
            'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Videos/Setup_Amol.mp4',
          poster:
            'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Images/Setup.jpg',
        },
      },

      {
        heading: 'Screen Mirroring Android to PC',
        text: (
          <span>
            1. Download and Install Apowermirror to your windows laptop/pc. You
            can download Apowermirror from{' '}
            <a
              style={{
                color: '#FF5155',
              }}
              href="https://www.apowersoft.com/phone-mirror"
            >
              https://www.apowersoft.com/phone-mirror
            </a>
            . <br />
            2. Download and Install Apowermirror to your Android device from
            Google Play Store. Turn on USB Debugging on your Android device.
            (Settings {'>'} Developer options) <br />
            3. Launch Apowermirror on your Windows. Create a profile and Select
            USB connection on Top of the Window. <br />
          </span>
        ),
      },
      {
        image: require('../assets/img4-3@3x.png'),
        text: (
          <span>
            4. Open the Apowermirror app on your Android Device And Connect the
            phone to windows laptop/pc using a compatible wire.
            <br />
            5. Allow USB Debugging prompt on your Android device and check
            ‘Always Allow’ from this computer and click on Enable. Continue on
            your Apowermirror window on laptop/pc
          </span>
        ),
      },
      {
        image: require('../assets/img4-5@3x.png'),
        text: `6. Now, Apowermirror will connect the windows to the android device. Before the mirroring starts    
        you will get a notification on your Android device asking for the permission to start Screen      
        Mirroring > press start now.`,
      },
      {
        image: require('../assets/img4-6@3x.png'),
        text: `Voila! You are all Set for Screen Mirroring.`,
      },
    ],
  },
  {
    id: 'androidToMAC',
    points: [
      {
        video: {
          url:
            'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Videos/Setup_Amol.mp4',
          poster:
            'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Images/Setup.jpg',
        },
      },

      {
        heading: 'Screen Mirroring Android to MAC',
        text: (
          <span>
            1. Download and Install Apowermirror to your windows laptop/pc. You
            can download Apowermirror from{' '}
            <a
              style={{
                color: '#FF5155',
              }}
              href="https://www.apowersoft.com/phone-mirror"
            >
              https://www.apowersoft.com/phone-mirror
            </a>
            . <br />
            2. Download and Install Apowermirror to your Android device from
            Google Play Store. Turn on USB Debugging on your Android device.
            (Settings {'> '}
            Developer options) <br />
            3. Launch Apowermirror on your Windows. Create a profile and Select
            USB connection on Top of the Window. <br />
          </span>
        ),
      },
      {
        image: require('../assets/img6-3@3x.png'),
        text: (
          <span>
            4. Open the Apowermirror app on your Android Device And Connect the
            phone to windows laptop/pc using a compatible wire.
            <br />
            5. Allow USB Debugging prompt on your Android device and check
            ‘Always Allow’ from this computer and click on Enable. Continue on
            your Apowermirror window on laptop/pc
          </span>
        ),
      },
      {
        image: require('../assets/img6-5@3x.png'),
        text: `6. Select your Device from the application window on your Mac.`,
      },
      {
        image: require('../assets/img6-6@3x.png'),
        text: `7. Apowermirror will connect the Mac to android device and before the mirroring starts you will get a notification on your Android Device asking for the permission to start Screen Mirroring > press start now. `,
      },
      {
        image: require('../assets/img6-7@3x.png'),
        text: `Voila! You are all Set for Screen Mirroring.`,
      },
    ],
  },
  {
    id: 'iosToPC',
    points: [
      {
        video: {
          url:
            'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Videos/Setup_Amol.mp4',
          poster:
            'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Images/Setup.jpg',
        },
      },

      {
        heading: 'Screen Mirroring iOS to PC',
        text: (
          <span>
            1. Download and Install Apowermirror to your windows laptop/pc. You
            can download Apowermirror from{' '}
            <a
              style={{
                color: '#FF5155',
              }}
              href="https://www.apowersoft.com/phone-mirror"
            >
              https://www.apowersoft.com/phone-mirror
            </a>
            . <br />
            2. Launch Apowermirror on your computer. Create your account and
            under the Wifi Connection tab , click on iOS. (make sure iPhone and
            laptop/pc are on same wifi)
          </span>
        ),
      },
      {
        image: require('../assets/img5-2@3x.png'),
        text: `3. On your iOS device Open Control Centre and Select Screen Mirroring`,
      },
      {
        image: require('../assets/img5-3@3x.png'),
        text: `4. In the Screen Mirroring window on your iOS device, you will be able to see the Apowersoft screen 
        mirror, select it on your iOS device.`,
      },
      {
        text: `Yay! All set for screen mirroring from your iOS device to Windows laptop/pc.`,
      },
    ],
  },
  {
    id: 'iosToMAC',
    points: [
      {
        video: {
          url:
            'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Videos/Setup_Amol.mp4',
          poster:
            'https://static.getloconow.com/streamer-dashboard-web-prod/assets/Images/Setup.jpg',
        },
      },
      {
        heading: 'Screen Mirroring iOS to MAC',
        text: (
          <span>
            1. Download And Install QuickTime Player You can download QuickTime
            Player from{' '}
            <a
              style={{
                color: '#FF5155',
              }}
              href="https://support.apple.com/downloads/quicktime"
            >
              https://support.apple.com/downloads/quicktime
            </a>
            . <br />
            2. Connect your iOS device to Mac using a compatible wire. <br />
            3. Launch QuickTime Player on your Mac {'>'} Go To File {'>'} New
            Movie Recording. <br />
          </span>
        ),
      },
      {
        image: require('../assets/img7-3.png'),
        text: `4. Inside the New Movie Recording window, click on the dropdown menu next to Record button > 
        select your iOS device. (Ex: I have selected my iPad in the picture)`,
      },
      {
        image: require('../assets/img7-4@3x.png'),
        text: `Cheers!! We have successfully started screen mirroring from iOS to Mac.`,
      },
    ],
  },
]

export const technicalStreamingDeviceData = [
  {
    id: 'mobile',
    points: [
      {
        heading: 'Mobile:',
        text: `Overlays are pre-set in Loco studio you don’t have to worry about them, soon we'll be adding more overlays for you to select from.`,
      },
      {
        heading: 'Tools:',
        text: (
          <span>
            <span style={{ color: 'white', fontWeight: 'bold' }}>Mute:</span>{' '}
            You can turn your primary microphone on and off during your stream
            to carry out personal communications and to reduce background noise
            while streaming
          </span>
        ),
      },
      {
        text: (
          <span>
            <span style={{ color: 'white', fontWeight: 'bold' }}>Camera:</span>{' '}
            You can use your front camera to act as a facecam to improve your
            stream hygiene and to express your emotions vividly
          </span>
        ),
      },
      {
        text: (
          <span>
            <span style={{ color: 'white', fontWeight: 'bold' }}>
              Start/pause:
            </span>{' '}
            You can start and pause your stream while taking a break
          </span>
        ),
      },
      {
        text: (
          <span>
            <span style={{ color: 'white', fontWeight: 'bold' }}>Quit:</span> To
            stop your stream
          </span>
        ),
      },
    ],
  },
  {
    id: 'pc',
    points: [
      {
        heading: 'PC',
        text: `Stream overlays are what streamers use to portray a specific style or theme across their channel and make an eye-catching and more interactive stream experience. Depending on the game you’re streaming or the type of content you generally stream, you can set your overlays accordingly.`,
      },
      {
        heading: 'Tools:',
        text: `To make your stream stand out from other streamers, make sure you keep these things in mind while streaming with OBS:`,
      },
      {
        text: (
          <span>
            <ul style={{ marginLeft: '30px' }}>
              <li>Use customized Overlays</li>
              <li>Keep an eye on the “Stat” box </li>
              <li>Customize your streaming resolution</li>
              <li>Manage scenes like a pro with “Scene Projection”</li>
              <li>Enable source alignment snapping</li>
              <li>Nest your scenes for easy manageability</li>
              <li>Using custom facecam overlay/shapes</li>
              <li>Using custom stinger transitions </li>
            </ul>
          </span>
        ),
      },
    ],
  },
]

export const setupStreamingDeviceOptions = [
  {
    key: 'mobile',
    value: 'mobile',
    display: 'Mobile',
  },
  {
    key: 'pc',
    value: 'pc',
    display: 'PC',
  },
  {
    key: 'androidToPC',
    value: 'androidToPC',
    display: 'Screen Mirroring Android to PC',
  },
  {
    key: 'androidToMAC',
    value: 'androidToMAC',
    display: 'Screen Mirroring Android to MAC',
  },
  {
    key: 'iosToPC',
    value: 'iosToPC',
    display: 'Screen Mirroring iOS to PC',
  },
  {
    key: 'iosToMAC',
    value: 'iosToMAC',
    display: 'Screen Mirroring iOS to MAC',
  },
]

export const technicalStreamingDeviceOptions = [
  {
    key: 'mobile',
    value: 'mobile',
    display: 'Mobile',
  },
  {
    key: 'pc',
    value: 'pc',
    display: 'PC',
  },
]
