import { Flex } from '@chakra-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { RootState } from '../../../../app/RootReducer'
import { extractPathFromWebviewUrl, paths } from '../../../../routers/constants'
import exitWebView from '../../../../utils/exitWebview'
import HeaderMobileLeft from './HeaderMobileLeft'
import HeaderMobileRight from './HeaderMobileRight'

declare global {
  interface Window {
    DashboardInterface: any
  }
}

const HeaderWebview = () => {
  const { pathname, search } = useLocation()
  const { isLive } = useSelector((state: RootState) => state.liveStreamManager)
  let label = ''
  switch (pathname) {
    case paths.dashboard.home:
      label = "Today's Stream Details"
      break
    case paths.dashboard.analytics:
      label = 'Streamer Analytics'
      break
    case paths.dashboard.streamerLeaderboard:
      label = 'Streamer Leaderboard'
      break
    case paths.dashboard.videos:
      label = 'Videos'
      break
    case paths.dashboard.clips:
      label = 'Clips'
      break
    case paths.dashboard.profile:
      label = 'Profile'
      break
    case paths.dashboard.wallet:
      label = 'Streamer Wallet'
      break
    case paths.dashboard.streamerAcademy:
      label = 'Streamer Academy'
      break
    case paths.dashboard.stream:
      label = 'Live Stream Manager'
      break
    case paths.dashboard.streamerMela:
      label = 'Loco Gamer Mela'
      break
    case paths.dashboard.streamerMelaStats:
      label = 'Gamer Mela Stats'
      break
    case paths.dashboard.streamerMelaPrize:
      label = 'Gamer Mela Prizes'
      break
    // case paths.dashboard.incentives:
    //   label = 'Streamer Incentives'
    //   break
    case paths.dashboard.community.moderators:
      label = 'Moderators'
      break
    case paths.dashboard.viewerLeaderboard:
      label = 'Viewer Leaderboard'
      break
    case paths.dashboard.WhatsNew:
      label = 'What’s New - Release Notes'
      break
    case paths.dashboard.manageAccounts:
      label = 'Manage Accounts'
      break
    case paths.dashboard.kyc:
    case paths.dashboard.kycPancard:
    case paths.dashboard.kycbankAccount:
      label = 'KYC Verification'
      break
    case paths.dashboard.vipLeaderboard:
      label = 'VIP Leaderboard'
      break
    default:
      label = 'Streaming Center'
      break
  }
  const showLive = isLive && paths.dashboard.stream === pathname

  const isWhatsNew = paths.dashboard.WhatsNew === pathname
  const isVIP = paths.dashboard.vipLeaderboard === pathname

  //@ts-ignore
  const defaultUrlForDeepLink: string = window?.NativeUIWebInterface
    ?.getInitialLoadURL
    ? (window as any)?.NativeUIWebInterface?.getInitialLoadURL()
    : ''
  const formattedDefaultUrlForDeepLink = defaultUrlForDeepLink
    ? extractPathFromWebviewUrl(defaultUrlForDeepLink)
    : ''

  return (
    <Flex w="full" justify="space-between">
      <HeaderMobileLeft
        label={label}
        showLive={showLive}
        isWhatsNew={isWhatsNew}
        isVIP={isVIP}
        headerRight={paths.dashboard.menu === pathname}
        handleBackProps={
          paths.dashboard.menu === pathname ||
          (paths.dashboard.default === pathname && !search) ||
          formattedDefaultUrlForDeepLink === pathname ||
          (paths.dashboard.incentives === pathname &&
          //@ts-ignore
          window?.DashboardInterface?.isStudio
            ? //@ts-ignore
              window?.DashboardInterface?.isStudio()
            : false)
            ? () => {
                exitWebView()
              }
            : undefined
        }
      />
      {paths.dashboard.menu === pathname && <HeaderMobileRight />}
    </Flex>
  )
}

export default HeaderWebview
