import { Flex, FlexProps } from '@chakra-ui/core'
import React from 'react'

interface ContainerProps {
  sideBar?: boolean
  hideNavBar?: boolean
  webViewChild?: React.ReactNode
  children?: React.ReactNode
}

export const Container = (props: ContainerProps & FlexProps): JSX.Element => {
  return (
    <Flex
      color={'white'}
      position={'relative'}
      w="full"
      flexDirection={['column', 'row', 'row']}
    >
      <Flex
        flex={1}
        direction="column"
        alignItems="center"
        position="relative"
        justifyContent="flex-start"
        px={[4, 4, 16]}
        py={[2, 4, 8]}
        overflow="auto"
        h="fit-content"
        {...props}
      >
        {props.children}
      </Flex>
    </Flex>
  )
}
