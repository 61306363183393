import { Box, Flex, Text } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const LoginMobileHeader = () => {
  const { t } = useTranslation()

  return (
    <Box w="full" display={['block', 'none', 'none']}>
      <Flex
        w="full"
        fontSize="20px"
        justifyContent="center"
        color="white"
        h={8}
        px={2}
        mb={2}
      >
        <Text fontWeight="900" as="span" mr={2}>
          LOCO
        </Text>
        <Text as="span"> {t('login.streamingText')}</Text>
      </Flex>
      <Flex
        w="full"
        color="white"
        px={2}
        fontSize="10px"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          bg="#FFDA00"
          w="60px"
          alignItems="center"
          height="19px"
          justifyContent="center"
          textAlign="left"
          mr={2}
          transform="skew(-20deg)"
        >
          <Text
            color="#0c0c20"
            fontWeight="black"
            lineHeight="1.22"
            transform="skew(20deg)"
          >
            {t('login.joinText')}
          </Text>
        </Flex>
        <Text as="span" mr={2} fontWeight="black">
          {`${t('login.gamingText')} ${t('login.revolutonText')}`}
        </Text>
        <Text as="span" mr={2}>
          {t('login.locoText')}
        </Text>
      </Flex>
    </Box>
  )
}
