import { setAccessToken, setRefreshToken } from '@app/appSlice'
import store from '@app/Store'
import { getFingerprint } from '@utils/fingerprint'
import { removeAccessToken, removeRefreshToken } from '@utils/manageTokens'
import { handleEventCapture } from '@utils/sentryEventCapture'
import wretch from 'wretch'

import { clientID, clientSecret, serverUrl2 } from '../../constent'

export const setAccessTokenToStoreAndLocal = (access_token: string) => {
  store.dispatch(setAccessToken(access_token))
  if (access_token) {
    window.localStorage.setItem('access_token', access_token)
  } else {
    window.localStorage.removeItem('access_token')
  }
}
export const setRefreshTokenToStoreAndLocal = (refresh_token: string) => {
  store.dispatch(setRefreshToken(refresh_token))
  if (refresh_token) {
    window.localStorage.setItem('refresh_token', refresh_token)
  } else {
    window.localStorage.removeItem('refresh_token')
  }
}

export const removeAccessTokenFromStoreAndLocal = () => {
  store.dispatch(setAccessToken(''))
  removeAccessToken()
}

export const removeRefreshTokenToStoreAndLocal = () => {
  store.dispatch(setRefreshToken(''))
  removeRefreshToken()
}

export const getAccessToken = () => store.getState().app.accessToken
export const getRefreshToken = () => store.getState().app.refreshToken

const delayMiddleware = (delay: number) => (next: any) => (
  url: any,
  opts: any
) => {
  return new Promise((res) => setTimeout(() => res(next(url, opts)), delay))
}

export const refreshTokenApi = async () => {
  const Authorization = getAccessToken()
  const refreshToken = getRefreshToken()
  const fingerprint = await getFingerprint()
  const params = {
    //@ts-ignore
    refresh_token: refreshToken!,
  }
  const result: any = await fetchWithAllErrorHandle
    .url(`${serverUrl2}/user/refresh_token/`)
    .headers({
      Authorization: Authorization!,
      'DEVICE-ID': fingerprint,
      'X-PLATFORM': '7',
      'X-CLIENT-ID': clientID,
      'X-CLIENT-SECRET': clientSecret,
    })
    .post(params)
    .badRequest((error, originalRequest) => {
      handleEventCapture(
        originalRequest._url,
        error?.message
          ? JSON.parse(error.message).message
          : 'Failed to Fetch Api',
        400
      )
      window.location.replace('/logout')
    })
    .unauthorized((error, originalRequest) => {
      removeAccessTokenFromStoreAndLocal()
      removeRefreshTokenToStoreAndLocal()
      handleEventCapture(
        originalRequest._url,
        error?.message
          ? JSON.parse(error.message).message
          : 'Failed to Fetch Api',
        401
      )
    })
    .json((json) => {
      if (json.access_token && json.refresh_token) {
        setAccessTokenToStoreAndLocal(json.access_token)
        setRefreshTokenToStoreAndLocal(json.refresh_token)
      } else {
        removeAccessTokenFromStoreAndLocal()
        removeRefreshTokenToStoreAndLocal()
      }
      return json
    })
    .catch((error) => {
      handleEventCapture(
        `${serverUrl2}/user/refresh_token/`,
        error?.message ? error?.message : 'Failed to Fetch Api'
      )
      return error
    })
  return result
}

export const fetchWithAllErrorHandle = wretch()
  .catcher(400, (err, originalRequest) => {
    const error = err?.message ? JSON.parse(err.message) : {}
    if (originalRequest?._url?.includes('/streamer/profile/')) {
      if (
        err.message &&
        error.message.toLowerCase() === 'country can be updated only once'
      ) {
        //@ts-ignore
        const originalBody = JSON.parse(originalRequest?._options.body)
        const updateBody = {
          ...originalBody,
          country: undefined,
        }
        return originalRequest.post(updateBody).json()
      }
    }
    if (originalRequest?._url?.includes('/user/username_hai_kya/?username=')) {
      // do nothing
    } else {
      handleEventCapture(
        originalRequest._url,
        error.message || 'Failed to Fetch Api',
        400
      )
    }

    return {
      statusCode: 400,
      error_code: error.error_code || 400,
      message: error.message || 'Failed to Fetch Api',
    }
  })
  .catcher(401, async (error, originalRequest) => {
    const result = await refreshTokenApi()
    if (result?.access_token) {
      return originalRequest
        .auth(result.access_token)
        .replay()
        .unauthorized((err) => {
          try {
            throw JSON.parse(err.message)
          } catch (_err) {
            throw err
          }
        })
        .json()
    }
  })
  .catcher(404, (err, originalRequest) => {
    if (originalRequest?._url?.includes('dashboard/get_user_settings/')) {
      // do nothing
    } else {
      handleEventCapture(
        originalRequest._url,
        err?.message ? JSON.parse(err.message).message : 'Failed to Fetch Api',
        404
      )
    }
    return {
      statusCode: 404,
      message: err?.message
        ? JSON.parse(err.message).message
        : 'Failed to Fetch Api',
    }
  })
  .catcher(403, (err, originalRequest) => {
    handleEventCapture(
      originalRequest._url,
      err?.message ? JSON.parse(err.message).message : 'Failed to Fetch Api',
      403
    )
    return {
      statusCode: 403,
      message: err?.message
        ? JSON.parse(err.message).message
        : 'Failed to Fetch Api',
    }
  })
  .catcher(408, (err, originalRequest) => {
    handleEventCapture(
      originalRequest._url,
      err?.message ? JSON.parse(err.message).message : 'Failed to Fetch Api',
      408
    )
    return {
      statusCode: 408,
      message: 'Timeout due to bad internet',
    }
  })
  .catcher(418, (error, originalRequest) => {
    handleEventCapture(
      originalRequest._url,
      error?.message
        ? JSON.parse(error.message).message
        : 'Failed to Fetch Api',
      418
    )
  })
  .catcher(422, (error, originalRequest) => {
    handleEventCapture(
      originalRequest._url,
      error?.message
        ? JSON.parse(error.message).message
        : 'Failed to Fetch Api',
      422
    )
    return {
      statusCode: 422,
      message: error?.message
        ? JSON.parse(error.message).message
        : 'Failed to Fetch Api',
    }
  })
  .catcher(429, (error, originalRequest) => {
    handleEventCapture(
      originalRequest._url,
      error?.message ? JSON.parse(error.message).message : 'Too Many Requests',
      429
    )
    return {
      statusCode: 429,
      message: 'Too many Requests. Please try after some time.',
    }
  })
  .catcher(500, (err, originalRequest) => {
    handleEventCapture(
      originalRequest._url,
      err?.message ? err.message : 'Failed to Fetch Api',
      500
    )
    const error = err?.message ? JSON.parse(err.message) : {}
    return {
      statusCode: 500,
      code: error.error_code || 500,
      message: error.message || 'Failed to Fetch Api',
    }
    // window.location.replace('/500');
  })
  .catcher(502, (err, originalRequest) => {
    handleEventCapture(originalRequest._url, 'Bad Gateway', 502)
    // window.location.replace('/500');
  })
  .catcher(503, (err, originalRequest) => {
    return (
      originalRequest
        //@ts-ignore
        .middlewares([delayMiddleware(1000)])
        //@ts-ignore
        .catcher(503, (err, nextRequest) => {
          try {
            return (
              nextRequest
                //@ts-ignore
                .middlewares([delayMiddleware(3000)])
                .replay()
                .json()
            )
          } catch (err_1) {
            handleEventCapture(
              originalRequest._url,
              err?.message ? err.message : 'Failed to Fetch Api',
              503
            )
            throw err_1
          }
        })
        .replay()
        .json()
    )
  })
