import './index.css'
import 'ui/dist/index.css'

import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import store from './app/Store'
import { sentryDsn } from './constent'
import * as serviceWorker from './serviceWorker'
import { eventActions } from './utils/Amplitude'
import { executeHandlers } from './utils/manageTokens'

Sentry.init({
  environment: process.env.REACT_APP_ENV,
  dsn: sentryDsn,
  sampleRate: 0.1,
  tracesSampleRate: 0,
})
eventActions.initAmplitude()

import './i18n/i18n'

// ReactDOM.render(<App />, document.getElementById('root'));
const render = () => {
  executeHandlers()

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const App = require('./app/App').default

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
