import { Box, Button, Icon, Stack, Text } from '@chakra-ui/core'
import React from 'react'

export const NotStreamerOnLocoModal = ({
  onClose,
}: {
  onClose: () => void
}) => {
  return (
    <Stack spacing={8} align={'center'} w="full">
      <Icon name="warning" width="60px" height="50px" color="#ffe351" />
      <Text fontWeight="bold" fontSize={['16px', '18px']}>
        You are not streamer on Loco
      </Text>
      <Box fontSize={['16px', '18px']} textAlign="center">
        <Box>
          <Text as="span" opacity={0.6}>
            Please Go{' '}
          </Text>
          <Text as="span" opacity={1} fontWeight="bold">
            {' '}
            Stream Now{' '}
          </Text>
          <Text as="span" opacity={0.6}>
            Section in Loco App{' '}
          </Text>
        </Box>
        <Text opacity={0.6}> and Setup Your Account First.</Text>
      </Box>
      <Button
        width="150px"
        height="44px"
        fontSize={['12px', '14px']}
        borderRadius="8px"
        box-shadow="0 2px 9px 0 rgba(81, 31, 255, 0.08)"
        variantColor="progress"
        onClick={() => {
          onClose()
        }}
      >
        GOT IT!
      </Button>
    </Stack>
  )
}
