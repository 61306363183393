import React, { useState } from 'react'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import './customCalendar.css'
import {
  SlideIn,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Icon,
  Flex,
} from '@chakra-ui/core'
import { Calendar } from 'react-modern-calendar-datepicker'
import { dateToObj } from '../../../utils/formatDateToObj'
import { useTranslation } from 'react-i18next'

const CustomCalendar = (props) => {
  const { t } = useTranslation()

  const [selectedDay, setSelectedDay] = useState(null)
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  })
  const {
    isOpen,
    onClose,
    disableClose,
    onChange,
    title,
    calendarProps,
    value,
    minDate,
  } = props

  const calendarMinimumDate = minDate
    ? minDate
    : { year: 1923, month: 1, day: 1 }

  const formatDate = (date) => {
    let day, month, formatedDate

    day = `${date.day}`.length === 1 ? `0${date.day}` : `${date.day}`
    month = `${date.month}`.length === 1 ? `0${date.month}` : `${date.month}`
    formatedDate = `${day}/${month}/${date.year}`

    return formatedDate
  }
  const getValue = () => {
    if (calendarProps.range) {
      if (selectedDayRange?.from && selectedDayRange?.to) {
        const { from, to } = selectedDayRange
        return `${formatDate(from)} - ${formatDate(to)}`
      }
    } else {
      return selectedDay ? formatDate(selectedDay) : ''
    }
  }
  const onChangeHandler = () => {
    if (selectedDay || (selectedDayRange?.from && selectedDayRange?.to)) {
      onChange(getValue())
    }
    onClose()
    calendarProps.range
      ? setSelectedDayRange({ from: null, to: null })
      : setSelectedDay(null)
  }
  let updatedValue = value ? value : null
  const formatedDateValue = updatedValue
    ? dateToObj(updatedValue)
    : calendarProps.maximumDate
  return (
    <SlideIn in={isOpen}>
      {(styles) => (
        <Modal isOpen={true} onClose={onClose} isCentered={true} bg="#fff">
          <ModalOverlay opacity={styles.opacity} />
          <ModalContent
            pb={5}
            {...styles}
            bg={'#fff'}
            borderRadius="12px"
            position={['fixed', 'relative']}
            bottom={[0, null]}
            top={[null]}
            mb={[0]}
            w={['100vw']}
            isCentered={true}
            borderBottomLeftRadius={[0, '8px']}
            borderBottomRightRadius={[0, '8px']}
          >
            <ModalHeader isCentered={true}>
              <Flex
                alignItems="center"
                direction="column"
                color="brand.primary-blue"
                fontSize="md"
              >
                {title}
              </Flex>
            </ModalHeader>
            {!disableClose ? (
              <Flex
                justifyContent="flex-end"
                p={1}
                position="absolute"
                top={0}
                right="0"
              >
                <Icon
                  //@ts-ignore
                  name="closev2"
                  cursor="pointer"
                  onClick={() => {
                    onClose()
                  }}
                  size="5"
                />
              </Flex>
            ) : (
              false
            )}
            <ModalBody>
              <Calendar
                customDaysClassName={['custom-days']}
                value={
                  calendarProps?.range
                    ? selectedDayRange
                    : selectedDay
                    ? selectedDay
                    : formatedDateValue
                }
                onChange={
                  calendarProps?.range ? setSelectedDayRange : setSelectedDay
                }
                minimumDate={calendarMinimumDate}
                colorPrimary="#511fff"
                colorPrimaryLight="rgba(79, 31, 255, 0.5)"
                calendarClassName={'custom-calendar'}
                calendarTodayClassName="custom-today-day"
                renderFooter={() => {
                  return (
                    <Flex justify="flex-end">
                      <Button
                        px={6}
                        borderRadius="10px"
                        bg="brand.primary-blue"
                        color="white"
                        onClick={onChangeHandler}
                      >
                        {t('profile.done')}
                      </Button>
                    </Flex>
                  )
                }}
                {...calendarProps}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </SlideIn>
  )
}

export default CustomCalendar
