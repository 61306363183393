import {
  Avatar,
  AvatarBadge,
  Flex,
  Icon,
  Image,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/core'
import styled from '@emotion/styled'
import React from 'react'
import {
  useTranslation,
  // Trans
} from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { RootState } from '../../../app/RootReducer'
import { paths } from '../../../routers/constants'
import HeaderCenter from './HeaderCenter'
import HeaderLangaugeSelector from './HeaderLangaugeSelector'
import StreamHealth from './StreamHealth'

const NewImage = styled.button`
  margin-inline-start: 10px;
  writing-mode: horizontal-tb;
  align-items: center;
  &:hover {
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(380%)
        hue-rotate(41deg) brightness(116%) contrast(100%);
    }
  }
`

const HeaderRight = ({
  showHeaderCenter,
  disableHomeAndCenter,
}: {
  showHeaderCenter: boolean
  disableHomeAndCenter: boolean
}) => {
  const { me } = useSelector((state: RootState) => state.login)
  const { isLive } = useSelector((state: RootState) => state.liveStreamManager)
  const history = useHistory()
  const { pathname } = useLocation()

  const { i18n } = useTranslation()

  const isRTL = i18n.resolvedLanguage !== 'en'

  const isWhatsNew = paths.dashboard.WhatsNew === pathname

  const customStyle = isRTL
    ? {
        left: 6,
      }
    : {
        right: 6,
      }

  return (
    <Flex
      justifyContent={showHeaderCenter ? ['flex-end', 'center'] : 'flex-end'}
      overflow="hidden"
      align="center"
      w={showHeaderCenter ? ['50%', '82%'] : 'full'}
      boxSizing="border-box"
    >
      {showHeaderCenter && !disableHomeAndCenter ? <HeaderCenter /> : null}
      <Stack
        isInline
        spacing={4}
        align="center"
        display={['none', 'flex', 'flex']}
        position={showHeaderCenter ? 'absolute' : 'relative'}
        {...customStyle}
      >
        {isLive && showHeaderCenter ? (
          <Icon
            //@ts-ignore
            name="liveTrue"
            size="18px"
            width="revert"
          />
        ) : null}
        <HeaderLangaugeSelector />
        {me?.username ? (
          showHeaderCenter ? (
            <Flex alignItems="center">
              {me.iso_code === 'IN' && (
                <Tooltip
                  label={isRTL ? `ما هو الجديد` : `What's New`}
                  display={['none', 'flex']}
                  hasArrow
                  bg="#383838"
                  color="white"
                  w="100px"
                  h="31px"
                  alignItems="center"
                  fontSize="14px"
                  placement="bottom"
                  zIndex={10000}
                  aria-label={`What's New`}
                  closeOnClick={true}
                >
                  <NewImage>
                    <Image
                      src={
                        isWhatsNew
                          ? '/static/images/Vector.svg'
                          : '/static/images/whats-new.svg'
                      }
                      onClick={() => {
                        history.push(paths.dashboard.WhatsNew)
                      }}
                    />
                  </NewImage>
                </Tooltip>
              )}
              <Text color="white" mx={2} fontSize="sm" fontWeight="semibold">
                {isRTL ? 'مرحبا' : 'Hi'}, {me.username}
              </Text>
            </Flex>
          ) : (
            <StreamHealth />
          )
        ) : null}
        {me?.avatar_url ? (
          <Avatar src={me.avatar_url} height="30px" width="30px">
            <AvatarBadge
              size="12px"
              border="2px"
              borderColor="black"
              bg="brand.primary-green"
              left={0}
            />
          </Avatar>
        ) : null}
      </Stack>
    </Flex>
  )
}

export default HeaderRight
