import './customCalendar.css'

import { Input, useDisclosure } from '@chakra-ui/core'
import React from 'react'

import CustomCalendar from './CustomCalendar'

const InputComponent = (props: any): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { value, onChange, title, placeholder } = props
  return (
    <>
      <Input
        isReadOnly={true}
        className="calendar-input"
        placeholder={placeholder}
        _placeholder={{
          color: 'brand.primary-white-v2',
        }}
        fontSize="sm"
        backgroundColor={'brand.primary-text-field'}
        border="none"
        _focus={{
          borderColor: 'white',
        }}
        borderRadius="4px"
        onClick={onOpen}
        value={value}
        isDisabled={props?.calendarProps?.isReadOnly}
        _disabled={{
          backgroundColor: 'brand.primary-text-field',
          cursor: 'not-allowed',
        }}
      />
      <CustomCalendar
        {...props}
        title={title}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        value={value}
        disableClose={false}
        onChange={onChange}
      />
    </>
  )
}

export default InputComponent
