import { Flex, Image, Text } from '@chakra-ui/core'
import { paths } from '@routers/constants'
import isMobile from 'is-mobile'
import React from 'react'
import Select from 'react-select'

interface SelectProps {
  options: any
  field: any
  form: any
}

const customStyles = {
  // @ts-ignore
  container: (base) => ({
    ...base,
    width: '8rem',
  }),
  // @ts-ignore
  menu: (provided) => ({
    ...provided,
    background: isMobile() || paths.dashboard.profile ? '#1c1c1c' : '#511fff',
    zIndex: 99999,
  }),
  // @ts-ignore
  option: (base, state) => ({
    ...base,
    background:
      state.isFocused | state.isSelected ? 'rgba(24, 24, 24, 0.8)' : 'inherit',
  }),
  // @ts-ignore
  control: (base) => ({
    ...base,
    border: isMobile() || paths.dashboard.profile ? 'white' : '#09003c',
    borderRadius: '4px',
    background: isMobile() || paths.dashboard.profile ? '#0f0f0f' : '#09003c',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    minHeight: '40px',
    color: 'white',
    width: '7.5rem',
  }),
  // @ts-ignore
  singleValue: (base) => ({
    ...base,
    color: 'white',
  }),
}

export const SelectField = ({
  options,
  field,
  form,
}: SelectProps): JSX.Element => {
  return (
    <Select
      styles={customStyles}
      options={options}
      name={field.name}
      value={
        options
          ? options.find(
              (option: { value: any }) => option.value === field.value
            )
          : ''
      }
      onChange={(option) => {
        form.setFieldValue(field.name, option.value)
      }}
      formatOptionLabel={(country) => (
        <Flex alignItems="center" justifyContent="space-between">
          <Image
            src={country.image}
            alt="country-image"
            height="24px"
            width="24px"
            marginRight="5px"
          />
          <Text>{country.label}</Text>
        </Flex>
      )}
    />
  )
}
