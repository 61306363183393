import { linkSocialAccounts } from '@api/apiRequest'
import {
  setAccessToken,
  setLinkedGmail,
  setLinkedPhoneNumber,
  setRefreshToken,
} from '@app/appSlice'
import { RootState } from '@app/RootReducer'
// import { googleSignUpApi } from '@api/signup';
import { Box, Button, Flex, Icon, Radio, Text } from '@chakra-ui/core'
import { useCustomToast } from '@components/customToast'
import { UseGoogleLogin } from '@components/GoogleLogin/GoogleLogin'
import GoogleLogo from '@images/signup/googleLogo.png'
import GreenTick from '@images/signup/greenTick.svg'
import Smartphone from '@images/signup/smartphone.png'
import WhatsAppImage from '@images/signup/whatsapp.png'
import { checkIndianBrowser } from '@modules/OTPless/constant'
import { paths } from '@routers/constants'
import { eventActions, eventConstants } from '@utils/Amplitude'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  setEmail,
  setError,
  setSignUpScreen,
  SignupScreenENUM,
} from '../SignupSlice'

function SignUpOptions() {
  const router = useHistory()
  const dispatch = useDispatch()
  const toast = useCustomToast()
  const [isChooseOtp, setChooseOtp] = useState(false)
  const { linkedGmail, linkedPhoneNumber } = useSelector(
    (state: RootState) => state.app
  )

  const responseGoogle = async (response: any): Promise<void> => {
    const userEmail = response?.profileObj?.email
    let ErrorEvent: null | string = response?.error || null

    if (
      response &&
      //@ts-ignore
      response.error &&
      //@ts-ignore
      response.error === 'popup_closed_by_user'
    ) {
      // do nothing
    }
    //@ts-ignore
    else if (response?.error === 'idpiframe_initialization_failed') {
      const errorObj = {
        statusCode: 101,
        title: 'Localstorage Error',
        //@ts-ignore
        message: response.details,
      }
      dispatch(setError(errorObj))
    } else {
      //@ts-ignore
      if (response?.tokenId) {
        //@ts-ignore
        const result = await linkSocialAccounts(response?.tokenId, 10)
        ErrorEvent = result?.message || null
        if (result?.statusCode) {
          ErrorEvent = result?.message || 'Api Failed'
          toast({
            position: 'top',
            title:
              result?.message ??
              'Some unexpected error happened. Please try again.',
            description: '',
            status: 'error',
            duration: 5000,
          })
        } else if (result?.error_code) {
          ErrorEvent = result?.message || 'Api Failed'
          toast({
            position: 'top',
            title:
              result?.message ??
              'Some unexpected error happened. Please try again.',
            description: '',
            status: 'error',
            duration: 5000,
          })
        } else {
          ErrorEvent = null
          //@ts-ignore
          dispatch(setLinkedGmail(response?.profileObj?.email))
          dispatch(
            //@ts-ignore
            setEmail(response?.profileObj?.email)
          )
          if (linkedPhoneNumber) {
            localStorage.setItem('prev_logged_in', 'yes')
            router.replace(paths.dashboard.default)
          }
        }
      }
    }
    if (ErrorEvent) {
      eventActions.sendAmplitudeData(eventConstants.dashboard_link_google, {
        source: 'onboarding',
        response: 'fail',
        failure_msg: ErrorEvent,
        email: userEmail,
      })
    } else {
      eventActions.sendAmplitudeData(eventConstants.dashboard_link_google, {
        source: 'onboarding',
        response: 'success',
        failure_msg: undefined,
        email: userEmail,
      })
    }
  }

  const { i18n, t } = useTranslation()
  const isRTL = i18n.resolvedLanguage !== 'en'
  const isIndianBrowser = checkIndianBrowser()

  const isBothLinked = linkedGmail && linkedPhoneNumber
  const ButtonText = isBothLinked
    ? t('login.phoneNumber.continue')
    : t('login.email.verifyButton')

  const onClickPhoneNumberVerifyButton = () => {
    if (isChooseOtp && isIndianBrowser) {
      dispatch(setSignUpScreen(SignupScreenENUM.PhoneNumberScreen))
    } else {
      router.push(paths.whatsappLink, { isSignup: true })
    }
  }
  useEffect(() => {
    if (isBothLinked) {
      router.replace(paths.dashboard.default)
    }
  }, [isBothLinked])

  return (
    <Flex
      dir={isRTL ? 'rtl' : 'ltr'}
      flex={1}
      bg={['black', '#2c18a6']}
      maxH={['100%', linkedPhoneNumber ? '400px' : '430px']}
      width={['100%', '500px']}
      color="white"
      direction="column"
      borderRadius={['0px', '8px']}
      overflow="hidden"
      px={[6, 4]}
      py={[4]}
    >
      <Icon
        cursor="pointer"
        name={'arrow-back'}
        onClick={() => {
          dispatch(setAccessToken(''))
          dispatch(setRefreshToken(''))
          dispatch(setLinkedGmail(null))
          dispatch(setLinkedPhoneNumber(null))
          const tnc = localStorage.getItem('terms_condition_modal_2023')
          localStorage.clear()
          tnc && localStorage.setItem('terms_condition_modal_2023', tnc)
          router.push(paths.login)
        }}
      />
      <Flex fontSize="sm" mt={6} alignItems="flex-start">
        <Icon name="info-outline" mt={1} size="4" color="#CBB311" />{' '}
        <Text
          flex={1}
          as="span"
          style={{
            paddingInlineStart: '0.5rem',
          }}
        >
          {t('login.link')}{' '}
        </Text>
      </Flex>
      <UseGoogleLogin
        render={(renderProps) => {
          return (
            <Box
              bg={[
                linkedGmail ? '#1A1A1A' : '#1A172A',
                linkedGmail ? '#27139E' : '#2D227D',
              ]}
              mt={4}
              rounded="lg"
              display="flex"
              flexDirection={'row'}
              justifyContent="space-between"
              borderWidth="2px"
              borderColor={linkedGmail ? ['transparent', '#27139E'] : '#CEA903'}
              fontSize="md"
              cursor={linkedGmail ? 'not-allowed' : 'pointer'}
              onClick={() => {
                if (!linkedGmail) {
                  renderProps.onClick()
                }
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                h="96px"
                py={4}
                style={{
                  paddingInlineStart: '0.5rem',
                }}
              >
                <Text fontWeight="bold">{t('login.email.googleAccount')}</Text>
                <Text color="#cccccc" fontWeight="normal">
                  {linkedGmail ? linkedGmail : t('login.email.verifyEmail')}
                </Text>
              </Box>
              <Box
                height="97px"
                width="97px"
                display={['none', linkedGmail ? 'none' : 'flex']}
                alignItems="end"
              >
                <img src={GoogleLogo} alt="google-logo" />
              </Box>
              <Box
                height="24px"
                width="18px"
                display={linkedGmail ? 'block' : 'none'}
                mt="26px"
                style={{
                  marginInlineEnd: '20px',
                }}
              >
                <img src={GreenTick} alt="right-tick" />
              </Box>
            </Box>
          )
        }}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
      />
      <Box
        bg={[
          linkedPhoneNumber ? '#1A1A1A' : '#1A172A',
          linkedPhoneNumber ? '#27139E' : '#2D227D',
        ]}
        rounded="lg"
        mt={4}
        fontSize="md"
        display="flex"
        borderWidth="2px"
        borderColor={linkedPhoneNumber ? ['transparent', '#27139E'] : '#CEA903'}
        flexDirection={'row'}
        justifyContent="space-between"
        cursor={
          linkedPhoneNumber
            ? 'not-allowed'
            : isIndianBrowser
            ? 'auto'
            : 'pointer'
        }
        onClick={() => {
          /*
            For Indian Browsers, We have radio button to choose WhatsApp/OTP, After which he click on Verify button
            For Non-Indian Browser, We only support WhatsApp login, this they can directly click here.
           */
          if (!linkedPhoneNumber && !isIndianBrowser) {
            router.push(paths.whatsappLink)
          }
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          // h="96px" // TODO: Need to check
          w="full"
          py={4}
          style={{
            paddingInlineStart: '0.5rem',
          }}
        >
          <Text fontWeight="bold">{t('login.phoneNumber.title')}</Text>
          {linkedPhoneNumber ? (
            <Text color="#cccccc" fontWeight="normal">
              {linkedPhoneNumber}
            </Text>
          ) : isIndianBrowser ? (
            <Box w="full" color={'#CCCCCC'} fontWeight={'400'} mt="0.75rem">
              <Box
                w="full"
                display={'flex'}
                justifyContent={'space-between'}
                mb="0.15rem"
              >
                <Text>{t('login.whatsapp.useWhatsApp')}</Text>
                <Radio
                  variantColor="black"
                  value={'1'}
                  isChecked={!isChooseOtp}
                  onChange={() => setChooseOtp(false)}
                  mx={3}
                />
              </Box>
              <Box w="full" display={'flex'} justifyContent={'space-between'}>
                <Text>{t('login.phoneNumber.useOtp')}</Text>
                <Radio
                  variantColor="black"
                  value={'2'}
                  isChecked={isChooseOtp}
                  onChange={() => setChooseOtp(true)}
                  mx={3}
                />
              </Box>
            </Box>
          ) : (
            <Text color="#cccccc" fontWeight="normal">
              {t('login.phoneNumber.verifyPhone')}
            </Text>
          )}
        </Box>
        {!isIndianBrowser && (
          <Box
            height="96px"
            width="96px"
            display={[
              'none',
              linkedPhoneNumber || isIndianBrowser ? 'none' : 'flex',
            ]}
            alignItems="flex-end"
          >
            <img src={WhatsAppImage} alt="WhatsApp Icon" />
          </Box>
        )}
        {linkedPhoneNumber && (
          <Box
            height="92px"
            width="92px"
            display={['none', linkedPhoneNumber ? 'none' : 'flex']}
            alignItems="flex-end"
          >
            <img src={Smartphone} alt="smartphone" />
          </Box>
        )}
        <Box
          height="24px"
          width="18px"
          display={linkedPhoneNumber ? 'block' : 'none'}
          mt="26px"
          style={{
            marginInlineEnd: '20px',
          }}
        >
          <img src={GreenTick} alt="right-tick" />
        </Box>
      </Box>
      {linkedGmail ? (
        <Flex justifyContent="center">
          <Button
            bg={['brand.primary-blue']}
            color="white"
            w={['full', 'fit-content']}
            minW="160px"
            display={['block', 'none']}
            rounded="8px"
            mt={4}
            textTransform="uppercase"
            onClick={onClickPhoneNumberVerifyButton}
          >
            {ButtonText}
          </Button>
          <Button
            bg={['white']}
            color="brand.primary-blue"
            w={['full', 'fit-content']}
            minW="160px"
            display={['none', 'block']}
            rounded="8px"
            mt={4}
            textTransform="uppercase"
            onClick={onClickPhoneNumberVerifyButton}
          >
            {ButtonText}
          </Button>
        </Flex>
      ) : (
        <UseGoogleLogin
          render={(renderProps) => {
            return (
              <Flex justifyContent="center">
                <Button
                  bg={['brand.primary-blue']}
                  color="white"
                  w={['full', 'fit-content']}
                  minW="160px"
                  display={['block', 'none']}
                  rounded="8px"
                  mt={4}
                  textTransform="uppercase"
                  onClick={() => {
                    renderProps.onClick()
                  }}
                >
                  {ButtonText}
                </Button>
                <Button
                  bg={['white']}
                  color="brand.primary-blue"
                  w={['full', 'fit-content']}
                  minW="160px"
                  display={['none', 'block']}
                  rounded="8px"
                  mt={4}
                  textTransform="uppercase"
                  onClick={() => {
                    renderProps.onClick()
                  }}
                >
                  {ButtonText}
                </Button>
              </Flex>
            )
          }}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
        />
      )}
    </Flex>
  )
}

export default SignUpOptions
