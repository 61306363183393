import React from 'react'
import { Box, Text, Flex } from '@chakra-ui/core'
import { useHistory, useRouteMatch } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Image = styled.img`
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.2;
`

const FlexCard = ({ buttonText, category, image, imageStyle }) => {
  const history = useHistory()
  const { url } = useRouteMatch()

  return (
    <Flex>
      <Box
        as="button"
        background="linear-gradient(135deg, rgba(205, 112, 255, 1), rgba(97, 77, 221, 1))"
        borderRadius={8}
        backgroundSize="cover"
        backgroundPosition="100% 90%"
        backgroundRepeat="no-repeat"
        flexDirection="column"
        position="relative"
        opacity={0.85}
        p={4}
        w={['100%', '255px']}
        h="162px"
        mr={[0, 4]}
        mt={4}
        display="flex"
        onClick={() => history.push(`${url}/${category}`)}
      >
        <Box fontSize="18px" fontWeight="bold" color="white" textAlign="left">
          <Text>{buttonText}</Text>
          <Image src={image} style={imageStyle} width="120" height="132" />
        </Box>
      </Box>
    </Flex>
  )
}

FlexCard.propTypes = {
  buttonText: PropTypes.string,
  category: PropTypes.string,
  image: PropTypes.object,
  imageStyle: PropTypes.object,
}

export default FlexCard
