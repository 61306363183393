import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/core'
import { Form, Formik } from 'formik'
import i18n from 'i18next'
import { ResendOTP } from 'otp-input-react'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { batch, useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import {
  LoginScreenENUM,
  OTPVerify,
  sentOtpToNumber,
  setLoginScreen,
  setNotStreamerError,
  setOTPLoginError,
  setOtpRetryCount,
  setPhoneNumber,
} from '../../app/appSlice'
import { RootState } from '../../app/RootReducer'
import NewModal from '../../components/NewModal'
import { streamDetailsStateInterface } from '../DefaultStream/streamDetailsSlice'
import { NotStreamerOnLocoModal } from './NotStreamerOnLocoModal'

export const LoginOTPScreen = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const isRTL = i18n.resolvedLanguage !== 'en'
  // const history = useHistory();
  const { isOpen, onClose, onOpen } = useDisclosure()

  const goBack = () => {
    dispatch(setLoginScreen(LoginScreenENUM.PhoneNumberScreen))
  }

  const {
    phoneNumber,
    phoneCode,
    OTPLoginLoading,
    OTPLoginError,
    notStreamerError,
    accessToken,
    refreshToken,
  } = useSelector((state: RootState) => state.app)

  interface Values {
    OTP: string
  }

  const {
    defaultStreamDetails,
    loading: defaultStreamDetailsLoading,
  }: streamDetailsStateInterface = useSelector(
    (state: RootState) => state.streamDetails
  )

  useEffect(() => {
    if (notStreamerError) {
      onOpen()
    }
  }, [notStreamerError])

  function usePrevious(value: any) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }
  const prevDefaultStreamDetailsLoading = usePrevious(
    defaultStreamDetailsLoading
  )
  useEffect(() => {
    if (accessToken && refreshToken) {
      if (
        prevDefaultStreamDetailsLoading !== defaultStreamDetailsLoading &&
        !defaultStreamDetailsLoading &&
        !prevDefaultStreamDetailsLoading
      ) {
        // history.push(paths.dashboard.default);
      }
    }
  }, [
    defaultStreamDetails?.title,

    defaultStreamDetailsLoading,
    accessToken,
    refreshToken,
    prevDefaultStreamDetailsLoading,
  ])

  const handleClose = () => {
    batch(() => {
      dispatch(setPhoneNumber(null))
      dispatch(setNotStreamerError(null))
      dispatch(setLoginScreen(LoginScreenENUM.LoginOptionsScreen))
    })
    onClose()
  }

  return (
    <Flex
      flex={1}
      height="100%"
      width="100%"
      direction="column"
      alignItems="center"
      justifyContent={['start', 'center']}
      bg={['black', '#2c18a6']}
      color="white"
      px={[4, 0]}
      py={[4, 0]}
    >
      <Formik
        initialValues={{
          OTP: '',
        }}
        validationSchema={OTPSchema}
        enableReinitialize={true}
        onSubmit={(values: Values) => {
          dispatch(OTPVerify(values.OTP))
        }}
      >
        {({ errors, touched, handleBlur, handleChange, values }) => (
          <Form className="login-screen-form ">
            <Box w={['full', '300px']} mb={8}>
              <Icon
                name="arrow-back"
                color="white"
                cursor="pointer"
                size={'5'}
                onClick={goBack}
              />
            </Box>
            <Box w={['full', '300px']} mb={8}>
              <Text fontSize={['18px', '20px']} fontWeight="black">
                {t('login.phoneNumber.enterCode')}
              </Text>
              <Box fontSize={['12px', '14px']} mt={[0, 2]}>
                <Text opacity={0.6}>{t('login.phoneNumber.verifyText')}</Text>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text>
                    {phoneCode} - {phoneNumber}
                  </Text>
                  <Icon
                    opacity={0.6}
                    name="edit"
                    cursor="pointer"
                    onClick={goBack}
                    size="5"
                  ></Icon>
                </Flex>
              </Box>
            </Box>
            <FormControl
              w={['full', '300px']}
              mb={4}
              isInvalid={
                OTPLoginError || (errors.OTP && touched.OTP) ? true : false
              }
            >
              <Input
                w={['full', '300px']}
                value={values.OTP}
                onChange={(e: any) => {
                  dispatch(setOTPLoginError(null))
                  handleChange(e)
                }}
                onBlur={handleBlur}
                bg={['#1c1c1c', '#16086c']}
                borderRadius="5px"
                type="tel"
                pattern="[0-9]*"
                name="OTP"
                borderColor={['#1c1c1c', '#09003c']}
                placeholder="OTP"
              />
              {OTPLoginError || (errors.OTP && touched.OTP) ? (
                <FormErrorMessage mt={0}>
                  {(isRTL && OTPLoginError
                    ? `رمز التحقق غير صحيح`
                    : OTPLoginError) || errors.OTP}
                </FormErrorMessage>
              ) : (
                false
              )}
            </FormControl>
            <ResendOTP
              maxTime={30}
              renderTime={() => {
                // do nothing
              }}
              renderButton={(data: any) => (
                <Button
                  variant="ghost"
                  px={0}
                  mb={8}
                  height="auto"
                  isDisabled={data.disabled}
                  onClick={data.onClick}
                  color="white"
                  fontSize="12px"
                  _focus={{
                    outline: 'transparent',
                    backgroundColor: 'transparent',
                  }}
                  _hover={{
                    outline: 'transparent',
                    backgroundColor: 'transparent',
                  }}
                  _disabled={{
                    opacity: 0.6,
                    cursor: 'no-drop',
                  }}
                >
                  <Text>
                    {t('login.phoneNumber.resend')}{' '}
                    {data.remainingTime ? (
                      <Text as="span">({data.remainingTime})</Text>
                    ) : null}
                  </Text>
                </Button>
              )}
              onResendClick={() => {
                dispatch(setOtpRetryCount(1))
                dispatch(sentOtpToNumber())
              }}
            />
            <Button
              w={['full', '300px']}
              bg={['#511fff', 'white']}
              //@ts-ignore
              color={['white', '#511fff']}
              size="md"
              isLoading={OTPLoginLoading}
              type="submit"
            >
              {t('login.phoneNumber.verifyButton')}
            </Button>
          </Form>
        )}
      </Formik>
      <NewModal
        isOpen={isOpen}
        onClose={handleClose}
        modalSize="md"
        isCentered={true}
        scrollBehaviour="outside"
        modalOverlayStyle={{
          zIndex: 1400,
        }}
        modalContentStyle={{
          bg: 'brand.primary-light-black-v4',
        }}
        modalHeaderStyle={{
          px: 0,
          pb: 0,
        }}
        modalBodyStyle={{
          p: 4,
        }}
        modalBodyComponent={<NotStreamerOnLocoModal onClose={handleClose} />}
      />
    </Flex>
  )
}

const OTPNum = /[0-9]\d{3}$/

const OTPSchema = Yup.object().shape({
  OTP: Yup.string()
    .matches(OTPNum, `${i18n.t('login.phoneNumber.invalidOtpError')}`)
    .max(4, `${i18n.t('login.phoneNumber.maxOtpError')}`)
    .required(`${i18n.t('login.phoneNumber.invalidOtpError')}`),
})
