import {
  Divider,
  Flex,
  Image,
  Link,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/core'
import queryString from 'query-string'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { RootState } from '../../../app/RootReducer'
import HowToStreamVideoModal from '../../../components/howToStreamVideoModal'
import banner from '../../../images/banner.png'
import { paths } from '../../../routers/constants'

const Main = () => {
  const { me } = useSelector((state: RootState) => state.login)
  const { isOpen, onClose } = useDisclosure()
  const { t } = useTranslation()
  return (
    <>
      <Image src={banner} />
      <Stack spacing={4} p={6} alignItems="left" w="full">
        <Text fontSize={'sm'} fontWeight="black">
          {t('onBoarding.streamSetup.hello')} {me?.username},
        </Text>
        <Text fontSize="sm" fontWeight="300">
          {t('onBoarding.streamSetup.title')} <br />
          {t('onBoarding.streamSetup.subtitle')}
        </Text>
        <Divider mt={0} borderColor={'brand.primary-white-v2'} opacity={0.2} />
        {me?.iso_code == 'IN' && (
          <>
            <Flex align="center" justify="space-between">
              <Text fontSize="sm" fontWeight="100" flex={4} maxW="49%">
                {t('onBoarding.streamSetup.learnStreaming')}
              </Text>
              <Link
                //@ts-ignore
                as={RouterLink}
                to={paths.streamerAcademy}
                color="white"
                bg={'brand.primary-blue'}
                type="submit"
                fontSize="xs"
                fontWeight={'bolder'}
                textAlign="center"
                boxSizing="border-box"
                py={[2, 3]}
                rounded={'8px'}
                w="full"
                maxW={['49%', '30%']}
                flex={1}
                outline="none"
                _hover={{ textDecoration: 'none' }}
                _focus={{
                  outline: 'none',
                }}
              >
                {t('streamerAcademy')}
              </Link>
            </Flex>
            <Divider
              mt={0}
              borderColor={'brand.primary-white-v2'}
              opacity={0.2}
            />{' '}
          </>
        )}
        <Flex align="center" justify="space-between">
          <Text fontSize="sm" fontWeight="100" flex={4} maxW="49%">
            {t('onBoarding.streamSetup.setup')}
          </Text>
          <Link
            //@ts-ignore
            as={RouterLink}
            to={{
              search: queryString.stringify({ to: 'form' }),
            }}
            color="white"
            bg={'#511fff'}
            type="submit"
            fontSize="xs"
            w="full"
            maxW={['49%', '30%']}
            fontWeight={'bolder'}
            flex={1}
            textAlign="center"
            boxSizing="border-box"
            py={[2, 3]}
            rounded={'8px'}
            outline="none"
            _hover={{ textDecoration: 'none' }}
            _focus={{
              outline: 'none',
            }}
          >
            {t('onBoarding.streamSetup.setupBtnText')}
          </Link>
        </Flex>
      </Stack>
      <HowToStreamVideoModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default Main
