import { Box, Flex, Select, Stack } from '@chakra-ui/core'
import React, { useState } from 'react'

import {
  setupStreamingDeviceData,
  setupStreamingDeviceOptions,
  technicalStreamingDeviceData,
  technicalStreamingDeviceOptions,
} from '../data/index'
import AcademyPointRow from './AcademyPointRow'
import AcademyTable from './AcademyTable'

interface StreamerAcademyDropdown {
  id: string
  containsTable: boolean
}

const StreamerAcademyDropdown = (props: StreamerAcademyDropdown) => {
  const { id, containsTable } = props
  const [streamingTypeKey, setStreamingTypeKey] = useState('mobile')
  let pickerOptions: any = []
  let data: any = []
  if (id === 'setup') {
    pickerOptions = setupStreamingDeviceOptions
    data = setupStreamingDeviceData
  } else if (id === 'technical') {
    pickerOptions = technicalStreamingDeviceOptions
    data = technicalStreamingDeviceData
  }
  const handleOnChange = (d: any) => {
    setStreamingTypeKey(d.target.value)
  }

  return (
    <Flex w="full" direction="column" mt={1}>
      <Box marginLeft="auto" marginBottom="24px">
        <Select
          value={streamingTypeKey}
          onChange={handleOnChange}
          w="fit-content"
          h="fit-content"
          py={2}
          fontSize={[10, 'xs']}
          color="#CD70FF"
          iconColor="#614DDD"
          style={{
            border: '1px solid',
            borderRadius: '8px',
            borderImageSlice: 1,
            borderImageSource:
              'linear-gradient(135deg, #CD70FF 0%, #614DDD 100%)',
          }}
          borderRadius="8px"
          focusBorderColor="none"
          bg="brand.primary-light-black-v2"
        >
          {pickerOptions?.map((pickerOption: any) => (
            <option value={pickerOption?.value} key={pickerOption?.key}>
              {pickerOption?.display}
            </option>
          ))}
        </Select>
      </Box>
      <Box>{containsTable && <AcademyTable />}</Box>
      <Stack>
        {data.map((item: any) => {
          return (
            item.id === streamingTypeKey &&
            item.points.map(
              (
                point: {
                  image: { default: string }
                  text: string
                  heading: string | JSX.Element
                  video: any
                },
                index: number
              ) => {
                return (
                  <AcademyPointRow
                    key={index}
                    image={point.image}
                    text={point.text}
                    heading={point.heading}
                    video={point.video}
                    index={index}
                  />
                )
              }
            )
          )
        })}
      </Stack>
    </Flex>
  )
}

export default StreamerAcademyDropdown
