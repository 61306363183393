import { Flex, Text } from '@chakra-ui/core'
import { find } from 'lodash'
import React, { useMemo } from 'react'
import Select, { OptionTypeBase, ValueType } from 'react-select'

interface MultiSelectProps {
  onChange?: (selectedOption: ValueType<OptionTypeBase, boolean>) => void
  value?: ValueType<OptionTypeBase, boolean> | any
  size?: string | string[]
  isMulti?: boolean
  isLoading?: boolean
  options: any[]
  errors: any
  name: string
  isDisable: boolean
  touched: any
  placeholder: string
  extraProps?: any
}

const CountrySelector = (props: MultiSelectProps) => {
  const {
    isMulti,
    isLoading,
    onChange,
    options,
    value,
    errors,
    touched,
    name,
    placeholder,
    extraProps,
  } = props
  const customStyles = useMemo(
    () => ({
      //@ts-ignore
      container: (base) => ({
        ...base,
        ':focus': {
          border: 'none',
          outline: 'none',
        },
        ':active': {
          border: 'none',
          outline: 'none',
        },
        width: '100%',
        borderRadius: '4px',
        cursor: 'pointer',
      }),
      //@ts-ignore
      menu: (provided) => ({
        ...provided,
        ':focus': {
          border: 'none',
          // outline:'none',
        },
        background: '#111111',
        zIndex: 10000,
      }),
      //@ts-ignore
      placeholder: (base) => ({
        ...base,
        color: '#a0a0a0',
        fontSize: '0.9rem',
      }),
      //@ts-ignore
      option: (base, state) => {
        return {
          ...base,
          ':active': {
            background: '#474747',
          },
          background:
            state.isFocused | state.isSelected
              ? 'rgba(24, 24, 24, 0.8)'
              : 'inherit',
          color: 'white',
        }
      },
      //@ts-ignore
      control: (base) => {
        return {
          ...base,
          ':hover': {
            border:
              errors[name] && touched[name] ? '1px solid #e02020' : 'none',
          },
          borderRadius: '4px',
          background: 'transparent',
          boxShadow: 'none',
          height: '40px',
          color: '#ffffff',
          border: errors[name] && touched[name] ? '1px solid #e02020' : 'none',
        }
      },
      //@ts-ignore
      menuList: (base, state) => {
        return {
          ...base,
          background:
            state.isFocused | state.isSelected
              ? 'rgba(24, 24, 24, 0.8)'
              : 'inherit',
        }
      },
      //@ts-ignore
      multiValueLabel: (base) => ({
        ...base,
        color: '#ffffff',
      }),
      //@ts-ignore
      multiValueRemove: (base) => {
        return {
          ...base,
          ':hover': {
            backgroundColor: '#989898',
          },
          backgroundColor: '#585858',
          marginLeft: '5px',
          color: '#ffffff',
          height: '18px',
          width: '18px',
          alignSelf: 'center',
          borderRadius: '50%',
        }
      },
      //@ts-ignore
      valueContainer: (base) => ({
        ...base,
        background: 'transparent',
        color: '#ffffff',
      }),
      //@ts-ignore
      multiValue: (base) => ({
        ...base,
        color: '#a0a0a0',
        paddingLeft: '5px',
        paddingRight: '5px',
        background: '#474747',
        marginLeft: '10px',
        maxWidth: '120px',
      }),
      //@ts-ignore
      indicatorSeparator: (base, state) => ({
        ...base,
        backgroundColor: '#a0a0a0',
        opacity: 0.5,
        display: state.isDisabled ? 'none' : 'block',
      }),
      //@ts-ignore
      dropdownIndicator: (base, state) => ({
        ...base,
        opacity: 0.5,
        display: state.isDisabled ? 'none' : 'block',
      }),
      //@ts-ignore
      singleValue: (base) => {
        return {
          ...base,
          color: 'white',
          paddingLeft: '5px',
          justifyContent: 'space-between',
          width: '100%',
        }
      },
      //@ts-ignore
      input: (base) => {
        return {
          ...base,
          color: '#ffffff',
          fontSize: '14px',
        }
      },
    }),
    [errors[name], touched[name]]
  )

  const formatOptionLabel = ({ label }: any): JSX.Element => {
    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Text>{label}</Text>
      </Flex>
    )
  }

  let updatedValue
  if (isMulti) {
    updatedValue = value?.map((v: any) =>
      find(options, { value: v.toLowerCase() })
    )
  } else {
    const val = value?.uid ? value.uid : value
    updatedValue = find(options, { value: val })
  }
  return (
    <Flex
      bg={[
        extraProps?.isReadOnly ? 'transparent' : 'brand.primary-text-field',
        'brand.primary-text-field',
      ]}
      rounded="4px"
    >
      <Select
        {...props}
        name={name}
        isDisabled={extraProps?.isReadOnly}
        isMulti={isMulti}
        isClearable={false}
        placeholder={placeholder || ''}
        isLoading={isLoading}
        styles={customStyles}
        isSearchable={true}
        value={updatedValue}
        onChange={onChange}
        formatOptionLabel={formatOptionLabel}
        options={options}
      />
    </Flex>
  )
}
export default CountrySelector
