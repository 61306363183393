import { Text } from '@chakra-ui/core'
import React from 'react'

import NewModal from '../NewModal'

interface TooltipModalProps {
  isOpen: boolean
  onClose: () => any
  tooltipLabel: string
  title: string
}

const TooltipModal = (props: TooltipModalProps) => {
  const { isOpen, onClose, tooltipLabel, title } = props

  return (
    <NewModal
      isOpen={isOpen}
      onClose={onClose}
      modalSize="full"
      isCentered={true}
      scrollBehaviour="outside"
      hideClose={true}
      modalOverlayStyle={{
        zIndex: 1400,
      }}
      modalContentStyle={{
        bg: ['brand.primary-light-black-v4'],
        width: '700px',
        borderRadius: 'none',
      }}
      modalHeaderStyle={{
        px: 0,
        pb: 0,
        display: 'none',
      }}
      modalBodyStyle={{
        pt: [4, 8],
        pb: [6, 8],
        px: [4, 8],
      }}
      modalBodyComponent={
        <>
          <Text fontSize="sm" fontWeight="black" mb={4}>
            {title}
          </Text>
          <Text fontSize="xs">{tooltipLabel}</Text>
        </>
      }
    />
  )
}

export default TooltipModal
