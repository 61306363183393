import { Flex, Image, Text } from '@chakra-ui/core'
import Trophy from '@images/vipLeaderboard/trophy.svg'
import React from 'react'

function EmptyRows() {
  return (
    <Flex
      width="100%"
      align="center"
      justify="space-between"
      direction="column"
      marginTop="60px"
    >
      <Image src={Trophy} h="120px" w="120px" alt="tropy" />
      <Text marginTop="26px" fontSize="16px">
        It’s a bit quiet in here
      </Text>
      <Text
        w="237px"
        textAlign="center"
        marginTop="16px"
        fontSize="14px"
        opacity={0.8}
      >
        Ask viewers to participate in the leaderboard to win giveaway prizes
      </Text>
    </Flex>
  )
}

export default EmptyRows
