import * as Sentry from '@sentry/browser'

import { isWebViewBuild } from '../constent'

export const handleEventCapture = (
  url: string,
  message: string,
  errorCode?: number
) => {
  Sentry.withScope(function(scope) {
    scope.setTag('apiurl', url)
    scope.setTag('errorType', 'Api Error')
    scope.setFingerprint([
      isWebViewBuild ? 'anyone-can-stream-dashboard' : 'oraganic-dashboard',
      url,
      message,
      errorCode ? errorCode.toString() : '000',
    ])
    if (errorCode) {
      Sentry.captureException({
        api: url,
        errorCode: errorCode,
        errorMessage: message,
      })
    } else {
      Sentry.captureException({
        api: url,
        errorMessage: message,
      })
    }
  })
}
