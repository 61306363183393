import { Box, Button, Flex, Icon, Stack, Text } from '@chakra-ui/core'
import { withTheme } from 'emotion-theming'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Container from '../../../../components/Container'
import { isWebViewBuild } from '../../../../constent'
import { paths } from '../../../../routers/constants'

interface AcademyCategoryContainerProps {
  children?: React.ReactNode
  title?: string
  subtitle?: string
  text?: string
  nextCategory?: string
  prevCategory?: string
  prevPath?: string
  nextPath?: string
  dashboardPrevPath?: string
  dashboardNextPath?: string
}

const AcademyCategoryContainer = ({
  children,
  title,
  text,
  subtitle,
  nextCategory,
  prevCategory,
  nextPath,
  prevPath,
  dashboardPrevPath,
  dashboardNextPath,
}: AcademyCategoryContainerProps): JSX.Element => {
  const history = useHistory()
  return (
    <Container
      subtitle={subtitle}
      innerContainerStyle={{
        maxW: '700px',
        bg: ['none'],
        w: 'full',
        justify: 'center',
        direction: 'column',
        alignSelf: 'center',
        rounded: '8px',
        p: [0, 4],
      }}
      outerContainerStyle={{
        h: '93vh',
      }}
    >
      <Stack
        spacing={2}
        mb={4}
        flexDirection={['column', 'row']}
        alignSelf={'start'}
      >
        <Flex pb={[4, 0]}>
          <Box display={['flex', 'none']} mr={2}>
            {!isWebViewBuild && (
              <Icon name="leftArrow" size="24px" onClick={history.goBack} />
            )}
          </Box>
          <Box
            w="full"
            fontSize={['sm', 'md']}
            fontWeight={700}
            letterSpacing={'1px'}
            paddingBottom="4px"
            borderBottom="1px solid"
          >
            <Link to="/dashboard/streamerAcademy">Streamer Academy</Link>
          </Box>
          <Box
            display={['none', 'flex']}
            fontSize={'md'}
            fontWeight={700}
            letterSpacing={'1px'}
            whiteSpace="pre"
            alignItems="center"
          >
            {` > `}
          </Box>
        </Flex>
        <Box
          fontSize={'lg'}
          fontWeight={[400, 700]}
          color={'rgba(153, 153, 153, 1)'}
        >
          {title}
        </Box>
      </Stack>

      <Box w="full" fontSize={'sm'} color="#cccccc" mb={4}>
        {text}
      </Box>

      {children}
      <Flex
        direction={['column-reverse', 'row']}
        display="flex"
        justify-content="space-between"
        w="full"
        mt={4}
      >
        {prevCategory && (
          <Button
            backgroundColor="rgba(26, 26, 26, 1)"
            _hover={{ bg: 'rgba(26, 26, 26, 1)' }}
            float="inline-start"
            fontSize="lg"
            h={12}
            mt={[4, 0]}
            onClick={() => {
              if (history.location.pathname.includes(paths.dashboard.default)) {
                history.push(`${dashboardPrevPath}`)
              } else {
                history.push(`${prevPath}`)
              }
            }}
          >{`< ${prevCategory}`}</Button>
        )}
        <Box w={prevCategory && nextCategory ? '35%' : '50%'} />
        {nextCategory && (
          <Button
            bgImg="linear-gradient(135deg, #CD70FF 0%, #614DDD 100%)"
            float="inline-end"
            fontSize="lg"
            h={12}
            onClick={() => {
              if (history.location.pathname.includes(paths.dashboard.default)) {
                history.push(`${dashboardNextPath}`)
              } else {
                history.push(`${nextPath}`)
              }
            }}
          >
            {`${nextCategory} >`}
          </Button>
        )}
      </Flex>
      <Box
        w={['full', '558px']}
        mt="4"
        fontSize="sm"
        fontWeight="400"
        textAlign="center"
        color="#666666"
      >
        If you want to know more about setting up your stream and earning
        rewards you can visit the{' '}
        <Text
          as="span"
          cursor="pointer"
          color="#FF5155"
          onClick={() => {
            history.push(paths.dashboard.help)
          }}
        >
          help & feedback
        </Text>{' '}
        section on our app.
      </Box>
    </Container>
  )
}

export default withTheme(AcademyCategoryContainer)
