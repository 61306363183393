import fp from 'fingerprintjs2'

//  using fingerprint to get unique fingerprint
export const getFingerprint = (): Promise<string> =>
  // eslint-disable-next-line no-undef
  new Promise((resolve) => {
    const fingerprint = window?.localStorage?.getItem('fingerprint')
    if (fingerprint) {
      resolve(fingerprint)
    } else {
      fp.get((components) => {
        const values = components.map(function(component) {
          return component.value
        })
        const murmur = fp.x64hash128(values.join(''), 31)
        window?.localStorage?.setItem('fingerprint', murmur)
        resolve(murmur)
      })
    }
  })
