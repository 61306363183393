import { RootState } from '@app/RootReducer'
import { Box, Icon, Image, Stack, Text } from '@chakra-ui/core'
import isMobile from 'is-mobile'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { isWebViewBuild } from '../../constent'
import { paths } from '../../routers/constants'

interface tag {
  key: string
  icon: string
  label: string
  value: string | number | any
}

const Tags = ({ tags }: { tags: tag[] }) => {
  const history = useHistory()
  const { me } = useSelector((state: RootState) => state.login)

  return (
    <Stack
      isInline
      spacing={[isWebViewBuild ? 2 : 4, 4]}
      pr={[isWebViewBuild ? 4 : 0, 0]}
    >
      {(isWebViewBuild || isMobile()) && me?.iso_code === 'IN' && (
        <Box
          as="button"
          cursor="pointer"
          onClick={() => {
            history.push(paths.dashboard.WhatsNew)
          }}
        >
          <Image
            src="/static/images/whats-new.svg"
            alt="whats new feature"
            marginRight={4}
          />
        </Box>
      )}
      {tags.map((tag) => {
        return (
          <Stack
            isInline
            spacing={2}
            key={tag.key}
            align="center"
            bg={['transparent', '#1d1d1d']}
            px={[0, 3]}
            py={[0, 1]}
            minW={['50px', '150px']}
            boxSizing="border-box"
            rounded="5px"
            display={
              isMobile() && !isWebViewBuild && tag.key === 'followers'
                ? 'none'
                : 'flex'
            }
          >
            <Icon
              //@ts-ignore
              name={tag.icon}
              size="5"
              width="revert"
            />
            <Text
              color="white"
              mx={2}
              fontWeight="black"
              fontSize={['xs', 'sm']}
            >
              {tag.value}
            </Text>
            <Text
              color="brand.primary-text"
              fontWeight="black"
              fontSize="xs"
              lineHeight="initial"
              display={['none', 'flex', 'flex']}
            >
              {tag.label}
            </Text>
          </Stack>
        )
      })}
    </Stack>
  )
}

export default Tags
