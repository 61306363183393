import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

import { getAnalyticsChartData } from '../../../api/apiRequest'
import { AppThunk } from '../../../app/Store'
import { formatHour } from '../../../utils/formatHour'

type currentInitialState = {
  loading: boolean
  error: any | null
  totalFollowerInMela: number
  totalWatchedHoursInMela: number
  totalWatchedHoursInMealReadable: string
  bronzeLevelFollowers: 100
  bronzeLevelWatchedHours: 100
  goldLevelFollowers: 1000
  goldLevelWatchedHours: 1000
  platinumLevelFollowers: 3000
  platinumLevelWatchedHours: 3000
}
const initialState: currentInitialState = {
  loading: false,
  error: null,
  totalFollowerInMela: 0,
  totalWatchedHoursInMealReadable: '',
  totalWatchedHoursInMela: 0,
  bronzeLevelFollowers: 100,
  bronzeLevelWatchedHours: 100,
  goldLevelFollowers: 1000,
  goldLevelWatchedHours: 1000,
  platinumLevelFollowers: 3000,
  platinumLevelWatchedHours: 3000,
}

const streamerMelaSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setLoader(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setChartDataStart(state) {
      state.loading = true
      state.error = null
    },
    setChartDataFail(state, action) {
      state.error = action.payload
      state.loading = false
    },
    setTotalFollowerInMela(state, action) {
      state.totalFollowerInMela = action.payload
      state.loading = false
    },
    setTotalWatchedHoursInMela(state, action) {
      state.totalWatchedHoursInMela = action.payload
      state.loading = false
    },
  },
})
export const {
  setError,
  setLoader,
  setChartDataStart,
  setChartDataFail,
  setTotalFollowerInMela,
  setTotalWatchedHoursInMela,
} = streamerMelaSlice.actions

export default streamerMelaSlice.reducer

export const fetchStreamerMelaData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setChartDataStart())
    const startDate = moment('01-10-2020', 'DD-MM-YYYY')
      .startOf('day')
      .valueOf()
    const endDate = moment('14-11-2020', 'DD-MM-YYYY')
      .endOf('day')
      .valueOf()
    const responseFollwers = await getAnalyticsChartData(
      'followers',
      'daily',
      startDate,
      endDate
    )
    if (responseFollwers?.data) {
      const totalFollowers = (responseFollwers.data as any[]).reduce(function(
        accumulator,
        data
      ) {
        return accumulator + data.y
      },
      0)

      const responseWatchedHours = await getAnalyticsChartData(
        'hours_watched',
        'daily',
        startDate,
        endDate
      )
      if (responseWatchedHours?.data) {
        const totalWatchedHours = formatHour(
          (responseWatchedHours.data as any[]).reduce(function(
            accumulator,
            data
          ) {
            return accumulator + data.y
          },
          0)
        )
        dispatch(setTotalFollowerInMela(totalFollowers))
        dispatch(setTotalWatchedHoursInMela(totalWatchedHours))
      } else if (responseFollwers?.statusCode) {
        dispatch(setChartDataFail(responseFollwers))
      }
    } else if (responseFollwers?.statusCode) {
      dispatch(setChartDataFail(responseFollwers))
    }
  } catch (err) {
    dispatch(setChartDataFail(err))
  } finally {
    dispatch(setLoader(false))
  }
}
