import { Box, Flex, FlexProps, Text } from '@chakra-ui/core'
import { withTheme } from 'emotion-theming'
import React from 'react'
import { Helmet } from 'react-helmet'

import { isWebViewBuild } from '../../constent'
import { Container } from '../Container/Container'

interface MainContainerProps {
  children?: React.ReactNode
  title?: string
  subtitle?: string
  innerContainerStyle?: FlexProps
  outerContainerStyle?: FlexProps
  maxwidth?: string
}

const MainContainer = ({
  children,
  title,
  subtitle,
  innerContainerStyle,
  outerContainerStyle,
  maxwidth,
}: MainContainerProps): JSX.Element => {
  return (
    <Container
      alignItems="center"
      webViewChild={
        <Text fontSize="lg" fontWeight="bolder">
          {title}
        </Text>
      }
      {...outerContainerStyle}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Flex maxW={maxwidth || '700px'} w="full" direction="column">
        <Box textAlign="center" pb={[2, 2, 4]} w="full">
          {!isWebViewBuild ? (
            <Text fontSize={'md'} fontWeight={700} letterSpacing={'1px'}>
              {title}
            </Text>
          ) : (
            false
          )}
          {subtitle ? (
            <Text
              display={['none', 'none', 'block']}
              fontSize={['sm']}
              fontStyle={'italic'}
            >
              {subtitle}
            </Text>
          ) : null}
        </Box>
        <Flex
          w={'full'}
          justify={'center'}
          direction="column"
          alignSelf="center"
          alignItems="center"
          {...innerContainerStyle}
        >
          {children}
        </Flex>
      </Flex>
    </Container>
  )
}

export default withTheme(MainContainer)
