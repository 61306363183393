import {
  Accordion,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Icon,
  Stack,
} from '@chakra-ui/core'
import styled from '@emotion/styled'
import MenuItemWebView from '@modules/Menu/components/MenuItem/index'
//types
import { RoutesProps } from '@routers/dashboardRoutes'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { isWebViewBuild } from '../../../../constent'
import MenuItem from './MenuItem'

const Menu = ({
  routes,
  menuListDisabled,
  onOpen,
}: {
  routes: RoutesProps[]
  menuListDisabled: boolean
  onOpen?: () => any
}): JSX.Element => {
  const { i18n } = useTranslation()

  const isRTL = i18n.resolvedLanguage !== 'en'

  return (
    <Stack spacing={0}>
      <Stack
        spacing={[1, 2]}
        bg={['brand.primary-light-black-v5']}
        p={[4, 2, 4]}
        boxSizing="border-box"
      >
        {routes.slice(0, 2).map((route: RoutesProps) => {
          return route.showInSidebar ? (
            <Box key={route.key}>
              <MenuItem route={route} menuListDisabled={menuListDisabled} />
            </Box>
          ) : null
        })}
      </Stack>
      <Divider
        borderColor="brand.primary-text"
        borderWidth="1px"
        opacity={0.2}
        m={0}
      />
      <Stack spacing={[1, 0]} p={[4, 2, 4]} boxSizing="border-box" pb={24}>
        {routes.slice(2, -1).map((route: RoutesProps) => {
          if (!route.path && route.routes) {
            return (
              <MenuList
                title={isRTL ? route.arbicName : route.name}
                route={route}
                key={route.key}
              />
            )
          }
          return route.showInSidebar ? (
            <Box key={route.key}>
              <MenuItem route={route} menuListDisabled={menuListDisabled} />
            </Box>
          ) : null
        })}
      </Stack>
      <Box
        w={['full', '18%']}
        bottom={0}
        position={'absolute'}
        bg="brand.primary-light-black-v5"
        boxSizing="border-box"
      >
        <Divider
          borderColor="brand.primary-text"
          borderWidth="1px"
          opacity={0.2}
          m={0}
        />
        {routes.slice(-1).map((route: RoutesProps) => {
          return route.showInSidebar ? (
            <Box px={4} color="white" key={route.key}>
              <MenuItem route={route} onOpen={onOpen} />
            </Box>
          ) : null
        })}
      </Box>
    </Stack>
  )
}

interface MenuList {
  title?: string
  route: RoutesProps
}

const SubMenu = styled(Box)`
  @media only screen and (max-width: 47.95rem) {
    padding-left: 0;
  }
`

const StyledAccordionHeader = styled(AccordionHeader)`
  @media only screen and (max-width: 47.95rem) {
    padding: ${isWebViewBuild ? '0.75rem 0 1rem 0' : '0.75rem 0 1rem 0.75rem'};
  }
`

export function MenuList({ title, route }: MenuList): JSX.Element {
  return (
    <Accordion allowToggle>
      <AccordionItem border="none">
        <StyledAccordionHeader
          display="flex"
          justifyContent="space-between"
          padding={isWebViewBuild ? '1rem 0 0.75rem' : '1rem 0.75rem 0.75rem'}
          _focus={{ boxShadow: 'none' }}
          fontSize="0.75rem"
          fontWeight={isWebViewBuild ? 'bold' : '300'}
        >
          <Box display="flex" alignItems="center">
            <Icon
              name={route.icon}
              color="red"
              size="16px"
              style={{
                marginInlineEnd: '1rem',
              }}
            />
            <Box color="white">{title}</Box>
            {route.isNew && (
              <Icon
                //@ts-ignore
                name="newTag"
                size="15px"
                mt={'1px'}
                width={'revert'}
                style={{
                  marginInlineStart: '0.5rem',
                }}
              />
            )}
          </Box>
          <AccordionIcon color="white" size="18px" />
        </StyledAccordionHeader>
        <AccordionPanel padding="0" marginLeft={isWebViewBuild ? 0 : [4, 0]}>
          {route.routes?.map((route) =>
            route.showInSidebar || route.showInWebViewMenu ? (
              <SubMenu
                px={4}
                color="white"
                key={route.key}
                mx={4}
                marginRight="0px"
              >
                {isWebViewBuild ? (
                  <MenuItemWebView route={route} />
                ) : (
                  <MenuItem route={route} />
                )}
              </SubMenu>
            ) : null
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default Menu
