import React from 'react'

import NewModal from '../NewModal'
import VideoPlayer from './videoPlayer'

interface HowToStreamVideoProps {
  isOpen: boolean
  onClose: () => any
}

const HowToStreamVideoModal = (props: HowToStreamVideoProps) => {
  const { isOpen, onClose } = props

  return (
    <NewModal
      isOpen={isOpen}
      onClose={onClose}
      modalSize="full"
      isCentered={true}
      scrollBehaviour="outside"
      modalContentStyle={{
        bg: ['brand.primary-light-black-v4'],
        width: '700px',
      }}
      modalHeaderStyle={{
        px: 0,
        pb: 0,
      }}
      modalBodyStyle={{
        py: [6, 8],
        px: [4, 8],
      }}
      modalBodyComponent={<VideoPlayer url="" poster="" />}
    />
  )
}

export default HowToStreamVideoModal
