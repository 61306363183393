import { RootState } from '@app/RootReducer'
import {
  Divider,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/core'
import { eventActions, eventConstants, eventPropsTypes } from '@utils/Amplitude'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default function HeaderLangaugeSelector() {
  const { t, i18n } = useTranslation()

  const { me } = useSelector((state: RootState) => state.login)
  const { previousVisit: previousVisitStore } = useSelector(
    (state: RootState) => state.app
  )

  const goTo = t('header.goto')

  const lngs = {
    en: { nativeName: 'English' },
    ar: { nativeName: 'العربية' },
  }

  const handleChangeLanguageEvent = (lang: string) => {
    const eventProperties: eventPropsTypes.change_language_dashboard = {
      source_name: previousVisitStore,
      platform: eventConstants.platform,
      streamer_id: me?.user_uid,
      streamer_name: me?.username,
      streamer_type: me?.user_type,
      toggle_lang_to: lang === 'en' ? 'English' : 'Arabic',
    }
    eventActions.sendAmplitudeData(eventConstants.change_language_dashboard, {
      ...eventProperties,
    })
  }

  return (
    <Flex align="center" justify="center" marginX={2}>
      <Text fontSize="sm" fontWeight="black" letterSpacing={'1px'}>
        {//@ts-ignore
        lngs[i18n.resolvedLanguage]?.nativeName ||
          `Language - ${i18n.resolvedLanguage}`}
      </Text>
      <Popover usePortal>
        <PopoverTrigger>
          <Flex p={1}>
            <Icon
              //@ts-ignore
              name="downArrow"
              mt={'1px'}
              size="14px"
              cursor={'pointer'}
            />
          </Flex>
        </PopoverTrigger>
        <PopoverContent
          zIndex={4}
          w="fit-content"
          py={3}
          bg="brand.primary-light-black-v5"
          border="none"
          color="white"
          boxShadow=" 0px 0px 7px 0px rgba(0, 0, 0, 0.75)"
          _focus={{
            borderColor: 'none',
            outline: 'none',
          }}
        >
          <PopoverArrow bg="brand.primary-light-black-v5" h="5px" />
          <PopoverBody>
            <Text fontSize="xs" opacity={0.5} px={3}>
              {goTo}
            </Text>
            <Divider border={1} opacity={0.2} />
            {Object.keys(lngs).map((lng) => (
              <Flex
                key={lng}
                fontSize="xs"
                fontWeight="black"
                letterSpacing="1px"
                cursor="pointer"
                mt={2}
                px={3}
                as="button"
                onClick={() => {
                  i18n.changeLanguage(lng)
                  handleChangeLanguageEvent(lng)
                }}
              >
                {//@ts-ignore
                lngs[lng]?.nativeName || lng}
              </Flex>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  )
}
