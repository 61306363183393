export default [
  { value: 'IN', label: '+91', image: '/static/images/flags/in.svg' },
  // { value: 'YE', label: '+967', image: '/static/images/flags/ye.svg' },
  // { value: 'GB', label: '+44', image: '/static/images/flags/gb.svg' },
  // { value: 'AE', label: '+971', image: '/static/images/flags/ae.svg' },
  // { value: 'TR', label: '+90', image: '/static/images/flags/tr.svg' },
  // { value: 'TN', label: '+216', image: '/static/images/flags/tn.svg' },
  // { value: 'SY', label: '+963', image: '/static/images/flags/sy.svg' },
  // { value: 'SA', label: '+966', image: '/static/images/flags/sa.svg' },
  // { value: 'QA', label: '+974', image: '/static/images/flags/qa.svg' },
  // { value: 'PK', label: '+92', image: '/static/images/flags/pk.svg' },
  // { value: 'OM', label: '+968', image: '/static/images/flags/om.svg' },
  // { value: 'NP', label: '+977', image: '/static/images/flags/np.svg' },
  // { value: 'MA', label: '+212', image: '/static/images/flags/ma.svg' },
  // { value: 'MN', label: '+976', image: '/static/images/flags/mn.svg' },
  // { value: 'LY', label: '+218', image: '/static/images/flags/ly.svg' },
  // { value: 'LB', label: '+961', image: '/static/images/flags/lb.svg' },
  // { value: 'KW', label: '+965', image: '/static/images/flags/kw.svg' },
  // { value: 'KR', label: '+82', image: '/static/images/flags/kr.svg' },
  // { value: 'JO', label: '+962', image: '/static/images/flags/jo.svg' },
  // { value: 'IL', label: '+972', image: '/static/images/flags/il.svg' },
  // { value: 'IQ', label: '+964', image: '/static/images/flags/iq.svg' },
  // { value: 'IR', label: '+98', image: '/static/images/flags/ir.svg' },
  // { value: 'EG', label: '+20', image: '/static/images/flags/eg.svg' },
  // { value: 'CY', label: '+357', image: '/static/images/flags/cy.svg' },
  // { value: 'BD', label: '+880', image: '/static/images/flags/bd.svg' },
  // { value: 'BH', label: '+973', image: '/static/images/flags/bh.svg' },
  // { value: 'DZ', label: '+213', image: '/static/images/flags/dz.svg' },
]
