import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  emailNotificationInfoStatus,
  updateNotificationInfoParams,
  whatsppNotificationInfoStatus,
} from '../../../api/notification'
import { RootState } from '../../../app/RootReducer'
import NewModal from '../../../components/NewModal'
import { paths } from '../../../routers/constants'
import {
  eventActions,
  eventConstants,
  eventPropsTypes,
} from '../../../utils/Amplitude'
import {
  setEmailNotificationStatus,
  setModalStatus,
  updateEmailNotificationStatus,
  updateWhatsappNotifStatus,
} from '../../Login/loginSlice'
import { setIsNew } from '../../Login/loginSlice'
import CommonModalBody, { CommonModalHeader } from './CommonModalBody'
import EmailModalBody from './EmailModalBody'
import WhatsappModalBody from './WhatsappModalBody'

interface props {
  isOpen: boolean
  onClose: () => void
}

const CommonNotificationModal = ({
  // isOpen,
  onClose,
}: props): JSX.Element => {
  const {
    emailNotificationStatus,
    whatsappNotifStatus,
    modalStatus,
  } = useSelector((state: RootState) => state.login)

  const dispatch = useDispatch()
  const history = useHistory()

  const handleWhatsappOptInPromptEvent = (
    eventProperties: eventPropsTypes.whatsapp_opt_in_prompt_props
  ) => {
    eventActions.sendAmplitudeData(
      eventConstants.whatsapp_opt_in_prompt,
      eventProperties
    )
  }

  const handleEmailOptInPromptEvent = (
    eventProperties: eventPropsTypes.email_notification_toggle_props
  ) => {
    eventActions.sendAmplitudeData(
      eventConstants.email_opt_in_prompt,
      eventProperties
    )
  }

  const handleWhatsappNotif = ({
    channel,
    flag,
  }: updateNotificationInfoParams) => {
    if (whatsappNotifStatus === whatsppNotificationInfoStatus.ADDPHONENUMBER) {
      if (flag === whatsppNotificationInfoStatus.NOTIFOFF) {
        handleWhatsappOptInPromptEvent({
          user_response: 'No',
          response: 'success',
        })
        onClose()
      } else {
        handleWhatsappOptInPromptEvent({
          user_response: 'No',
          response: 'failure',
          fail_reason: 'link_phone_needed',
        })
        onClose()
        history.push(paths.dashboard.phoneNumberLink)
      }
      dispatch(setIsNew(false))
    } else {
      handleWhatsappOptInPromptEvent({
        user_response: 'Yes',
        response: 'success',
      })
      dispatch(
        updateWhatsappNotifStatus({
          channel: channel,
          flag: flag,
        })
      )
      onClose()
    }
  }

  const handleEmailNotification = ({
    channel,
    flag,
  }: updateNotificationInfoParams) => {
    handleEmailOptInPromptEvent({
      response: 'success',
      state_changed_to:
        emailNotificationStatus === emailNotificationInfoStatus.NOTIFON
          ? 'out'
          : 'in',
    })
    dispatch(setEmailNotificationStatus(flag))
    dispatch(
      updateEmailNotificationStatus({
        channel: channel,
        flag: flag,
      })
    )
    dispatch(setModalStatus('CLOSE'))
    onClose()
  }

  const FetchModalBody = ({ modalStatus }: any) => {
    switch (modalStatus) {
      case 'COMMON':
        return (
          <CommonModalBody
            handleWhatsappNotif={handleWhatsappNotif}
            handleEmailNotification={handleEmailNotification}
          />
        )
      case 'EMAIL':
        return (
          <EmailModalBody handleEmailNotification={handleEmailNotification} />
        )
      case 'WHATSAPP':
        return <WhatsappModalBody handleWhatsappNotif={handleWhatsappNotif} />
      default:
        return <React.Fragment />
    }
  }

  return (
    <NewModal
      isOpen={modalStatus === 'COMMON' || modalStatus === 'EMAIL'}
      onClose={() => {
        // do nothing
      }}
      hideClose
      isCentered={true}
      scrollBehaviour={'inside'}
      modalSize={'sm'}
      modalBodyStyle={{
        px: [4, 2],
        pb: [4, 2],
        pt: [0, 2],
      }}
      modalHeaderStyle={{
        px: [4, 2],
        pb: [0],
      }}
      modalContentStyle={{
        borderRadius: [0, '12px'],
        bg: ['brand.primary-dark-black-v2', 'brand.primary-light-black-v2'],
        height: 'auto',
        width: ['auto', '560px'],
        p: 2,
      }}
      modalHeaderComponent={
        (modalStatus === 'COMMON' ||
          modalStatus === 'EMAIL' ||
          modalStatus === 'WHATSAPP') && <CommonModalHeader />
      }
      modalBodyComponent={<FetchModalBody modalStatus={modalStatus} />}
    />
  )
}

export default CommonNotificationModal
