import { sentOTPtoLinkPhoneNumber } from '@api/profile'
import { setCountryCode, setphoneCode } from '@app/appSlice'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  Text,
  useDisclosure,
} from '@chakra-ui/core'
import { useCustomToast } from '@components/customToast'
import { SelectField } from '@components/SelectField'
import countryPhoneList from '@constants/countryList'
import { setToasts } from '@hoc/WithToasts/withToastsSlice'
import { NoProfileOnLocoModal } from '@modules/Login/NoProfileOnLocoModal'
import {
  Field,
  // FormikHelpers,
  Form,
  Formik,
} from 'formik'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { batch, useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { RootState } from '../../../app/RootReducer'
import NewModal from '../../../components/NewModal'
import {
  setNoProfileError,
  setPhoneNumber,
  setPhoneNumberSignupStart,
  setPhoneNumberSignUpSuccess,
  setSignupOtpRetryCount,
  setSignUpScreen,
  SignupScreenENUM,
} from '../SignupSlice'

interface Values {
  phoneNumber: string | null
  countryCode: string | ''
}

export const SignUpPhoneScreen = () => {
  const { phoneNumber, phoneNumberSignUpLoading, noProfileError } = useSelector(
    (state: RootState) => state.signup
  )
  const { countryCode } = useSelector((state: RootState) => state.app)
  const dispatch = useDispatch()
  const toast = useCustomToast()

  const { isOpen, onClose, onOpen } = useDisclosure()
  const goBack = () => {
    batch(() => {
      dispatch(setPhoneNumber(null))
      dispatch(setSignUpScreen(SignupScreenENUM.SignupOptionsScreen))
    })
  }

  useEffect(() => {
    if (noProfileError) {
      onOpen()
    }
  }, [noProfileError])

  const handleClose = () => {
    batch(() => {
      dispatch(setPhoneNumber(null))
      dispatch(setNoProfileError(null))
      dispatch(setSignUpScreen(SignupScreenENUM.SignupOptionsScreen))
    })
    onClose()
  }

  const handleSendOtp = async ({
    country_code,
    phone_number,
  }: {
    phone_number: string
    country_code: string
  }) => {
    dispatch(setPhoneNumberSignupStart())
    const result = await sentOTPtoLinkPhoneNumber({
      country: country_code,
      phone: phone_number.toString(),
      isSignUp: true,
    })
    if (result.message === 'Otp sent') {
      dispatch(setSignupOtpRetryCount(0))
      dispatch(setPhoneNumberSignUpSuccess())
      dispatch(
        setToasts({
          position: 'top',
          title: i18n.t('login.phoneNumber.success'),
          description: `${i18n.t(
            'login.phoneNumber.otpToast'
          )} ${phone_number}`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      )
    } else if (result.error_code) {
      toast({
        position: 'top',
        title: result.message,
        description: '',
        status: 'error',
        duration: 3000,
      })
    } else if (result.statusCode) {
      toast({
        position: 'top',
        title: result.message,
        description: '',
        status: 'error',
        duration: 3000,
      })
    } else {
      toast({
        position: 'top',
        title: result.message,
        description: 'something went wrong',
        status: 'error',
        duration: 3000,
      })
    }
  }

  const { i18n, t } = useTranslation()
  const isRTL = i18n.resolvedLanguage !== 'en'

  return (
    <Flex
      dir={isRTL ? 'rtl' : 'ltr'}
      flex={1}
      direction="column"
      alignItems="center"
      justifyContent={['start', 'center']}
      bg={['black', '#2c18a6']}
      maxH={['100%', '360px']}
      width={['100%', '360px']}
      color="white"
      px={[4, 0]}
      py={[4, 0]}
    >
      <Box w={['full', '300px']} mb={8}>
        <Icon
          name="arrow-back"
          color="white"
          cursor="pointer"
          size={'5'}
          onClick={goBack}
        />
      </Box>
      <Box w={['full', '300px']} mb={8}>
        <Text fontSize={['18px', '20px']} fontWeight="black">
          {t('login.phoneNumber.enterPhone')}
        </Text>
        <Text fontSize={['12px', '14px']} opacity={0.6} mt={[0, 2]}>
          {t('login.phoneNumber.verifyCode')}
        </Text>
      </Box>
      <Formik
        initialValues={{
          phoneNumber: phoneNumber,
          countryCode: countryCode,
        }}
        enableReinitialize={true}
        validationSchema={phoneSchema}
        onSubmit={(values: Values) => {
          batch(() => {
            const isdCode = countryPhoneList.find(
              ({ value }) => value === values.countryCode
            )?.label
            dispatch(setCountryCode(values.countryCode))
            dispatch(setphoneCode(isdCode))
            dispatch(setPhoneNumber(values.phoneNumber))
            handleSendOtp({
              country_code: values.countryCode,
              phone_number: values.phoneNumber!,
            })
          })
        }}
      >
        {({ errors, touched, handleBlur, handleChange, values }) => (
          <Form className="login-screen-form ">
            <FormControl
              w={['full', '300px']}
              mb={12}
              isInvalid={
                errors.phoneNumber && touched.phoneNumber ? true : false
              }
            >
              <InputGroup w={['full', '300px']} height="48px">
                <Field
                  name="countryCode"
                  component={SelectField}
                  options={countryPhoneList}
                />
                <Input
                  bg={['#1c1c1c', '#16086c']}
                  type="number"
                  name="phoneNumber"
                  value={values.phoneNumber ? values.phoneNumber : ''}
                  roundedLeft="0"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  borderColor={['#1c1c1c', '#09003c']}
                  placeholder={t('login.phoneNumber.title')}
                />
              </InputGroup>
              {errors.phoneNumber && touched.phoneNumber ? (
                <FormErrorMessage mt={0}>
                  {' '}
                  <Box
                    style={{
                      marginInlineStart: '1rem',
                    }}
                  >
                    {errors.phoneNumber}
                  </Box>
                </FormErrorMessage>
              ) : (
                false
              )}
            </FormControl>

            <Button
              w={['full', '300px']}
              bg={['#511fff', 'white']}
              //@ts-ignore
              color={['white', '#511fff']}
              size="md"
              type="submit"
              isLoading={phoneNumberSignUpLoading}
            >
              {t('login.phoneNumber.continue')}
            </Button>
          </Form>
        )}
      </Formik>
      <NewModal
        isOpen={isOpen}
        onClose={handleClose}
        modalSize="md"
        isCentered={true}
        scrollBehaviour="outside"
        modalOverlayStyle={{
          zIndex: 1400,
        }}
        modalContentStyle={{
          bg: 'black',
        }}
        modalHeaderStyle={{
          px: 0,
          pb: 0,
        }}
        modalBodyStyle={{
          p: 4,
        }}
        modalBodyComponent={<NoProfileOnLocoModal onClose={handleClose} />}
      />
    </Flex>
  )
}

const phoneSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .nullable()
    .required(`${i18n.t('login.phoneNumber.phoneError')}`)
    .max(10, `${i18n.t('login.phoneNumber.maxphoneError')}`),
})
